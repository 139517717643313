// import Button from '@mui/material/Button';
import Resource from 'ui/layouts/Resource';
// import { useFinanceContext } from './FinanceProvider';
import FinancePayoutsTable from './FinancePayoutsTable';
import FinanceCassettes from './FinanceCassettes';
import FinanceCharts from './FinanceCharts';
import Divider from '@mui/material/Divider';

const Finance = () => {
    // const { updateData } = useFinanceContext();

    return (
        <Resource
            title='Merchants | Finance'
            breadcrumbsProps={{
                heading: 'Finance',
                links: [{ name: '' }],
                // action: (
                //     <Button
                //         variant='contained'
                //         color='secondary'
                //         onClick={updateData}
                //     >
                //         Update Data
                //     </Button>
                // )
            }}
        >
            <FinanceCassettes />
            <Divider
                sx={{
                    mt: 5
                }}
            />
            <FinancePayoutsTable />
            <FinanceCharts />
        </Resource>
    );
};

export default Finance;
