import { memo } from "react";
import { BarChartProps, BarStatisticDiagram } from "ui/molecules/StatisticDiagram";
import BarTooltip from "./BarTooltip";

const BarDiagram = ({
    sx,
    chartProps,
    isLoading,
    ...restProps
}: BarChartProps) => {
    const getGroupMode = () => {
        if (!chartProps!.data.length || chartProps?.isStacked) {
            return 'stacked';
        }

        return 'grouped';
    };

    const axisBottomTickRotation = () => {
        if (chartProps!.data.length > 4) {
            return 30;
        }

        return 0;
    };

    return (
        <BarStatisticDiagram
            {...restProps}
            isLoading={isLoading}
            sx={{
                position: 'relative',
                overflow: 'visible',
                ...sx
            }}
            isDataAvailable={Boolean(chartProps!.data.length)}
            chartProps={{
                labelSkipWidth: 12,
                labelSkipHeight: 12,
                labelTextColor: {
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                },
                legends: [
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ],
                // role: 'application',
                // ariaLabel: 'Processed Per Payment Method',
                // barAriaLabel: e => `${e.id}: ${e.formattedValue} per method ${e.indexValue}`,
                // keys: Array.from(keysSet),
                // indexBy: 'tag',
                valueScale: { type: 'linear' },
                groupMode: getGroupMode(),
                indexScale: {
                    type: 'band',
                    round: true
                },
                colors: { scheme: 'category10' },
                valueFormat: " >-,.2f",
                enableLabel: false,
                borderColor: {
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                },
                tooltip: props => (
                    <BarTooltip
                        {...props}
                    />
                ),
                axisTop: null,
                axisRight: null,
                ...chartProps,
                style: {
                    maxHeight: 478,
                    height: '100%',
                    ...chartProps?.style
                },
                axisBottom: {
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: axisBottomTickRotation(),
                    legendPosition: 'middle',
                    legendOffset: 32,
                    ...chartProps?.axisBottom
                    // renderTick: props => (
                    //     <AxisBottomTick
                    //         {...props}
                    //         isLoading
                    //         renderTitle={props => {
                    //             const {
                    //                 label,
                    //                 formattedValue,
                    //                 postfix
                    //             } = getBottomTooltipProps(props);

                    //             return `${label}: ${formattedValue} ${postfix}`;
                    //         }}
                    //     />
                    // )
                },
                axisLeft: {
                    format: '.2s',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    // legend: `Number of Transactions`,
                    legendPosition: 'middle',
                    legendOffset: -45,
                    ...chartProps?.axisLeft
                },
                data: chartProps!.data
            }}
        />
    );
};

export default memo(BarDiagram);
