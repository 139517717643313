import { PropsWithChildren, createContext } from "react";
import type { FinanceProviderContext } from "./types";
import useFinance from "./useFinance";

export const FinanceContext = createContext({} as FinanceProviderContext);

const FinanceProvider = ({ children }: PropsWithChildren) => (
    <FinanceContext.Provider
        value={useFinance()}
    >
        {children}
    </FinanceContext.Provider>
);

export default FinanceProvider;
