import { useCallback, useRef } from 'react';
import { GRID_ACTIONS_COLUMN_TYPE, type GridRowIdGetter } from '@mui/x-data-grid-premium';
import type { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import type { MerchantPayoutFile } from 'features/finance/types';
import type { FiltersPublicApi } from "ui/widgets/Table";
import useActions from "features/finance/useActions";
import {
    getMerchantPayoutFilesSettlementXLS,
    getMerchantPayoutFilesStillPendingSettlementCSV,
    getMerchantPayoutFilesTransactionCSV
} from 'features/finance/api';
import { FilterVariant } from 'consts/filters';
import { date } from "consts/finance";
import { FilterRenderTypes, useFiltersEvents } from 'ui/widgets/Filters';
import { ActionRequestHandlers, MenuActions } from 'ui/widgets/Table';

export default function useFinancePayoutsTable() {
    const financeFiltersRef = useRef<FiltersPublicApi<string> | null>(null);

    const {
        getMerchantPayoutFiles,
        downloadCsv
    } = useActions();

    const filterTypes = new Map<FilterVariant, string[]>([
        [FilterVariant.DateRange, date]
    ]);

    const getRowId: GridRowIdGetter<MerchantPayoutFile> = useCallback(({ pki }: MerchantPayoutFile) =>
        pki, []);

    const getInitialState: () => GridInitialStatePremium = useCallback(() => ({
        pinnedColumns: {
            right: [GRID_ACTIONS_COLUMN_TYPE]
        }
    }), []);

    const getMenuItems = () => [
        MenuActions.SettlementXLS,
        MenuActions.TransactionsCSV,
        MenuActions.StillPendingSettlementCSV
    ];

    const getRequestMappings = () =>
        new Map<MenuActions, ActionRequestHandlers>([
            [MenuActions.SettlementXLS, { menu: getMerchantPayoutFilesSettlementXLS }],
            [MenuActions.TransactionsCSV, { menu: getMerchantPayoutFilesTransactionCSV }],
            [MenuActions.StillPendingSettlementCSV, { menu: getMerchantPayoutFilesStillPendingSettlementCSV }]
        ]);

    useFiltersEvents({
        [FilterRenderTypes.PopoverDropdown]: {
            onApply: () => financeFiltersRef.current?.onApply()
        }
    });

    return {
        financeFiltersRef,
        filterTypes,
        getRowId,
        fetchTableData: getMerchantPayoutFiles,
        downloadCsv,
        getInitialState,
        getMenuItems,
        getRequestMappings
    };
};
