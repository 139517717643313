import { ApiState } from "infrastructure/api";
import type { RootState } from "infrastructure/store";

export const selectIsMerchantPayoutsUninitialized = (state: RootState) =>
    state.finance.tableLoadingState === ApiState.Idle;

export const selectIsMerchantPayoutsLoading = (state: RootState) =>
    state.finance.tableLoadingState === ApiState.Pending;

export const selectMerchantPayoutsSlice = (state: RootState) =>
    state.finance.tableSlice;

export const selectIsExportMerchantPayoutsLoading = (state: RootState) =>
    state.finance.exportLoadingState === ApiState.Pending;

export const selectPayoutsChartLoadingState = (state: RootState) =>
    state.finance.charts.payouts.loadingState === ApiState.Pending;

export const selectPayoutsChartPayload = (state: RootState) =>
    state.finance.charts.payouts.payload;

export const selectChargebackRefundsChartLoadingState = (state: RootState) =>
    state.finance.charts.chargebackRefunds.loadingState === ApiState.Pending;

export const selectChargebackRefundsChartPayload = (state: RootState) =>
    state.finance.charts.chargebackRefunds.payload;

export const selectPreviousUrl = (state: RootState) =>
    state.finance.previousUrl;
