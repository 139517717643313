import type { AsyncThunk } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiRouteTypes } from "consts/enpoints/api";
import { payloadCreator } from "util/api";
import { concat } from "util/support";
import { sliceToken } from "./state";
import {
    createOrUpdateUserPreferences,
    getConstants,
    getCountries,
    getDomains,
    getLedgers,
    getSettings,
    getUserPreferences
} from "features/general/api";
import type { RootState } from "infrastructure/store";
import { ApiState } from "infrastructure/api";
import type {
    ConstantsResponse,
    CountriesPaginatedResponse,
    DomainsResourceResponse,
    LedgersResponse,
    SettingsResponse,
    UserPreferencesRequest,
    UserPreferencesResponse
} from "./types";
import type { ID, ThunkReturnType } from "types";


export const getConstantsThunk: AsyncThunk<
    ThunkReturnType<ConstantsResponse>,
    void,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetConstants]),
    payloadCreator(getConstants),
    {
        condition: (_, { getState }) => {
            const { general } = getState() as RootState;
            return ![
                ApiState.Pending,
                ApiState.Succeeded
            ].includes(general.constantsLoadingState);
        }
    }
);

export const getSettingsThunk: AsyncThunk<
    ThunkReturnType<SettingsResponse>,
    void,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetSettings]),
    payloadCreator(getSettings),
    {
        condition: (_, { getState }) => {
            const { general } = getState() as RootState;
            return (
                !general.settings &&
                ![ApiState.Pending].includes(general.settingsLoadingState)
            );
        }
    }
);

export const getDomainsThunk: AsyncThunk<
    ThunkReturnType<DomainsResourceResponse>,
    void,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetApiDomains]),
    payloadCreator(getDomains),
    {
        condition: (_, { getState }) => {
            const { general } = getState() as RootState;
            return (
                !general.domains &&
                ![ApiState.Pending].includes(general.domainsLoadingState)
            );
        }
    }
);

export const getCountriesThunk: AsyncThunk<
    ThunkReturnType<CountriesPaginatedResponse>,
    string | undefined,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetCountries]),
    payloadCreator(getCountries),
    {
        condition: (_, { getState }) => {
            const { general } = getState() as RootState;
            return ![ApiState.Pending].includes(general.countriesLoadingState) &&
                (general.countries.length === 0);
        }
    }
);

export const getUserPreferencesThunk: AsyncThunk<
    ThunkReturnType<UserPreferencesResponse>,
    ID,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetUsersPreferences]),
    payloadCreator(getUserPreferences),
    {
        condition: (_, { getState }) => {
            const { general } = getState() as RootState;
            return ![ApiState.Pending].includes(general.userPreferencesLoadingState) &&
                (Object.entries(general.userPreferences.preferences).length === 0);
        }
    }
);

export const createOrUpdateUserPreferencesThunk: AsyncThunk<
    ThunkReturnType<UserPreferencesResponse>,
    UserPreferencesRequest,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.PutUsersPreferences]),
    payloadCreator(createOrUpdateUserPreferences),
    {
        condition: (_, { getState }) => {
            const { general } = getState() as RootState;
            return ![ApiState.Pending].includes(general.userPreferencesLoadingState);
        }
    }
);

export const getLedgersThunk: AsyncThunk<
    ThunkReturnType<LedgersResponse>,
    string | undefined,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetLedgers]),
    payloadCreator(getLedgers),
    {
        condition: (_, { getState }) => {
            const { general } = getState() as RootState;

            return (
                !general.ledgers &&
                ![ApiState.Pending].includes(general.ledgersLoadingState)
            );
        }
    }
);
