import { memo } from 'react';
import numeral from 'numeral';
import Box from '@mui/material/Box';
import { CurrencySymbol } from 'consts/general';
import type { Transaction } from 'features/transactions/types';

type Props = Pick<
    Transaction,
    | 'amount'
    | 'currency'
    | 'amountEur'
>;

const Amount = ({ amount, currency, amountEur }: Props) => (
    <Box
        sx={{
            fontWeight: 600,
            whiteSpace: 'nowrap',
            textAlign: 'right'
        }}
    >
        {currency} {numeral(amount)
            .format(`0,0.00`)}
        <Box
            sx={{
                color: 'text.secondary',
                mt: .5
            }}
        >
            {CurrencySymbol.EUR} {numeral(amountEur)
                .format(`0,0.00`)}
        </Box>
    </Box>
);

export default memo(Amount);
