import { useQuery, useFetch } from "hooks/useQuery";
import { Filters } from "consts/transactions";
import { Aggregates } from "consts/request-query";
import type { TransactionsResponse } from "features/transactions/types";
import type { ConsumersResponse } from "features/consumers/types";
import { getTransactions, getEsTransactions } from "features/transactions/api";
import { getConsumers } from "features/consumers/api";
import type {
    EsTransactionsByPaymentMethodResponse,
    EsTransactionsByCurrencyResponse,
    EsTransactionsByStatusResponse
} from "./types";
import {
    totalCountRequestQueryParamsFactory,
    transactionsRequestQueryParamsFactory
} from "./requestQueryParamsFactory";
import { RequestQueryMapper } from "util/request-query-mapper";
// import { BooleanSwitchValue } from "consts/general";

export const esTransactionsGroupByCurrencyQueryToken = Symbol('esTransactionsGroupByCurrencyQueryToken::CURRENCY');
export const esTransactionsGroupByStatusQueryToken = Symbol('esTransactionsGroupByStatusQueryToken::STATUS');
export const esTransactionsByPaymentMethodQueryToken = Symbol('esTransactionsByPaymentMethodQueryToken');
// export const distinctEmailsQueryToken = Symbol('distinctEmailsQueryToken');
// export const distinctEmailsBySucceededTransactionsQueryToken = Symbol('distinctEmailsBySucceededTransactionsQueryToken::SUCCEEDED');
// export const distinctEmailsByFailedTransactionsQueryToken = Symbol('distinctEmailsByFailedTransactionsQueryToken::FAILED');
// export const distinctEmailsByRejectedTransactionsQueryToken = Symbol('distinctEmailsByRejectedTransactionsQueryToken::REJECT');
// export const totalCountTransactionsPerDistictEmailsQueryToken = Symbol('totalCountTransactionsPerDistictEmailsQueryToken');
export const totalConsumersQueryToken = Symbol('totalConsumersQueryToken');
export const topRecentTransactionsQueryToken = Symbol('topRecentTransactionsQueryToken');

export default function useStatisticDashboardQuery() {
    const esTransactionsByCurrencyQuery = useQuery<EsTransactionsByCurrencyResponse>({
        getRequestQueryParams: requestQueryMapper =>
            transactionsRequestQueryParamsFactory(Filters.currency)(
                requestQueryMapper
                    .containsIn(Filters.status, 'SUCCEEDED')
                    // .containsIn(Filters.parentId, `${BooleanSwitchValue.Off}`)
            ),
        fetchQuery: getEsTransactions
    });

    const esTransactionsByStatusQuery = useQuery<EsTransactionsByStatusResponse>({
        getRequestQueryParams: transactionsRequestQueryParamsFactory(Filters.status),
        fetchQuery: getEsTransactions
    });

    const esTransactionsByPaymentMethodQuery = useQuery<EsTransactionsByPaymentMethodResponse>({
        getRequestQueryParams: requestQueryMapper =>
            transactionsRequestQueryParamsFactory(Filters.methodTag)(
                requestQueryMapper
                    .containsIn(Filters.status, 'SUCCEEDED')
                    .aggregates(Aggregates.Subtotals, Filters.amountEur)
                    .aggregates(Aggregates.Subtotals, '')
                    .aggregates(Aggregates.Subavgs, '')
            ),
        fetchQuery: getEsTransactions
    });

    /* const distinctEmailsQuery = useQuery<TransactionsResponse>({
        getRequestQueryParams: distinctEmailTransactionsRequestQueryParamsFactory(),
        fetchQuery: getTransactions
    });

    const distinctEmailsBySucceededTransactionsQuery = useQuery<TransactionsResponse>({
        getRequestQueryParams: requestQueryMapper =>
            distinctEmailTransactionsRequestQueryParamsFactory('SUCCEEDED')(
                requestQueryMapper
                    .contains(Filters.parentId, `${BooleanSwitchValue.Off}`)
            ),
        fetchQuery: getTransactions
    });

    const distinctEmailsByFailedTransactionsQuery = useQuery<TransactionsResponse>({
        getRequestQueryParams: distinctEmailTransactionsRequestQueryParamsFactory('FAILED'),
        fetchQuery: getTransactions
    });

    const distinctEmailsByRejectedTransactionsQuery = useQuery<TransactionsResponse>({
        getRequestQueryParams: distinctEmailTransactionsRequestQueryParamsFactory('REJECT'),
        fetchQuery: getTransactions
    });

    const totalCountTransactionsPerDistictEmailsQuery = useQuery<EsTransactionsResponse>({
        getRequestQueryParams: totalCountRequestQueryParamsFactory(),
        fetchQuery: getEsTransactions
    }); */

    const totalConsumersQuery = useQuery<ConsumersResponse>({
        getRequestQueryParams: () => totalCountRequestQueryParamsFactory()(
            RequestQueryMapper.from()
        ),
        fetchQuery: getConsumers
    });

    const topRecentTransactionsQuery = useQuery<TransactionsResponse>({
        getRequestQueryParams: () => totalCountRequestQueryParamsFactory(5)(
            RequestQueryMapper.from()
        ),
        fetchQuery: getTransactions
    });

    return {
        ...useFetch(
            esTransactionsByCurrencyQuery,
            esTransactionsByStatusQuery,
            esTransactionsByPaymentMethodQuery,
            // distinctEmailsQuery,
            // distinctEmailsBySucceededTransactionsQuery,
            // distinctEmailsByFailedTransactionsQuery,
            // distinctEmailsByRejectedTransactionsQuery,
            // totalCountTransactionsPerDistictEmailsQuery,
            totalConsumersQuery,
            topRecentTransactionsQuery
        ),
        [esTransactionsGroupByCurrencyQueryToken]: esTransactionsByCurrencyQuery,
        [esTransactionsGroupByStatusQueryToken]: esTransactionsByStatusQuery,
        [esTransactionsByPaymentMethodQueryToken]: esTransactionsByPaymentMethodQuery,
        // [distinctEmailsQueryToken]: distinctEmailsQuery,
        // [distinctEmailsBySucceededTransactionsQueryToken]: distinctEmailsBySucceededTransactionsQuery,
        // [distinctEmailsByFailedTransactionsQueryToken]: distinctEmailsByFailedTransactionsQuery,
        // [distinctEmailsByRejectedTransactionsQueryToken]: distinctEmailsByRejectedTransactionsQuery,
        // [totalCountTransactionsPerDistictEmailsQueryToken]: totalCountTransactionsPerDistictEmailsQuery,
        [totalConsumersQueryToken]: totalConsumersQuery,
        [topRecentTransactionsQueryToken]: topRecentTransactionsQuery
    };
};
