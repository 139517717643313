import type { WritableDraft } from "immer/dist/internal";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { ElasticSearchResourceResponse, ThunkReturnType } from "types";
import type { FinanceState } from "./types";
import { ApiState } from "infrastructure/api";
import thunkReducerFactory from "infrastructure/store/reducers/thunkReducerFactory";

export const {
    requestLoading: merchantPayoutsRequestLoading,
    requestFulfilled: merchantPayoutsRequestFulfilled,
    requestRejected: merchantPayoutsRequestRejected
} = thunkReducerFactory();

export const payoutsChatRequestLoading = (state: WritableDraft<FinanceState>) => {
    state.charts.payouts.loadingState = ApiState.Pending;
};

export const payoutsChatRequestFulfilled = (
    state: WritableDraft<FinanceState>,
    { payload }: PayloadAction<ThunkReturnType<ElasticSearchResourceResponse<string>>>
) => {
    state.charts.payouts.loadingState = ApiState.Succeeded;
    state.charts.payouts.payload = payload!.data;
};

export const payoutsChatRequestRejected = (
    state: WritableDraft<FinanceState>
) => {
    state.charts.payouts.loadingState = ApiState.Failed;
};

export const chargebackRefundsChatRequestLoading = (state: WritableDraft<FinanceState>) => {
    state.charts.chargebackRefunds.loadingState = ApiState.Pending;
};

export const chargebackRefundsChatRequestFulfilled = (
    state: WritableDraft<FinanceState>,
    { payload }: PayloadAction<ThunkReturnType<ElasticSearchResourceResponse<string>>>
) => {
    state.charts.chargebackRefunds.loadingState = ApiState.Succeeded;
    state.charts.chargebackRefunds.payload = payload!.data;
};

export const chargebackRefundsChatRequestRejected = (
    state: WritableDraft<FinanceState>
) => {
    state.charts.chargebackRefunds.loadingState = ApiState.Failed;
};

export const savePreviousUrl = (state: WritableDraft<FinanceState>) => {
    state.previousUrl = window.location.href;
};
