import { PropsWithChildren, createContext, useEffect, useRef, useState } from "react";
import { useModal } from "ui/atoms/Modal";
import type { ResourceContext as ResourceContextType } from "./types";

export const ResourceContext = createContext({} as ResourceContextType);

const ResourceProvider = <TResource extends unknown>({ children }: PropsWithChildren) => {
    const modal = useModal();
    const [resource, setResource] = useState<unknown | null>(null);
    const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

    const onOpen = (resource: unknown | null = null) => {
        setResource(resource);
        modal.onOpen();
    };

    const onClose = () => {
        clearTimeout(timeoutRef.current);
        modal.onClose();
        timeoutRef.current = setTimeout(() => setResource(null), 300);
    };

    const isNew = () => !resource;

    useEffect(() => () => clearTimeout(timeoutRef.current), []);

    return (
        <ResourceContext.Provider
            value={{
                ...modal,
                isNew,
                onOpen,
                onClose,
                resource: resource as TResource
            }}
        >
            {children}
        </ResourceContext.Provider>
    );
};

export default ResourceProvider;
