import { memo } from "react";
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from "ui/atoms/Card";
import Typography from "@mui/material/Typography";
import useRecentTransactions from "./useRecentTransactions";
import Amount from "./Amount";
import TableCell from "./TableCell";
import StatusCell from "./Status";
import { WebRoutes } from "consts/enpoints/web";
import { formatPaymentMethodName } from "features/paymentMethods/helpers";

const RecentTransactions = () => {
    const {
        isLoading,
        getTopRecentTransactions
    } = useRecentTransactions();

    return (
        <Card
            sx={{
                p: 3
            }}
        >
            <Typography
                variant='h6'
                component='div'
            >
                Recent Transactions
            </Typography>
            <TableContainer
                sx={{
                    mt: 3
                }}
                component={Paper}
            >
                <Table aria-label='recent transactions table'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Status</TableCell>
                            <TableCell
                                align='right'
                            >
                                Payment method
                            </TableCell>
                            <TableCell
                                align='right'
                            >
                                Amount
                            </TableCell>
                            <TableCell
                                align='right'
                            >
                                Date
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getTopRecentTransactions()
                            .map(({
                                status,
                                methodTag,
                                amount,
                                currency,
                                amountEur,
                                createdAt
                            }, index) => (
                                <TableRow
                                    key={String(index)}
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0
                                        }
                                    }}
                                >
                                    <StatusCell
                                        isLoading={isLoading}
                                        status={status}
                                    />
                                    <TableCell
                                        isLoading={isLoading}
                                        align='center'
                                        sx={{
                                            fontWeight: 600
                                        }}
                                    >
                                        {formatPaymentMethodName(`${methodTag}`)}
                                    </TableCell>
                                    <TableCell
                                        isLoading={isLoading}
                                        align='right'
                                    >
                                        <Amount
                                            amount={amount}
                                            currency={currency}
                                            amountEur={amountEur}
                                        />
                                    </TableCell>
                                    <TableCell
                                        isLoading={isLoading}
                                        align='right'
                                    >
                                        {createdAt}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box
                sx={{
                    textAlign: 'center'
                }}
            >
                <Link
                    to={WebRoutes.Transactions}
                    component={RouterLink}
                    sx={{ display: 'contents' }}
                >
                    See all transactions
                </Link>
            </Box>
        </Card>
    );
};

export default memo(RecentTransactions);
