import { FormikState, FormikValues } from "formik";
import { ReactNode } from "react";

export const getFormInputProps = (
  fieldName = "",
  { errors, touched }: Pick<FormikState<FormikValues>, 'errors' | 'touched'>
) => {
  return {
    error: Boolean(touched[fieldName] && errors[fieldName]),
    helperText:
      (touched[fieldName] && errors[fieldName] ? errors[fieldName] : undefined) as ReactNode,
  };
};
