import { useFormik } from "formik";
import type { SupportFormData } from "features/general/types";
import type { SupportFormProps } from "./types";
import { getInitialValues, useValidationSchema } from "./validators";

export default function useSupport(form: SupportFormProps) {
    const validationSchema = useValidationSchema();

    const onSubmit: SupportFormProps['onSubmit'] = async (values, ...rest) => form.onSubmit(
        validationSchema.cast(values) as SupportFormData,
        ...rest
    );

    return useFormik({
        ...form,
        onSubmit,
        isInitialValid: false,
        validateOnMount: true,
        validationSchema,
        initialValues: getInitialValues()
    });
};
