import type { ConstantsResponse, LedgersResponse, SettingsResponse } from "features/general/types";
import type { ThunkReturnType } from "types";

import { getConstantsThunk, getLedgersThunk, getSettingsThunk } from "features/general/thunks";
import useRequest from "./useRequest";
import useCountries from "features/general/useCountries";
import useUserPreferences from "features/general/useUserPreferences";
import usePaymentMethodActions from "features/paymentMethods/useActions";
import useSessionEffect from "./useSessionEffect";
import { RequestQueryMapper } from "util/request-query-mapper";
import { Filters } from "consts/transactions";

const dashboardSessionEffectSymbol = Symbol('dashboardSessionEffectSymbol');

export default function useDashboardBootstrap() {
    const getConstants = useRequest<ThunkReturnType<ConstantsResponse>, void>({
        thunk: getConstantsThunk
    });

    const getSettings = useRequest<ThunkReturnType<SettingsResponse>, void>({
        thunk: getSettingsThunk
    });

    const getLedgers = useRequest<ThunkReturnType<LedgersResponse>, string | undefined>({
        thunk: getLedgersThunk
    });

    useUserPreferences({
        shouldFetchUserPreferences: true
    });

    const { getAllPaymentMethods } = usePaymentMethodActions();

    useCountries();

    useSessionEffect(() => {
        getConstants();
        getAllPaymentMethods();
        getSettings();
        getLedgers(
            RequestQueryMapper.from()
                .distincts(Filters.currency)
                .toString()
        );
    }, dashboardSessionEffectSymbol);
};
