import { useSearchParams } from 'react-router-dom';
import { useTypedSelector } from 'hooks';
import { DEFAULT_PAGE_SIZE } from "consts/table";
import type { PaginateResourceResponse } from "types";
import type { RootState } from "infrastructure/store";
import { FilterVariant } from 'consts/filters';
import useDefaultValues, { type UseDefaultValues } from './useDefaultValues';
import { useFilterRequestMapper } from 'ui/widgets/Filters';

type UseTableArgs = {
    readonly selectIsTableLoading: (state: RootState) => boolean;
    readonly selectTableDataSlice: (state: RootState) => PaginateResourceResponse<any>['data'];
    readonly fetchTableData: (filterParams: string) => void;
    readonly paginateOptions?: Pick<
        PaginateResourceResponse<null>['data'],
        | 'current_page'
        | 'per_page'
        | 'total'
    >;
    readonly filterTypes: Map<FilterVariant, string[]>;
    readonly requestQueryOverrideDecorator?: (
        aggregator: (
            fontendQueryParams: URLSearchParams,
            backendQueryParams: URLSearchParams
        ) => void
    ) => (
        fontendQueryParams: URLSearchParams,
        backendQueryParams: URLSearchParams
    ) => void;
} & UseDefaultValues;

const defaultPaginateOptions = {
    current_page: 1,
    per_page: DEFAULT_PAGE_SIZE,
    total: DEFAULT_PAGE_SIZE
};

export default function useTable({
    selectIsTableLoading,
    selectTableDataSlice,
    fetchTableData,
    filterTypes,
    defaultValues,
    requestQueryOverrideDecorator,
    paginateOptions = defaultPaginateOptions
}: UseTableArgs) {
    const [browserUrlSearchParams] = useSearchParams();

    const isLoading = useTypedSelector(selectIsTableLoading);
    const tableDataSlice = useTypedSelector(selectTableDataSlice);

    const {
        data = [],
        current_page: currentPage = paginateOptions.current_page,
        per_page: perPage = paginateOptions.per_page,
        total = paginateOptions.total,
        sortable
    } = tableDataSlice ?? {};

    const getParamsForRequest = useFilterRequestMapper({
        filterTypes,
        requestQueryOverrideDecorator
    });

    const withDefaultValues = useDefaultValues({ defaultValues });

    const fetchData = async (urlSearchParams: URLSearchParams = browserUrlSearchParams) => {
        fetchTableData(
            getParamsForRequest(
                withDefaultValues(urlSearchParams)
            )
        );
    };

    return {
        sortable,
        data,
        currentPage,
        perPage,
        total,
        isLoading,
        fetchData
    };
};
