import { ResponseSettlementStatus } from "consts/api";
import { ApiState } from "infrastructure/api";
import { ResourceResponse } from "types";

export const resourceSliceFactory = () => ({
    tableLoadingState: ApiState.Idle,
    exportLoadingState: ApiState.Idle,
    tableSlice: null,

    searchParams: ''
});

export const getPromiseSettledResourceResult = <TResource>(
    promiseSettledResult: PromiseSettledResult<
        ResourceResponse<TResource> | null | undefined
    >
) => {
    if (promiseSettledResult.status === ResponseSettlementStatus.Fullfilled) {
        return promiseSettledResult.value;
    }

    return null;
};
