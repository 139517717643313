import { memo, useState, ReactNode } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Tab, { TabProps } from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList, { TabListProps } from '@mui/lab/TabList';
import TabPanel, { TabPanelProps } from '@mui/lab/TabPanel';

export type Props<T> = {
    readonly model: Array<Pick<TabProps, 'label'> & {
        readonly component: ReactNode;
    } & T>;
    readonly onChange: (activeTab: string) => void;
    readonly value?: string;
    readonly ContainerProps?: BoxProps;
    readonly TabListContainerProps?: BoxProps;
    readonly TabListProps?: Omit<TabListProps, 'onChange'>;
    readonly TabProps?: TabProps;
    readonly TabPanelProps?: Omit<TabPanelProps, 'value'>;
};

const Tabs = <T extends object>({
    model,
    onChange,
    value = '1',
    ContainerProps = {},
    TabListContainerProps = {},
    TabListProps = {},
    TabProps = {},
    TabPanelProps = {}
}: Props<T>) => {
    const [activeTab, setActiveTab] = useState(value);

    const handleChange: TabListProps['onChange'] = (_, value) => {
        setActiveTab(value);
        onChange(value);
    };

    return (
        <Box
            {...ContainerProps}
        >
            <TabContext value={activeTab}>
                <Box
                    {...TabListContainerProps}
                >
                    <TabList
                        {...TabListProps}
                        onChange={handleChange}
                    >
                        {model.map(({ label }, value) => (
                            <Tab
                                {...TabProps}
                                key={String(label)}
                                label={label}
                                value={String(value + 1)}
                            />
                        ))}
                    </TabList>
                </Box>
                {model.map(({ label, component }, tabIndex) => (
                    <TabPanel
                        {...TabPanelProps}
                        key={String(label)}
                        value={String(tabIndex + 1)}
                    >
                        {component}
                    </TabPanel>
                ))}
            </TabContext>
        </Box>
    );
};

export default memo(Tabs);
