import { useTheme } from '@mui/material/styles';
import type { LineProps } from "@nivo/line";
import type { Scalar } from "types";
import { ThemePaletteMode } from "consts/ui";

export default function useLinearChartColors<TKey extends Scalar>(
    colorSchemeMap: Map<TKey, Record<ThemePaletteMode, string>>
): Pick<LineProps, 'colors'> {
    const theme = useTheme();
    const mode = theme.palette.mode;

    return {
        colors: ({ id }) => `${colorSchemeMap.get(id)?.[mode]}`
    };
};
