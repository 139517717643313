import { memo } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import useFeedback from "./useFeedback";
import { getFormInputProps } from "util/forms";
import type { FeedbackFormProps } from "./types";
import { FormFields } from "./validators";

const Feedback = (props: FeedbackFormProps) => {
    const {
        errors,
        values,
        touched,
        isSubmitting,
        isValid,
        handleSubmit,
        handleChange,
        handleBlur
    } = useFeedback(props);

    return (
        <Box
            noValidate
            component='form'
            name='feedback'
            autoComplete="off"
            onSubmit={handleSubmit}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                gap: 3
            }}
        >
            <TextField
                {...getFormInputProps(FormFields.Description, { errors, touched })}
                multiline
                fullWidth
                rows={6}
                placeholder='Type your message here...'
                name={FormFields.Description}
                value={values[FormFields.Description]}
                onChange={handleChange}
                onBlur={handleBlur}
            />
            <LoadingButton
                variant='outlined'
                type='submit'
                disabled={!isValid}
                loading={isSubmitting}
            >
                Send
            </LoadingButton>
        </Box>
    );
};

export default memo(Feedback);
