import { memo } from "react";
import type { Theme } from "@mui/material";
import type { BarTooltipProps } from "../types";
import Box from "@mui/material/Box";

const BarTooltip = ({ color, label, formattedValue, postfix }: BarTooltipProps) => (
    <Box
        sx={({ palette }: Theme) => ({
            fontFamily: 'var(--manrope-font)',
            bgcolor: palette.background.paper,
            color: palette.text.primary,
            borderRadius: 1,
            boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px',
            p: '5px 9px',
            display: 'grid',
            gridTemplateColumns: 'repeat(3, auto)',
            alignItems: 'center',
            gap: 1,
        })}
    >
        <Box
            sx={{
                width: 12,
                height: 12,
                bgcolor: color
            }}
        />
        <Box>{label}</Box>
        <Box
            sx={{
                fontWeight: 'bold'
            }}
        >
            {formattedValue} {postfix}
        </Box>
    </Box>
);

export default memo(BarTooltip);
