import { FC, memo } from "react";
import Account from "ui/molecules/Account";
import Typography from "@mui/material/Typography";
import { selectSettings } from "features/general/selectors";
import { useTypedSelector } from "hooks";

type Props = {
    size?: number;
};

const Header: FC<Props> = ({ size }) => {
    const settings = useTypedSelector(selectSettings);
   

    const commonSx = {
        fontFamily: 'var(--primary-font)'
    };

    return (
        <Account
            title={(
                <Typography
                    sx={commonSx}
                >
                    #{settings?.pki}
                </Typography>
            )}
            subTitle={(
                <Typography
                    sx={{
                        ...commonSx,
                        fontWeight: 700
                    }}
                >
                    {settings?.fullName}
                </Typography>
            )}
            name={settings?.fullName}
            alt={settings?.fullName}
            sx={{
                width: size,
                height: size
            }}
        />
    );
};

Header.defaultProps = {
    size: 82
};

export default memo(Header);
