// TODO: migrate all x-date-pickers / x-date-pickers-pro to v.6 project wide
import { HelmetProvider } from 'react-helmet-async';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { LocalizationProvider as LocalizationProviderV6 } from '@mui/x-date-pickers-pro-v.6';
import { AdapterMoment as AdapterMomentV6 } from '@mui/x-date-pickers-pro-v.6/AdapterMoment';
import AppNotification from "ui/widgets/Notificator";
// theme
import ThemeProvider from 'theme';
// Routes
import { useRouter, useBootstrap } from "hooks";
// locales
import ThemeLocalization from 'locales';
// components
import { SettingsProvider, ThemeSettings } from "lib/settings";
import SnackbarProvider from 'lib/snackbar';
import ScrollToTop from 'lib/scroll-to-top';
import { MotionLazyContainer } from 'lib/animate';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  useBootstrap();

  const appRoutes = useRouter();

  return (
    <HelmetProvider>
      <LocalizationProviderV6 dateAdapter={AdapterMomentV6}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <SettingsProvider>
            <MotionLazyContainer>
              <ThemeProvider>
                <ThemeSettings>
                  <ThemeLocalization>
                    <SnackbarProvider>
                      <ScrollToTop />
                      {appRoutes}
                      <AppNotification />
                    </SnackbarProvider>
                  </ThemeLocalization>
                </ThemeSettings>
              </ThemeProvider>
            </MotionLazyContainer>
          </SettingsProvider>
        </LocalizationProvider>
      </LocalizationProviderV6>
      <ToastContainer />
    </HelmetProvider>
  );
}

export default App;
