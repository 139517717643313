import { createSlice } from "@reduxjs/toolkit";
import { ApiState } from "infrastructure/api";
import sliceMixin from "infrastructure/store/reducers/sliceMixin";
import {
    rolesRequestFulfilled,
    rolesRequestLoading,
    rolesRequestRejected,
    usersMerchantsRequestFulfilled,
    usersMerchantsRequestLoading,
    usersMerchantsRequestRejected,
    usersMorsRequestFulfilled,
    usersMorsRequestLoading,
    usersMorsRequestRejected,
    usersRequestFulfilled,
    usersRequestLoading,
    usersRequestRejected
} from "./reducers";
import initialState, { sliceToken } from "./state";
import {
    getRolesThunk,
    getUsersMerchantsThunk,
    getUsersMorsThunk,
    getUsersThunk,
    postUserThunk
} from "./thunks";
import type { UsersState } from "./types";

const slice = createSlice({
    name: sliceToken,
    initialState,
    reducers: sliceMixin<UsersState>(initialState),
    extraReducers: builder => {
        builder
            // Users
            .addCase(getUsersThunk.pending, usersRequestLoading)
            .addCase(getUsersThunk.fulfilled, usersRequestFulfilled)
            .addCase(getUsersThunk.rejected, usersRequestRejected)
            // Create or update User
            .addCase(postUserThunk.pending, state => {
                state.userProfileLoadingState = ApiState.Pending;
            })
            .addCase(postUserThunk.fulfilled, state => {
                state.userProfileLoadingState = ApiState.Succeeded;
            })
            .addCase(postUserThunk.rejected, state => {
                state.userProfileLoadingState = ApiState.Failed;
            })
            // Roles
            .addCase(getRolesThunk.pending, rolesRequestLoading)
            .addCase(getRolesThunk.fulfilled, rolesRequestFulfilled)
            .addCase(getRolesThunk.rejected, rolesRequestRejected)
            // UsersMerchantsPivot
            .addCase(getUsersMerchantsThunk.pending, usersMerchantsRequestLoading)
            .addCase(getUsersMerchantsThunk.fulfilled, usersMerchantsRequestFulfilled)
            .addCase(getUsersMerchantsThunk.rejected, usersMerchantsRequestRejected)
            // UsersMorsPivot
            .addCase(getUsersMorsThunk.pending, usersMorsRequestLoading)
            .addCase(getUsersMorsThunk.fulfilled, usersMorsRequestFulfilled)
            .addCase(getUsersMorsThunk.rejected, usersMorsRequestRejected)
    }
});

export const {
    name,
    actions: {
        changeExportLoading,
        resetState
    }
} = slice;

export default slice.reducer;
