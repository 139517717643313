import { memo, type FC } from "react";
import Box, { type BoxProps } from "@mui/material/Box";
import {
    useStatisticDashboardContext,
    esTransactionsGroupByCurrencyQueryToken,
    esTransactionsGroupByStatusQueryToken
} from "ui/organizms/StatisticDashboard/StatisticDashboardProvider";
import { CurrencySymbol } from "consts/general";
import type { ElasticSearchResourceResponse } from "types";
import {
    LinearDiagram,
    LinearDiagramProps,
    SliceTooltip,
    TitleNumber,
    diagramPropsPredicate,
    getAverage,
    getLinearChartData,
    useLinearDiagram
} from "ui/widgets/Charts";
import { Filters } from "consts/filters";

type ProcessedAmountByCurrencyDiagramProps =
    & Pick<LinearDiagramProps, 'className' | 'isLoading'>
    & {
        readonly esTransactionsGroupByCurrencyPayload: ElasticSearchResourceResponse<string>['data'];
        readonly esTransactionsGroupByStatusPayload: ElasticSearchResourceResponse<string>['data'];
    };

const ProcessedAmountByCurrencyDiagram = memo(({
    className,
    isLoading,
    esTransactionsGroupByCurrencyPayload,
    esTransactionsGroupByStatusPayload
}: ProcessedAmountByCurrencyDiagramProps) => {
    const {
        total: totalAmountEur,
        data,
        columns
    } = useLinearDiagram({
        isLoading,
        onLoad: () => {
            let totalAmountEur = 0;

            getLinearChartData(esTransactionsGroupByStatusPayload, {
                periodQueryParameterField: Filters.createdAt,
                periodGroupingField: Filters.createdAt,
                getDatum: row => {
                    const amountEur = Number(row.at(1));

                    if (Object.is(row.at(-1), 'SUCCEEDED')) {
                        totalAmountEur += amountEur;
                    }

                    return {
                        pivot: '',
                        x: 0,
                        y: 0
                    };
                }
            });

            const data = getLinearChartData(esTransactionsGroupByCurrencyPayload, {
                periodQueryParameterField: Filters.createdAt,
                periodGroupingField: Filters.createdAt,
                getDatum: row => ({
                    pivot: String(row.at(-1)),
                    x: row.at(-2),
                    y: Number(row.at(1))
                })
            });

            return {
                total: totalAmountEur,
                data,
                columns: esTransactionsGroupByCurrencyPayload.columns
            };
        }
    });

    return (
        <LinearDiagram
            className={className}
            sx={{
                zIndex: 1
            }}
            title='Processed Amounts'
            subtitle={(
                <Box
                    sx={{
                        fontFamily: 'var(--manrope-font)',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        fontSize: 27
                    }}
                >
                    {CurrencySymbol.EUR}
                    <TitleNumber
                        isLoading={isLoading}
                        format=",.2f"
                        sx={{
                            fontWeight: 'bold'
                        }}
                    >
                        {totalAmountEur}
                    </TitleNumber>
                </Box>
            )}
            subheader={(
                <Box
                    sx={{
                        fontFamily: 'var(--manrope-font)',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        fontSize: 14
                    }}
                >
                    Average per interval unit: {CurrencySymbol.EUR}
                    <TitleNumber
                        isLoading={isLoading}
                        format=",.2f"
                    >
                        {getAverage({
                            columns,
                            total: totalAmountEur,
                            periodGroupingField: Filters.createdAt,
                            periodQueryParameterField: Filters.createdAt
                        })}
                    </TitleNumber>
                </Box>
            )}
            columns={columns}
            chartProps={{
                margin: {
                    right: 75
                },
                sliceTooltip: props => (
                    <SliceTooltip
                        {...props}
                        columns={columns}
                        periodGroupingField={Filters.createdAt}
                        postfix={CurrencySymbol.EUR}
                    />
                ),
                yFormat: ' >-,.2f',
                axisLeft: {
                    legend: `Amount (${CurrencySymbol.EUR})`
                },
                data
            }}
            periodGroupingField={Filters.createdAt}
            isLoading={isLoading}
        />
    );
}, diagramPropsPredicate);

const ProcessedAmountByCurrency: FC<BoxProps> = ({ className }) => {
    const statisticDashboard = useStatisticDashboardContext();
    const {
        payload: esTransactionsGroupByCurrencyPayload,
        isLoading: isEsTransactionsGroupByCurrencyLoading
    } = statisticDashboard[esTransactionsGroupByCurrencyQueryToken];
    const {
        payload: esTransactionsGroupByStatusPayload,
        isLoading: isEsTransactionsGroupByStatusLoading
    } = statisticDashboard[esTransactionsGroupByStatusQueryToken];


    const isLoading = (
        isEsTransactionsGroupByCurrencyLoading ||
        isEsTransactionsGroupByStatusLoading
    );

    return (
        <ProcessedAmountByCurrencyDiagram
            className={className!}
            isLoading={isLoading}
            esTransactionsGroupByCurrencyPayload={esTransactionsGroupByCurrencyPayload!}
            esTransactionsGroupByStatusPayload={esTransactionsGroupByStatusPayload!}
        />
    );
};

ProcessedAmountByCurrency.defaultProps = {
    className: 'js-processed-amounts-by-currency'
};

export default memo(ProcessedAmountByCurrency);
