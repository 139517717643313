import { ApiRoutes } from "consts/enpoints/api";
import { fetchApi } from "infrastructure/api";
import type { BlacklistedEmailEntity, Consumer, ConsumerKYCService } from "./types";
import { KYCProviders } from "consts/kyc";
import { Filters } from "consts/consumers";
import { BooleanSwitchValue } from "consts/general";

export const getConsumers = (queryString: string) =>
    fetchApi(`${ApiRoutes.GetConsumers()}${queryString}`);

export const createConsumer = (body: Pick<Consumer, 'hashedEmail' | 'MOR'>) =>
    fetchApi(ApiRoutes.PostConsumer, {
        method: 'POST',
        body: {
            ...body,
            [Filters.active]: BooleanSwitchValue.Off
        }
    });

export const getConsumerByEmailOrId = (consumerEmailOrId: string | number) =>
    fetchApi(ApiRoutes.GetConsumerByEmailOrId(`${consumerEmailOrId}`));

export const getConsumersBlacklist = (queryString: string) =>
    fetchApi(`${ApiRoutes.GetConsumersBlacklist}${queryString}`);

export const createConsumersBlacklist = (body: Partial<BlacklistedEmailEntity>) =>
    fetchApi(ApiRoutes.PostConsumersBlacklist, {
        method: 'POST',
        body
    });

export const deleteConsumersBlacklist = (email: string) =>
    fetchApi(ApiRoutes.DeleteConsumersBlacklist(email), {
        method: 'DELETE'
    });

export const updateOrCreateConsumerEmails = ({ coreId, ...consumer }: Pick<
    Consumer,
    | 'coreId'
    | 'samePersonIdentifier'
> & Partial<
    Pick<
        Consumer,
        | 'email'
        | 'samePersonIdentifierSource'
    >
>) => fetchApi(ApiRoutes.PutConsumerEmails(coreId), {
    method: 'PUT',
    body: {
        ...consumer,
        consumerId: coreId
    }
});

export const updateConsumer = ({ coreId, ...body }: Partial<Consumer>) =>
    fetchApi(ApiRoutes.PutConsumer(`${coreId}`), {
        method: 'PUT',
        body
    });

export const getConsumerKYCSettings = (queryString: string) =>
    fetchApi(`${ApiRoutes.GetConsumersKYCSettings}${queryString}`);

export const triggerConsumerKYCSettings = (body: Partial<ConsumerKYCService>) =>
    fetchApi(ApiRoutes.PostConsumersKYCSettings, {
        method: 'POST',
        body: {
            ...body,
            provider: KYCProviders.ShuftiPro
        }
    });

export const getConsumersManualBulkKYCsImportHistoryPivot = (queryString: string) =>
    fetchApi(`${ApiRoutes.GetConsumersManualBulkKYCsImportHistoryPivot}${queryString}`);
