import type { PayloadAction } from "@reduxjs/toolkit";
import { ApiState } from "infrastructure/api";
import type { WritableDraft } from "immer/dist/internal";
import type {
    PayoutAccountsState,
    PayoutAccountsResponse,
    PayoutCryptoAccountsResponse,
    Account
} from "./types";
import type { ThunkReturnType } from "types";

export const payoutAccountsRequestLoading = (state: WritableDraft<PayoutAccountsState>) => {
    state.payoutAccountsLoadingState = ApiState.Pending;
};

export const payoutAccountsRequestFulfilled = (
    state: WritableDraft<PayoutAccountsState>,
    { payload }: PayloadAction<ThunkReturnType<PayoutAccountsResponse>>
) => {
    state.payoutAccountsLoadingState = ApiState.Succeeded;
    state.payoutAccounts = payload!.data;
};

export const payoutAccountsRequestRejected = (state: WritableDraft<PayoutAccountsState>) => {
    state.payoutAccountsLoadingState = ApiState.Failed;
    state.payoutAccounts = null;
};

export const payoutCryptoAccountsRequestLoading = (state: WritableDraft<PayoutAccountsState>) => {
    state.payoutCryptoAccountsLoadingState = ApiState.Pending;
};

export const payoutCryptoAccountsRequestFulfilled = (
    state: WritableDraft<PayoutAccountsState>,
    { payload }: PayloadAction<ThunkReturnType<PayoutCryptoAccountsResponse>>
) => {
    state.payoutCryptoAccountsLoadingState = ApiState.Succeeded;
    state.payoutCryptoAccounts = payload!.data;
};

export const payoutCryptoAccountsRequestRejected = (state: WritableDraft<PayoutAccountsState>) => {
    state.payoutCryptoAccountsLoadingState = ApiState.Failed;
    state.payoutCryptoAccounts = null;
};

export const selectAccount = (
    state: WritableDraft<PayoutAccountsState>,
    { payload }: PayloadAction<Account>
) => {
    state.account = payload;
};
