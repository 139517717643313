import type { GridApi, GridEventListener } from "@mui/x-data-grid-premium";
import type { MutableRefObject } from "react";
import { toast } from "react-toastify";
import { useEffect } from 'react';
import type { PluginSettings } from "./PluginSettings";
import { copyToClipboard } from "util/clipboard";

export interface CopyToClipboardSettings extends PluginSettings {
    readonly ignoreFields: Array<string>;
}

export default function useCopyToClipboard(gridApiRef: MutableRefObject<GridApi>, settings: CopyToClipboardSettings) {
    useEffect(() => {
        if (!settings.isEnabled) {
            return;
        }

        const handleCellMouseUp: GridEventListener<'cellDoubleClick'> = async (cellParams, event) => {
            if (cellParams.isEditable ||
                cellParams.colDef.editable ||
                settings.ignoreFields.some(field => cellParams.field.startsWith(field))) {
                return;
            }

            const element = event.target as HTMLElement;

            try {
                await copyToClipboard(element.innerText);
                toast('Field content copied to clipboard');
            }catch (error) {
                if (error instanceof Error) {
                    toast(`Unable to copy to clipboard: ${error.message}`);
                }
            }
        };

        return gridApiRef.current.subscribeEvent('cellDoubleClick', handleCellMouseUp);
    }, [gridApiRef, settings]);
};
