import { useRequest } from "hooks";
import type {
    RolesResponse,
    User,
    UserMerchantRequestParams,
    UserMorRequestParams,
    UsersMerchantsPivot,
    UsersMerchantsResponse,
    UsersMorsPivot,
    UsersMorsResponse,
    UsersResponse
} from "./types";
import {
    associateUserMerchantThunk,
    associateUserMorThunk,
    disassociateUserMerchantThunk,
    disassociateUserMorThunk,
    getRolesThunk,
    getUsersMerchantsThunk,
    getUsersMorsThunk,
    getUsersThunk,
    postUserThunk
} from "./thunks";
import type { ID, ResourceResponse, ThunkReturnType } from "types";
import useExportTable from "hooks/useExportTable";
import useResetState from "hooks/useResetState";
import { resetState, changeExportLoading } from "./slice";
import { ApiRouteTypes } from "consts/enpoints/api";

const useActions = () => {
    const getUsers = useRequest<ThunkReturnType<UsersResponse>, string>({
        thunk: getUsersThunk
    });

    const getRoles = useRequest<ThunkReturnType<RolesResponse>, void>({
        thunk: getRolesThunk
    });

    const getUsersMerchants = useRequest<ThunkReturnType<UsersMerchantsResponse>, ID | undefined>({
        thunk: getUsersMerchantsThunk
    });

    const getUsersMors = useRequest<ThunkReturnType<UsersMorsResponse>, ID | undefined>({
        thunk: getUsersMorsThunk
    });

    const associateUserMerchant = useRequest<
        ThunkReturnType<ResourceResponse<UsersMerchantsPivot>>,
        UserMerchantRequestParams
    >({
        thunk: associateUserMerchantThunk
    });

    const disassociateUserMerchant = useRequest<
        ThunkReturnType<ResourceResponse<null>>,
        UserMerchantRequestParams
    >({
        thunk: disassociateUserMerchantThunk
    });

    const associateUserMor = useRequest<
        ThunkReturnType<ResourceResponse<UsersMorsPivot>>,
        UserMorRequestParams
    >({
        thunk: associateUserMorThunk
    });

    const disassociateUserMor = useRequest<
        ThunkReturnType<ResourceResponse<null>>,
        UserMorRequestParams
    >({
        thunk: disassociateUserMorThunk
    });

    const postUser = useRequest<any, Partial<User>>({
        thunk: postUserThunk
    });

    const downloadCsv = useExportTable({
        changeExportLoading,
        apiResourceType: ApiRouteTypes.GetUsers
    });

    const resetMerchants = useResetState(resetState);

    return {
        getUsers,
        getRoles,
        getUsersMerchants,
        getUsersMors,
        associateUserMerchant,
        disassociateUserMerchant,
        associateUserMor,
        disassociateUserMor,
        postUser,
        downloadCsv,
        resetMerchants
    };
};

export default useActions;
