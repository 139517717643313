import type { ID } from "types";
import { subscribe, unsubscribe } from "./custom-event";

interface EventSubscriberBaseArg {
    readonly eventName: string;
    readonly options?: boolean | AddEventListenerOptions;
    readonly listenerId?: ID;
};

interface SubscribeArg extends EventSubscriberBaseArg {
    readonly listener: EventListenerOrEventListenerObject;
};

interface UnsubscribeArg extends EventSubscriberBaseArg {
    readonly listener?: EventListenerOrEventListenerObject;
};

export class EventSubscriber {
    public static make() {
        return new this();
    }

    private readonly subscriptions: Map<string, Map<ID, EventListenerOrEventListenerObject>> = new Map();

    public subscribe({
        eventName,
        listener,
        options,
        listenerId
    }: SubscribeArg): this {
        const subscriberId = listenerId ?? this.getListenerCountByEvent(eventName) + 1;

        this.subscriptions.set(
            eventName,
            this.subscriptions.has(eventName)
                ? this.subscriptions
                    .get(eventName)!
                    .set(subscriberId, listener)
                : new Map([
                    [subscriberId, listener]
                ])
        );

        subscribe(eventName, listener, options);

        return this;
    };

    public unsubscribe = ({
        eventName,
        listener,
        options,
        listenerId
    }: UnsubscribeArg): this => {
        const listeners = this.subscriptions.get(eventName);

        if (!listeners) {
            return this;
        }

        if (typeof listenerId !== 'undefined') {
            unsubscribe(
                eventName,
                listeners.get(listenerId)!,
                options
            );

            listeners.delete(listenerId);

            return this;
        }

        const listenersArray = Array.from(listeners.entries());

        if (typeof listener !== 'undefined') {
            for (const [key, value] of listenersArray) {
                if (Object.is(value, listener)) {
                    unsubscribe(
                        eventName,
                        value,
                        options
                    );

                    listeners.delete(key);

                    return this;
                }
            }
        }

        for (const [key, value] of listenersArray) {
            unsubscribe(
                eventName,
                value,
                options
            );

            listeners.delete(key);
        }

        return this;
    };

    private getListenerCountByEvent(eventName: string) {
        return this.subscriptions.get(eventName)?.size || 0;
    }
}
