export const enum RequestQueryMapperStrategy {
    Append = 'append',
    Set = 'set'
};

export const enum Aggregates {
    GroupBys = 'groupBys',
    Subtotals = 'subtotals',
    Subavgs = 'subavgs',
    CountRelations = 'countRelations',
    Havings = 'havings',
    Submins = 'submins',
    Submaxs = 'submaxs'
};
