import type { AsyncThunk } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import type { ElasticSearchResourceResponse, ThunkReturnType } from "types";
import type { RootState } from "infrastructure/store";
import { ApiRouteTypes } from "consts/enpoints/api";
import { payloadCreator } from "util/api";
import { concat } from "util/support";
import { ApiState } from "infrastructure/api";
import type { MerchantPayoutFilesResponse } from "./types";
import { sliceToken } from "./state";
import { getEsMerchantPayoutFiles, getMerchantPayoutFiles } from "./api";
import { getEsTransactions } from "features/transactions/api";

export const getMerchantPayoutFilesThunk: AsyncThunk<
    ThunkReturnType<MerchantPayoutFilesResponse>,
    string,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetMerchantPayoutFiles]),
    payloadCreator(getMerchantPayoutFiles),
    {
        condition: (_, { getState }) => {
            const { finance } = getState() as RootState;

            return ![ApiState.Pending].includes(finance.tableLoadingState);
        }
    }
);

export const getEsMerchantPayoutFilesThunk: AsyncThunk<
    ThunkReturnType<ElasticSearchResourceResponse<string>>,
    string,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetEsMerchantPayoutFiles]),
    payloadCreator(getEsMerchantPayoutFiles),
    {
        condition: (_, { getState }) => {
            const { finance } = getState() as RootState;

            return ![ApiState.Pending].includes(finance.charts.payouts.loadingState);
        }
    }
);

export const getEsTransactionsThunk: AsyncThunk<
    ThunkReturnType<ElasticSearchResourceResponse<string>>,
    string,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetEsTransactions]),
    payloadCreator(getEsTransactions),
    {
        condition: (_, { getState }) => {
            const { finance } = getState() as RootState;

            return ![ApiState.Pending].includes(finance.charts.chargebackRefunds.loadingState);
        }
    }
);
