import { memo } from 'react';
import type { GridRenderCellParams } from '@mui/x-data-grid';
import type { Status as StatusType } from 'types';
import type { Transaction } from 'features/transactions/types';
import { TransactionStatus } from 'ui/widgets/Table/renderers';
import TableCell from './TableCell';

type Props = Pick<Transaction, 'status'> & {
    readonly isLoading: boolean;
};

const Status = ({ status, isLoading }: Props) => {
    const transactionStatusProps = ({
        value: status as StatusType
    }) as GridRenderCellParams<StatusType, {}, StatusType>;

    return (
        <TableCell
            isLoading={isLoading}
            component='th'
            scope='row'
        >
            <TransactionStatus
                {...transactionStatusProps}
            />
        </TableCell>
    );
};

export default memo(Status);
