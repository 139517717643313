import { ApiRoutes } from "consts/enpoints/api";
import { addSeconds, isPast } from "date-fns";
import type { AuthResponse } from "features/auth/types";
import { getAuthData, getAuthToken, setAuthData, isResponseValid, revokeAuthData, } from "features/auth/helpers";
import { FormSerializers } from "infrastructure/api";
import { ACCESS_TOKEN_EXPIRY_THRESHOLD } from "consts/auth";

export const ApiStrategies = {
    [FormSerializers.FormData]: {
        getBody<T>(body: T) {
            return {
                body
            };
        },

        getHeaders(headers = {}) {
            return {
                ...tokenHeaderMixin(),
                ...headers
            } as Record<string, string>;
        }
    },
    [FormSerializers.Json]: {
        getBody<T>(body?: T) {
            if (body) {
                return {
                    body: JSON.stringify(body)
                };
            }

            return {};
        },

        getHeaders(headers = {}) {
            return {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                ...tokenHeaderMixin(),
                ...headers
            } as Record<string, string>;
        }
    }
};

export class RefreshToken {
    private static refreshTokenRequest: Promise<Response> | null = null;

    private static isExpired(expiryThreshold: number) {
        const authData = getAuthData();

        if (!authData) {
            return false;
        }

        const {
            expires_in: expiresIn,
            timestamp
        } = authData;

        return isPast(addSeconds(timestamp, (expiresIn / expiryThreshold)));
    }

    public static async refresh({
        baseUrl = process.env.REACT_APP_BACKEND_ORIGIN_URL,
        expiryThreshold = ACCESS_TOKEN_EXPIRY_THRESHOLD,
        force = false
    } = {}) {
        if (!force && !this.isExpired(expiryThreshold)) {
            return;
        }

        if (!this.refreshTokenRequest) {
            this.refreshTokenRequest = fetch(`${baseUrl}${ApiRoutes.PostRefreshToken}`, {
                method: 'post',
                headers: ApiStrategies[FormSerializers.Json].getHeaders(
                    tokenHeaderMixin()
                )
            });
        }

        const handleRevokeAuthData = () => {
            revokeAuthData();
            this.refreshTokenRequest = null;
        };

        const response = await this.refreshTokenRequest;
        let result: AuthResponse | null = null;

        if (response.ok) {
            result = (await response.json()) as AuthResponse;

            if (!isResponseValid(result)) {
                handleRevokeAuthData();
                throw result;
            }

            const { data } = result;

            setAuthData({
                ...data,
                timestamp: Date.now()
            });
        } else {
            handleRevokeAuthData();
            throw response;
        }

        this.refreshTokenRequest = null;
        return result;
    }
}

function tokenHeaderMixin() {
    const token = getAuthToken();
    if (token) {
        return {
            'Authorization': `Bearer ${token}`
        };
    }
    return {};
}
