import type { AsyncThunk } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiRouteTypes } from "consts/enpoints/api";
import { payloadCreator } from "util/api";
import { concat } from "util/support";
import { sliceToken } from "./state";
import { getPayoutAccounts, getPayoutCryptoAccounts } from "./api";
import type { RootState } from "infrastructure/store";
import { ApiState } from "infrastructure/api";
import type { PayoutAccountsResponse, PayoutCryptoAccountsResponse } from "./types";
import type { ThunkReturnType } from "types";

export const getPayoutAccountsThunk: AsyncThunk<
    ThunkReturnType<PayoutAccountsResponse>,
    void,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetPayoutAccounts]),
    payloadCreator(getPayoutAccounts),
    {
        condition: (_, { getState }) => {
            const { general } = getState() as RootState;

            return (
                !general.payoutAccounts &&
                ![ApiState.Pending].includes(general.payoutAccountsLoadingState)
            );
        }
    }
);
export const getPayoutCryptoAccountsThunk: AsyncThunk<
    ThunkReturnType<PayoutCryptoAccountsResponse>,
    void,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetPayoutCryptoAccounts]),
    payloadCreator(getPayoutCryptoAccounts),
    {
        condition: (_, { getState }) => {
            const { general } = getState() as RootState;

            return (
                !general.payoutCryptoAccounts &&
                ![ApiState.Pending].includes(general.payoutCryptoAccountsLoadingState)
            );
        }
    }
);
