import { memo, PropsWithChildren } from 'react';
import { Helmet, type HelmetProps } from "react-helmet-async";

const Screen = ({ children, title, ...helmetProps }: PropsWithChildren<HelmetProps>) => (
    <>
        <Helmet
            {...helmetProps}
            title={`${title} | Macropay | Tech and advisory for financial institutions`}
        />
        {children}
    </>
);

export default memo(Screen);
