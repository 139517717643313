import type { AsyncThunk } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiState } from "infrastructure/api";

import type { RootState } from "infrastructure/store";
import { ApiRouteTypes } from "consts/enpoints/api";
import { payloadCreator } from "util/api";
import { concat } from "util/support";
import { sliceToken } from "./state";
import type {
    RolesResponse,
    User,
    UsersMerchantsPivot,
    UsersMorsPivot,
    UsersMerchantsResponse,
    UsersMorsResponse,
    UsersResponse,
    UserMerchantRequestParams,
    UserMorRequestParams
} from "./types";
import {
    associateUserMerchant,
    associateUserMor,
    createOrUpdateUser,
    disassociateUserMerchant,
    disassociateUserMor,
    getRoles,
    getUsers,
    getUsersMerchants,
    getUsersMors
} from "features/users/api";
import type { ID, ResourceResponse, ThunkReturnType } from "types";
import { requestNullValueSanitizer } from "util/transformers";

export const getUsersThunk: AsyncThunk<
    ThunkReturnType<UsersResponse>,
    string,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetUsers]),
    payloadCreator(getUsers),
    {
        condition: (_, { getState }) => {
            const { users } = getState() as RootState;

            return ![ApiState.Pending].includes(users.usersLoadingState);
        }
    }
);

export const getRolesThunk: AsyncThunk<
    ThunkReturnType<RolesResponse>,
    void,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetRoles]),
    payloadCreator(getRoles),
    {
        condition: (_, { getState }) => {
            const { users } = getState() as RootState;

            return ![ApiState.Pending].includes(users.rolesLoadingState) &&
                !users.rolesSlice?.length;
        }
    }
);

export const getUsersMerchantsThunk: AsyncThunk<
    ThunkReturnType<UsersMerchantsResponse>,
    ID | undefined,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetUsersMerchants]),
    payloadCreator(getUsersMerchants),
    {
        condition: (_, { getState }) => {
            const { users } = getState() as RootState;

            return ![ApiState.Pending].includes(users.usersMerchantsLoadingState);
        }
    }
);

export const getUsersMorsThunk: AsyncThunk<
    ThunkReturnType<UsersMorsResponse>,
    ID | undefined,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetUsersMors]),
    payloadCreator(getUsersMors),
    {
        condition: (_, { getState }) => {
            const { users } = getState() as RootState;

            return ![ApiState.Pending].includes(users.usersMorsLoadingState);
        }
    }
);

export const associateUserMerchantThunk: AsyncThunk<
    ThunkReturnType<ResourceResponse<UsersMerchantsPivot>>,
    UserMerchantRequestParams,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.PostUserMerchant]),
    payloadCreator(associateUserMerchant)
);

export const disassociateUserMerchantThunk: AsyncThunk<
    ThunkReturnType<ResourceResponse<null>>,
    UserMerchantRequestParams,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.DeleteUserMerchant]),
    payloadCreator(disassociateUserMerchant)
);

export const associateUserMorThunk: AsyncThunk<
    ThunkReturnType<ResourceResponse<UsersMorsPivot>>,
    UserMorRequestParams,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.PostUserMor]),
    payloadCreator(associateUserMor)
);

export const disassociateUserMorThunk: AsyncThunk<
    ThunkReturnType<ResourceResponse<null>>,
    UserMorRequestParams,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.DeleteUserMor]),
    payloadCreator(disassociateUserMor)
);

export const postUserThunk: AsyncThunk<any, Partial<User>, {}> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.PostUser]),
    payloadCreator(createOrUpdateUser, requestNullValueSanitizer),
    {
        condition: (_, { getState }) => {
            const { users } = getState() as RootState;

            return ![ApiState.Pending].includes(users.userProfileLoadingState);
        }
    }
);
