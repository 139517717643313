import type { MerchantPayoutFile } from "features/finance/types";
import { dateFormater } from "ui/widgets/Table/formatters";
import { withGridCellParams } from "ui/widgets/Table/renderers";

const PatoutInterval = withGridCellParams<string, MerchantPayoutFile>(({ row, ...restProps }) => (
    <>
        {[
            dateFormater({
                ...restProps,
                value: row.dateFrom
            }),
            dateFormater({
                ...restProps,
                value: row.dateTo
            })
        ].join(' - ')}
    </>
));

export default PatoutInterval;
