import { memo, type FC } from "react";
import Box, { BoxProps } from "@mui/material/Box";
import {
    useStatisticDashboardContext,
    esTransactionsGroupByStatusQueryToken
} from "ui/organizms/StatisticDashboard/StatisticDashboardProvider";
import type { ElasticSearchResourceResponse } from "types";
import {
    LinearDiagram,
    LinearDiagramProps,
    TitleNumber,
    diagramPropsPredicate,
    getAverage,
    getLinearChartData,
    integerFormat,
    useLinearDiagram,
    useLinearChartColors
} from "ui/widgets/Charts";
import { Filters } from "consts/filters";
import { statusColorSchemeMap } from "consts/transactions";

type TransactionsVolumeByStatusDiagramProps =
    & Pick<LinearDiagramProps, 'className' | 'isLoading'>
    & {
        readonly payload: ElasticSearchResourceResponse<string>['data'];
    };

const TransactionsVolumeByStatusDiagram = memo(({
    className,
    isLoading,
    payload
}: TransactionsVolumeByStatusDiagramProps) => {
    const {
        total: totalGroupCount,
        data,
        columns
    } = useLinearDiagram({
        isLoading,
        onLoad: () => {
            let totalGroupCount = 0;

            const data = getLinearChartData(payload, {
                periodQueryParameterField: Filters.createdAt,
                periodGroupingField: Filters.createdAt,
                getDatum: row => {
                    const groupCount = Number(row.at(-3));

                    totalGroupCount += groupCount;

                    return {
                        pivot: String(row.at(-1)),
                        x: row.at(-2),
                        y: groupCount
                    };
                }
            });
            return {
                total: totalGroupCount,
                data,
                columns: payload.columns
            };
        }
    });

    return (
        <LinearDiagram
            className={className}
            sx={{
                zIndex: 1
            }}
            title='Transaction Volume'
            subtitle={(
                <Box
                    sx={{
                        fontFamily: 'var(--manrope-font)',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        fontSize: 27
                    }}
                >
                    <TitleNumber
                        isLoading={isLoading}
                        sx={{
                            fontWeight: 'bold'
                        }}
                    >
                        {totalGroupCount}
                    </TitleNumber>
                </Box>
            )}
            subheader={(
                <Box
                    sx={{
                        fontFamily: 'var(--manrope-font)',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        fontSize: 14
                    }}
                >
                    Average per interval unit:
                    <TitleNumber
                        isLoading={isLoading}
                    >
                        {getAverage({
                            columns,
                            total: totalGroupCount,
                            periodGroupingField: Filters.createdAt,
                            periodQueryParameterField: Filters.createdAt
                        })}
                    </TitleNumber>
                </Box>
            )}
            columns={columns}
            chartProps={{
                ...useLinearChartColors(statusColorSchemeMap),
                margin: {
                    right: 130
                },
                yFormat: ' >-,',
                axisLeft: {
                    legend: 'Group Count',
                    format: integerFormat
                },
                data
            }}
            periodGroupingField={Filters.createdAt}
            isLoading={isLoading}
        />
    );
}, diagramPropsPredicate);

const TransactionsVolumeByStatus: FC<BoxProps> = ({ className }) => {
    const statisticDashboard = useStatisticDashboardContext();
    const { payload, isLoading } = statisticDashboard[esTransactionsGroupByStatusQueryToken];

    return (
        <TransactionsVolumeByStatusDiagram
            className={className!}
            isLoading={isLoading}
            payload={payload!}
        />
    );
};

TransactionsVolumeByStatus.defaultProps = {
    className: 'js-transactions-volume-by-status'
};

export default memo(TransactionsVolumeByStatus);
