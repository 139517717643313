import { useRef, useState } from "react";
import type { ID } from "types";
import { publish } from "util/custom-event";
import { EventSubscriber } from "util/event-subscriber";
import useActions from "features/finance/useActions";

const enum FinanceEvents {
    UpdateData = 'FinanceEvents:UpdateData',
};

export default function useFinance() {
    const { reset } = useActions();

    useState(reset);

    const [eventSubscriber] = useState(() => EventSubscriber.make());
    const eventSubscriberRef = useRef(eventSubscriber);
    eventSubscriberRef.current = eventSubscriber;

    const updateData = () =>
        publish(FinanceEvents.UpdateData, {});

    const subscribeUpdateData = (listener: EventListenerOrEventListenerObject, listenerId: ID) =>
        eventSubscriberRef.current.subscribe({
            eventName: FinanceEvents.UpdateData,
            listener,
            listenerId,
        });

    const unsubscribeUpdateData = (listenerId: ID) =>
        eventSubscriberRef.current.unsubscribe({
            eventName: FinanceEvents.UpdateData,
            listenerId,
        });

    return {
        updateData,
        subscribeUpdateData,
        unsubscribeUpdateData
    };
};
