import type { Moment } from "moment";
import type { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import moment from "moment";
import { FilterValueProvider, TValueStrategy, ValueStrategy } from "./types";
import { PredefinedCalendarInterval } from "util/interval";

class DaterangeFilterValueProvider extends FilterValueProvider<DateRange<Moment>> {
    static getDefaultValue() {
        return PredefinedCalendarInterval.defaultValue()
            .getValue();
    }

    static getEmptyValue() {
        return PredefinedCalendarInterval.emptyValue()
            .getValue();
    }

    static getSelectedToValue() {
        return PredefinedCalendarInterval.selectedToValue()
            .getValue();
    }

    static getLast7DaysValue() {
        return PredefinedCalendarInterval.last7Days()
            .getValue();
    }

    static getLast30DaysValue() {
        return PredefinedCalendarInterval.last30Days()
            .getValue();
    }

    public getInitialValue(values: Array<string>) {
        return this.handleChangeValue(
            values.map(this.validateDate.bind(this)) as DateRange<Moment>
        );
    }

    public handleChangeValue(values: DateRange<Moment>) {
        return [
            ...values,
            null,
            null
        ]
            .slice(0, 2)
            .map(this.validateDate.bind(this)) as DateRange<Moment>;
    }

    private validateDate(stringDate: string | Moment | null, modifier: number) {
        let momentValue: Moment | null = moment(stringDate);

        if (!momentValue.isValid()) {
            const [startDay, endDay] = this.getDefaultValue();

            momentValue = (modifier <= 0)
                ? startDay
                : endDay;
        }

        return momentValue;
    }
}

const dateRangeFilterValueStrategy: TValueStrategy<DateRange<Moment>> = {
    [ValueStrategy.Default]:
        new DaterangeFilterValueProvider(
            DaterangeFilterValueProvider.getDefaultValue
        ),
    [ValueStrategy.Empty]:
        new DaterangeFilterValueProvider(
            DaterangeFilterValueProvider.getEmptyValue
        ),
    [ValueStrategy.SelectedTo]:
        new DaterangeFilterValueProvider(
            DaterangeFilterValueProvider.getSelectedToValue
        ),
    [ValueStrategy.Last7Days]:
        new DaterangeFilterValueProvider(
            DaterangeFilterValueProvider.getLast7DaysValue
        ),
    [ValueStrategy.Last30Days]:
        new DaterangeFilterValueProvider(
            DaterangeFilterValueProvider.getLast30DaysValue
        )
};

export default dateRangeFilterValueStrategy;
