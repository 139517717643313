import type { MerchantPayoutFile } from "features/finance/types";
import Box from "@mui/material/Box";
import { financial } from "ui/widgets/Table/formatters";
import { withGridCellParams } from "ui/widgets/Table/renderers";

const Fees = withGridCellParams<number, MerchantPayoutFile>(({ row, ...restProps }) => (
    <Box
        sx={{
            fontWeight: 'bold'
        }}
    >
        {row.currency} {financial({
            ...restProps,
            value: `${(
                Number(row.totalGross) - Number(row.amountPaid) - Number(row.rollingReserveRetained)
            ) + Number(row.rollingReservePaid)}`
        })}
    </Box>
));

export default Fees;
