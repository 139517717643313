import { memo } from "react";
import Box from "@mui/material/Box";
import ProgressLoader from "ui/atoms/ProgressLoader";
import useFeesMethods from "./useFeesMethods";
import FeeCategory from "./FeeCategory";
import FeeTitle from "./FeeTitle";
import GeneralFees from "./GeneralFees";

const FeesMethods = () => {
    const {
        isLoading,
        getGeneralFeesMethods,
        getFeesMethods,
        getGeneralFees
    } = useFeesMethods();

    return (
        <ProgressLoader
            isLoading={isLoading}
            WrapperComponent={Box}
            WrapperComponentProps={{
                sx: {
                    display: 'grid',
                    placeContent: 'center',
                    height: 'calc(100vh - 422px)'
                }
            }}
        >
            <Box>
                <FeeCategory
                    header='General Fees'
                    renderFeeTitle={() => null}
                    getFeesMethods={getGeneralFeesMethods}
                    feesProps={{
                        sx: {
                            backgroundColor: 'transparent',
                            overflow: 'visible',
                            boxShadow: 'none',
                            '& .MuiAccordionDetails-root': {
                                px: 0
                            }
                        }
                    }}
                >
                    <GeneralFees
                        sx={{
                            mb: 4
                        }}
                        fees={getGeneralFees()}
                    />
                </FeeCategory>
                <FeeCategory
                    sx={{
                        mt: 5
                    }}
                    header='Payment Method Fees'
                    renderFeeTitle={tag => (
                        <FeeTitle tag={tag} />
                    )}
                    getFeesMethods={getFeesMethods}
                    feesProps={{
                        defaultExpanded: true
                    }}
                />
            </Box>
        </ProgressLoader>
    );
};

export default memo(FeesMethods);