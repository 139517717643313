import { memo } from 'react';
import Resource from 'ui/layouts/Resource';
import StatisticDashboardProvider from './StatisticDashboardProvider';
import StatisticDashboardFilters from './StatisticDashboardFilters';
import StatisticDashboardData from './StatisticDashboardData';
import StatisticDashboardCassettes from './StatisticDashboardCassettes';
import { FilterVariant, date } from 'consts/filters';
import { multi } from 'consts/dashboard';

const StatisticDashboard = () => (
    <Resource
        title='Merchants | Dashboard'
        breadcrumbsProps={{
            heading: 'Dashboard',
            links: [{ name: '' }],
            sx: {
                px: {
                    xs: 2,
                    lg: 0
                }
            }
        }}
    >
        <StatisticDashboardProvider
            filterTypes={new Map<FilterVariant, string[]>([
                [FilterVariant.MultiOption, multi],
                [FilterVariant.DateRange, date]
            ])}
        >
            <StatisticDashboardFilters />
            <StatisticDashboardCassettes />
            <StatisticDashboardData />
        </StatisticDashboardProvider>
    </Resource>
);

export default memo(StatisticDashboard);
