import { memo } from "react";
import { Details, DetailContentProps } from "ui/molecules/TableGrids";
import CodeSnippet from "ui/molecules/TableGrids/Details/CodeSnippet";
import DetailsGeneralInfo from "ui/molecules/TableGrids/Details/DetailsGeneralInfo";
import DetailTabs from "ui/molecules/TableGrids/Details/DetailTabs";
import useTransactionDetails from "./useTransactionDetails";
import TransactionActions from "./TransactionActions";
import { useTypedSelector } from "hooks";
import { selectIsTransactionDetailsLoading } from "features/transactions/selectors";
import useTransactionHistory from "./useTransactionHistory";
import useStatusesHistory from "./useStatusesHistory";

const TransactionDetails = ({ row: transaction }: DetailContentProps) => {
    const {
        transactionRequest,
        transactionResponse,
        transactionDecision
    } = transaction;

    const transactionDetails = useTransactionDetails(transaction);

    const transactionHistoryCollection = useTransactionHistory(transaction);

    const statusesHistory = useStatusesHistory({
        transactionHistoryCollection,
        transaction
    });

    const isTransactionDetailsLoading = useTypedSelector(selectIsTransactionDetailsLoading);

    return (
        <Details
            row={transaction}
            transactionActionsSlot={
                <TransactionActions
                    {...transactionDetails}
                />
            }
            generalInfoSlot={
                <DetailsGeneralInfo
                    isLoading={isTransactionDetailsLoading}
                    {...({
                        ...transactionDetails,
                        statusesHistory
                    })}
                />
            }
            tabsSlot={
                <DetailTabs
                    onTabChange={() => {}}
                    model={[
                        {
                            label: 'Request',
                            component: (
                                <CodeSnippet
                                    data={transactionRequest}
                                />
                            )
                        },
                        {
                            label: 'Response',
                            component: (
                                <CodeSnippet
                                    data={transactionResponse}
                                />
                            )
                        },
                        {
                            label: 'System Decision',
                            component: (
                                <CodeSnippet
                                    data={transactionDecision}
                                />
                            )
                        }
                    ]}
                />
            }
        />
    );
};

export default memo(TransactionDetails);
