import { configureStore } from "@reduxjs/toolkit";

import type { ConfigureStoreOptions } from "@reduxjs/toolkit";

import { globalErrorHandler } from "infrastructure/store/middleware/globalErrorHandler";
import rootReducer from "infrastructure/store/reducers/root";

export const createStore = (
  options?: ConfigureStoreOptions["preloadedState"] | undefined
) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(globalErrorHandler),
    ...options,
  });

const store = createStore();

export default store;
