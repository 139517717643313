import { UserRolesNames } from "consts/auth";
import type { User } from "features/users/types";
import type { ResourceResponse } from "types";

export type UserCredentials = {
  readonly email: string;
  readonly password: string;
};

export enum AuthStatus {
  Authenticated = 'authenticated',
  Guest = 'guest',
  Pending = 'pending',
  Uninitialized = 'uninitialized'
};

export type AuthState<T = User> = {
  status: AuthStatus;
  user: T | null;
};

export type AuthResponse = ResourceResponse<{
  readonly access_token: string;
  readonly token_type: string;
  readonly expires_in: number;
}>;

export type AuthData = AuthResponse['data'] & {
  readonly timestamp: number;
};

export type Authorizable = {
  readonly roles?: Array<UserRolesNames> | string;
};
