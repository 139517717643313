import { combineReducers } from "@reduxjs/toolkit";

import generalReducer, { name as general } from "features/general/slice";
import authReducer, { name as auth } from "features/auth/slice";
import transactionsReducer, { name as transactions } from "features/transactions/slice";
import consumersReducer, { name as consumers } from "features/consumers/slice";
import merchantsReducer, { name as merchants } from "features/merchants/slice";
import paymentMethodsReducer, { name as paymentMethods } from "features/paymentMethods/slice";
import usersReducer, { name as users } from "features/users/slice";
import financeReducer, { name as finance } from "features/finance/slice";
import merchantPayoutsReducer, { name as merchantPayouts } from "features/merchantPayouts/slice";
import pivotsReducer, { name as pivots } from "features/pivots/slice";
import kycReducer, { name as kyc } from "features/kyc/slice";
import payoutAccountsReducer, { name as payoutAccounts } from "features/payoutAccounts/slice";

const combinedReducer = combineReducers({
    [general]: generalReducer,
    [auth]: authReducer,
    [transactions]: transactionsReducer,
    [consumers]: consumersReducer,
    [merchants]: merchantsReducer,
    [users]: usersReducer,
    [paymentMethods]: paymentMethodsReducer,
    [finance]: financeReducer,
    [merchantPayouts]: merchantPayoutsReducer,
    [pivots]: pivotsReducer,
    [kyc]: kycReducer,
    [payoutAccounts]: payoutAccountsReducer
    /**reducers goes here */
});

export default combinedReducer;
