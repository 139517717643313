import useActions from "features/users/useActions";

export default function useUsers() {
    const {
        getUsers: getResource,
        downloadCsv
    } = useActions();

    // const { onOpen } = useResource();
    //open user modal
    // const onRowClick: GridEventListener<'rowClick'> = ({ row }) => onOpen(row);

    return {
        downloadCsv,
        getResource,
        // onRowClick
    };
};
