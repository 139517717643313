import { memo } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { TransactionDetail } from "features/transactions/types";
import useDetailsGeneralInfo from "./useDetailsGeneralInfo";
import DetailsLoader from "./DetailsLoader";

type Props = {
    readonly isLoading: boolean;
} & Pick<
    TransactionDetail,
    | 'status'
    | 'isPaid'
    | 'settlementStatus'
    | 'settlementStatusDateTime'
    | 'isSettled'
    | 'fundStatus'
    | 'resultMessage'
    | 'transactionId'
    | 'transactionGuid'
    | 'trackingMemberCode'
    | 'currency'
    | 'amount'
    | 'createdAt'
    | 'updatedAt'
    | 'descriptor'
    | 'paymentMethod'
    | 'type'
    | 'direction'
>;

const DetailsGeneralInfo = ({ isLoading, ...props }: Props) => {
    const {
        getGeneralDetails,
        getRowDecoration
    } = useDetailsGeneralInfo(props as TransactionDetail);

    return (
        <DetailsLoader
            isLoading={isLoading}
        >
            <Box>
                <Typography
                    variant='body1'
                    fontWeight={500}
                    mb={2}
                >
                    General Information
                </Typography>
                {getGeneralDetails()
                    .map(({ label, value }, rowIndex) => (
                        <Stack
                            key={String(label)}
                            direction='row'
                            justifyContent='space-between'
                            alignItems='center'
                            sx={getRowDecoration(rowIndex, {
                                backgroundColor: 'ButtonShadow',
                                borderRadius: 10
                            })}
                            p={1}
                        >
                            <Typography variant='caption'>{label}</Typography>
                            <Box>{value}</Box>
                        </Stack>
                    ))}
            </Box>
        </DetailsLoader>
    );
};

export default memo(DetailsGeneralInfo);
