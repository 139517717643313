import type { FinanceState } from "./types";
import { ApiState } from "infrastructure/api";
import { resourceSliceFactory } from "util/resource";

const chart = {
    loadingState: ApiState.Idle,
    payload: null
};

const state: FinanceState = {
    ...resourceSliceFactory(),
    previousUrl: '',
    charts: {
        payouts: {
            ...chart
        },
        chargebackRefunds: {
            ...chart
        }
    }
};

export const sliceToken = 'finance';

export default state;
