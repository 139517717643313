import type { EsTransactionsReserve } from "./types";
import type { MerchantPayoutFilesResponse } from "features/finance/types";
import { getMerchantPayoutFiles } from "features/finance/api";
import { getLedgers } from "features/general/api";
import type { LedgersResponse } from "features/general/types";
import { useQuery, useFetch } from "hooks/useQuery";
import { Filters } from "consts/transactions";
import { Filters as GeneralFilters } from "consts/filters";
import { Paginate } from "consts/table";
import { BooleanSwitchValue } from "consts/general";
import { RequestQueryMapper } from "util/request-query-mapper";
import { getEsTransactions } from "features/transactions/api";
import { useTypedSelector } from "hooks";
import { selectPayoutCurrency } from "features/general/selectors";
import useActions from "features/general/useActions";
import { PendingPayoutRange } from "consts/finance";

export const rollingReverseReserveQueryToken = Symbol('rollingReverseReserveQueryToken');
export const esPendingPayoutsQueryToken = Symbol('esPendingPayoutsQueryToken');
export const lastPayedQueryToken = Symbol('lastPayedQueryToken');

export default function useFinanceCassettes() {
    const { getEnvironmentVariable } = useActions();
    const payoutCurrency = useTypedSelector(selectPayoutCurrency);

    const rollingReverseReserveQuery = useQuery<LedgersResponse>({
        getRequestQueryParams: () =>
            RequestQueryMapper.from()
                .containsIn(Filters.currency, payoutCurrency),
        fetchQuery: getLedgers
    });

    const esPendingPayoutsQuery = useQuery<EsTransactionsReserve>({
        getRequestQueryParams: () => {
            const isProd = !getEnvironmentVariable;
            const requestQueryMapper = RequestQueryMapper.from();

            // WTF Marius ???
            if (isProd) {
                requestQueryMapper
                    .inclusiveRange(
                        GeneralFilters.createdAt,
                        PendingPayoutRange.Production.from(),
                        0
                    );
            } else {
                requestQueryMapper
                    .inclusiveRange(
                        GeneralFilters.createdAt,
                        PendingPayoutRange.Default.from(),
                        0
                    )
                    .inclusiveRange(
                        GeneralFilters.createdAt,
                        PendingPayoutRange.Default.to(),
                        1
                    );
            }
            // end WTF Marius ???

            return requestQueryMapper
                .containsIn(Filters.settlementStatus, 'FUNDSRECEIVED')
                .containsIn(Filters.settlementStatus, 'SUCCEEDED')
                .sum(Filters.amountEur)
                .containsIn(Filters.isPaid, `${BooleanSwitchValue.Off}`);
        },
        fetchQuery: getEsTransactions
    });

    const lastPayedQuery = useQuery<MerchantPayoutFilesResponse>({
        getRequestQueryParams: () =>
            RequestQueryMapper.from()
                .containsIn(Filters.isPaid, `${BooleanSwitchValue.On}`)
                .contains(Paginate.perPage, String(1)),
        fetchQuery: getMerchantPayoutFiles
    });

    return {
        ...useFetch(
            rollingReverseReserveQuery,
            esPendingPayoutsQuery,
            lastPayedQuery
        ),
        [rollingReverseReserveQueryToken]: rollingReverseReserveQuery,
        [esPendingPayoutsQueryToken]: esPendingPayoutsQuery,
        [lastPayedQueryToken]: lastPayedQuery
    };
};
