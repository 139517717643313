import type { Option } from "types";
import { TextFieldProps } from '@mui/material';
import Icon from 'ui/atoms/Icon';
import { ValueStrategy } from '../meta/types';

// Custom behaviour overrides (pluggins port)
export interface MetaConfigSettings extends DOMStringMap {
  readonly initialValueStrategy: ValueStrategy;
  readonly changeValueStrategy: ValueStrategy;
  readonly defaultValueStrategy: ValueStrategy;
};

export type Input = {
  propName?: string;
  readonly label?: string;
  readonly options?: Array<Option>;
  readonly value?: any;
  readonly InputProps?: TextFieldProps;
};

export type Config = {
  readonly filterId: string;
  readonly renderAs: string; // -> FilterRenderTypes
  readonly searchQueryParam?: string;
  readonly inputs: Array<Input>
  readonly icon?: string;
  readonly adornment?: string; // -> 'startAdornment' | 'endAdornment';
  readonly meta?: MetaConfigSettings;
  readonly defaultValue?: any;
};

export default function useFiltersConfigParser() {
  return parseInputs;
};

function parseInputs(inputs: Array<Input>, adornment?: string, icon?: string) {
  return inputs.reduce((accInput, input) => {
    if (input.propName) {
      const { propName, ...restInputProps } = input;

      return {
        ...accInput,
        [propName]: {
          ...parseInput(restInputProps, adornment, icon)
        }
      }
    }

    return {
      ...accInput,
      ...parseInput(input, adornment, icon)
    };
  }, {});
}

function parseInput(currentInput: Input, adornment?: string, icon?: string) {
  return {
    ...currentInput,
    InputProps: {
      ...getInputProps(currentInput.InputProps),
      ...getInputAdornment(adornment, icon)
    }
  }
}

function getInputProps(InputProps?: TextFieldProps) {
  return InputProps instanceof Object ? InputProps : {};
}

function getInputAdornment(adornment?: string, icon?: string) {
  return (adornment && icon) ? {
    [adornment]: <Icon name={icon} />
  } : {};
}


export function typed<T = Array<Config>>(collection: any) {
  return collection as T;
}
