import { ApiRoutes } from "consts/enpoints/api";
import { downloadFileApi, fetchApi } from "infrastructure/api";
import { MerchantPayoutFile } from "./types";
import { generateFileName } from "util/support";
import { RequestQueryMapper } from "util/request-query-mapper";
import { Aggregates } from "consts/request-query";

export const getMerchantPayoutFiles = (queryString: string) =>
  fetchApi(`${ApiRoutes.GetMerchantPayoutFiles()}${queryString}`);

export const getEsMerchantPayoutFiles = (queryString: string) =>
  fetchApi(`${ApiRoutes.GetEsMerchantPayoutFiles()}${RequestQueryMapper
      .from(queryString)
      .aggregates(Aggregates.Submins, '')
      .aggregates(Aggregates.Submaxs, '')
    }`);

export const getMerchantPayoutFilesSettlementXLS = ({ coreId, fileKey }: MerchantPayoutFile) =>
  downloadFileApi(ApiRoutes.GetMerchantPayoutFilesById(coreId, fileKey!), {
    headers: {
      Accept: "application/xls",
    },
    fileName: generateFileName({
      prefix: 'Settlement',
      fileKey: fileKey,
      extension: 'xls'
    })
  });

export const getMerchantPayoutFilesTransactionCSV = ({ coreId, fileKey }: MerchantPayoutFile) =>
  downloadFileApi(ApiRoutes.GetMerchantPayoutFilesTransactionsCSV(coreId), {
    headers: {
      Accept: "text/csv",
    },
    fileName: generateFileName({
      prefix: 'Transactions',
      fileKey: fileKey,
      extension: 'csv'
    })
  });

export const getMerchantPayoutFilesStillPendingSettlementCSV = ({ coreId, fileKey }: MerchantPayoutFile) =>
  downloadFileApi(ApiRoutes.GetMerchantPayoutFilesStillPendingSettlementCSV(coreId), {
    headers: {
      Accept: "text/csv",
    },
    fileName: generateFileName({
      prefix: 'Still Pending Settlement',
      fileKey: fileKey,
      extension: 'csv'
    })
  });
