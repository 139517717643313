import { memo, PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { WebRoutes } from "consts/enpoints/web";

const Support = ({ children, ...props }: PropsWithChildren) => (
    <Box
        {...props}
    >
        {children}
        <Button
            to={WebRoutes.Support}
            variant='contained'
            color='secondary'
            component={Link}
        >
            Contact Support
        </Button>
    </Box>
);

export default memo(Support);
