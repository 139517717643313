import {
    useEffect,
    useState,
    useRef,
    type ForwardedRef,
    type MutableRefObject
} from 'react';
import {
    useFilters,
    useFiltersModel,
    ChangePayload,
    FilterChangePayload,
    type FiltersProps,
    type UseFiltersProps,
    type WidgetProps
} from 'ui/widgets/Filters';
import type { FiltersPublicApi } from '../types';
import { TableGridMode } from 'consts/table';
import { useTypedSelector } from 'hooks';
import { selectIsBootstrapDataLoading } from 'features/general/selectors';
import useTableFiltersConfig from './useTableFiltersConfig';

export type TableFiltersProps<TFilterType extends string> =
    & UseFiltersProps<TFilterType>
    & {
        readonly mode: TableGridMode;
        readonly ref?: ForwardedRef<MutableRefObject<FiltersPublicApi<TFilterType> | null>>;
        readonly onFilterChange?: FiltersProps['onChange'];
        readonly onFiltersReset?: FiltersProps['onReset'];
    }
    & Pick<
        FiltersProps,
        | 'filtersModelOverrides'
        | 'useFilterPropsOverride'
        | 'useVisibilityRules'
        | 'isFiltersOpen'
    >

export default function useTableFilters<TFilterType extends string>({
    mode,
    isFiltersOpen: isInitialOpen,
    filterTypes,
    filtersModelOverrides,
    ref: filtersRef,
    fetchData,
    onBeforeFetch,
    onFilterInit,
    onFilterChange,
    onFiltersReset,
    useFilterPropsOverride,
    useVisibilityRules
}: TableFiltersProps<TFilterType>) {
    const filtersApiRef: MutableRefObject<FiltersPublicApi<TFilterType> | null> = useRef<
        FiltersPublicApi<TFilterType>
    >(null);
    const isFiltersApiRefInitialized = useRef<boolean>(false);

    const {
        filters,
        getFiltersRegistryUrlSearchParams,
        onChange,
        onReset,
        onApply
    } = useFilters({
        filterTypes,
        filtersConfig: useTableFiltersConfig(mode),
        fetchData,
        onBeforeFetch,
        onFilterInit
    });

    const [isFiltersOpen, setFiltersOpen] = useState<boolean>(Boolean(isInitialOpen));

    const isBootstrapDataLoading = useTypedSelector(selectIsBootstrapDataLoading);
    const { getFilterOptions } = useFiltersModel(filtersModelOverrides);

    const filterPropsOverride = useFilterPropsOverride!(filters);
    const filterVisibilityPredicate = useVisibilityRules!(filters);

    const handleToggleFilters = () => setFiltersOpen(state => !state);

    const getWidgetProps = (Component?: WidgetProps['Component']) => ({
        Component,
        filters,
        isFiltersOpen
    });

    const isFiltersAvailable = () => filters.length > 0;

    const handleFilterChange = (...args: [ChangePayload<TFilterType, FilterChangePayload>]) => {
        onFilterChange?.(...args);
        onChange(...args);
    };

    const handleFiltersReset: FiltersProps['onReset'] = (...args) => {
        onFiltersReset?.(...args);
        onReset(...args);
    };

    // Expose filters public api
    useEffect(() => {
        filtersApiRef.current = {
            filters,
            getFiltersRegistryUrlSearchParams,
            onChange,
            onReset,
            onApply
        };

        if (filtersRef) {
            if (typeof filtersRef === 'function') {
                !isFiltersApiRefInitialized.current &&
                    filtersRef(filtersApiRef);
                isFiltersApiRefInitialized.current = true;
            } else {
                filtersRef.current = filtersApiRef;
            }
        }
    });

    return {
        filters,
        isFiltersOpen,
        isBootstrapDataLoading,
        isFiltersAvailable,
        filterPropsOverride,
        filterVisibilityPredicate,
        getFiltersRegistryUrlSearchParams,
        getWidgetProps,
        getFilterOptions,
        onChange: handleFilterChange,
        onReset: handleFiltersReset,
        onApply,
        handleToggleFilters
    };
};
