import { ID } from "types";
import { ApiRoutes } from "consts/enpoints/api";
import { fetchApi } from "infrastructure/api";
import { RequestQueryMapper } from "util/request-query-mapper";
import { Aggregates } from "consts/request-query";
import { BooleanSwitchValue } from "consts/general";
import { Filters } from "consts/transactions";

export const getTransactionFilters = () =>
    fetchApi(ApiRoutes.GetTransactionFilters)

export const getBankSdk = (queryString: string) =>
    fetchApi(ApiRoutes.GetBankSdk(queryString));

export const getTransactionById = (transactionId: ID) =>
    fetchApi(ApiRoutes.GetTransactionById(transactionId));

export const getTransactionHistory = (coreId: ID) =>
    fetchApi(ApiRoutes.GetTransactionHistory(coreId));

export const clearExport = () =>
    fetchApi(ApiRoutes.GetClearExport);

export const downloadCsv = () =>
    fetchApi(ApiRoutes.GetDownloadCSV);

export const getInNotifications = (queryString: string) =>
    fetchApi(`${ApiRoutes.GetInNotifications}${queryString}`);

export const getOutNotifications = (queryString: string) =>
    fetchApi(`${ApiRoutes.GetOutNotifications}${queryString}`);

export const getTransactions = (queryString: string) =>
    fetchApi(`${ApiRoutes.GetTransactions()}${queryString}`);

export const getEsTransactions = (queryString: string) =>
    fetchApi(`${ApiRoutes.GetEsTransactions()}${
        RequestQueryMapper
            .from(queryString)
            .aggregates(Aggregates.Submins, '')
            .aggregates(Aggregates.Submaxs, '')
            .containsIn(Filters.parentId, `${BooleanSwitchValue.Off}`)
        }`);

export const getTransactionDetails = (coreId: string) =>
    fetchApi(ApiRoutes.GetTransactions(coreId));

export const getTransactionOrigins = (queryString: string) =>
    fetchApi(`${ApiRoutes.GetTransactionOrigins}${queryString}`);

export const sendNotification = (coreId: string) =>
    processTransactionAction(coreId, {
        Action: 'sendNotification'
    });

export const checkStatus = (coreId: string) =>
    processTransactionAction(coreId, {
        Action: 'checkStatus'
    });

export const fakeRedirect = (coreId: string) =>
    processTransactionAction(coreId, {
        Action: 'fakeRedirect'
    });

export const refund = (coreId: string) =>
    processTransactionAction(coreId, {
        Action: 'refund'
    });
export const settlePingOrder = (coreId: string) =>
    fetchApi(ApiRoutes.PingTransactions(coreId), {
        method: 'PATCH',
        headers: {
            Action: 'settleOrder'
        }
    });

export const closePingOrder = (coreId: string) =>
    fetchApi(ApiRoutes.PingTransactions(coreId), {
        method: 'PUT',
        body: {
            is_order_closed: 1
        }
    });

function processTransactionAction(coreId: string, headers: Record<string, string>) {
    return fetchApi(ApiRoutes.GetTransactions(coreId), {
        method: 'PATCH',
        headers
    });
}

// export const sendExport = (queryString: string) =>
//     fetchApi(`${ApiRoutes.GetTransactions}${queryString}`, {
//         headers: {
//             Accept: 'text/csv'
//         }
//     });
