import { useAppDispatch, useRequest } from "hooks";
import type { ThunkReturnType } from "types";
import type { Account, PayoutAccountsResponse, PayoutCryptoAccountsResponse } from "./types";
import { getPayoutAccountsThunk, getPayoutCryptoAccountsThunk } from "./thunks";
import { selectAccount as selectAccountAction } from "./slice";
import { useCallback } from "react";

const useActions = () => {
    const dispatch = useAppDispatch();

    const getPayoutAccounts = useRequest<ThunkReturnType<PayoutAccountsResponse>, void>({
        thunk: getPayoutAccountsThunk
    });

    const getPayoutCryptoAccounts = useRequest<ThunkReturnType<PayoutCryptoAccountsResponse>, void>({
        thunk: getPayoutCryptoAccountsThunk
    });

    const selectAccount = useCallback((account: Account) => dispatch(selectAccountAction(account)), [dispatch]);

    return {
        getPayoutAccounts,
        getPayoutCryptoAccounts,
        selectAccount
    };
};

export default useActions;
