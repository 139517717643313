import { useMemo } from "react";
import { array, object, ref, string } from "yup";
import type { UserFormProps } from "./types";
import { isMerchantRequired } from "./helpers";

export const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordConfirmation: ''
};

export const formFields = {
    firstName: 'firstName',
    lastName: 'lastName',
    email: "email",
    passwd: "passwd",
    passwordConfirmation: 'passwordConfirmation',
    roleId: 'roleId',
    role: 'role',
    merchants: 'merchants'
};

type UseValidationSchemaArg = Pick<UserFormProps, 'bootstrapData'> & {
    readonly isNew: boolean;
};

const useValidationSchema = ({
    isNew,
    bootstrapData: {
        roles
    }
}: UseValidationSchemaArg) =>
    useMemo(() =>
        object()
            .shape({
                [formFields.firstName]: string()
                    .min(3)
                    .max(50)
                    .required(),
                [formFields.lastName]: string()
                    .min(3)
                    .max(50)
                    .required(),
                [formFields.email]: string()
                    .email()
                    .required(),
                [formFields.roleId]: string()
                    .required(),
                [formFields.merchants]: array()
                    .of(object().shape({
                        id: string().required(),
                        name: string().required()
                    }))
                    .when(formFields.roleId, {
                        is: isMerchantRequired(roles),
                        then: shema => shema
                            .min(1, "Merchants required")
                    }),
                ...(
                    isNew
                        ? ({
                            [formFields.passwd]: string()
                                .min(8, "Password length not enough")
                                .max(50, "You are crazy?")
                                .required("Password required"),
                            [formFields.passwordConfirmation]: string()
                                .required()
                                .oneOf([ref(formFields.passwd), null], 'Passwords must match'),
                        })
                        : ({
                            [formFields.passwd]: string()
                                .min(8, "Password length not enough")
                                .max(50, "You are crazy?")
                                .nullable()
                                .notRequired(),
                            [formFields.passwordConfirmation]: string()
                                .test('passwords-match', 'Passwords must match', function (value) {
                                    return this.parent.passwd === (value ?? null);
                                })
                        })
                )
            }), [isNew, roles]);

export default useValidationSchema;
