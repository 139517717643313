import { useMemo } from 'react';
import { capitalizeFirstLetterFormatter } from 'util/formaters';
import { object, string } from 'yup';

export const enum FormFields {
    Description = 'description'
};

export const getInitialValues = () => ({
    [FormFields.Description]: ''
});

export const useValidationSchema = () =>
    useMemo(() =>
        object().shape({
            [FormFields.Description]: string()
                .min(10)
                .max(2500)
                .notRequired()
                .label(capitalizeFirstLetterFormatter(FormFields.Description))
        })
        , []);
