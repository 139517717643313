import { memo } from "react";
import { TableToolbar, TableToolbarProps } from "ui/widgets/Table";
import { selectIsExportUsersLoading } from "features/users/selectors";
// import useAuth from "features/auth/useAuth";
// import Button from "@mui/material/Button";
// import GroupAddIcon from '@mui/icons-material/GroupAdd';
// import { useResource } from "ui/widgets/Resource";
// import { isAdmin } from "features/auth/helpers";

const UsersToolbar = ({ onExport }: Pick<TableToolbarProps, 'onExport'>) => {
    // const { onOpen } = useResource();
    // const { user } = useAuth();

    // const roleName = user?.roleName;

    // const add = () => (
    //     <>
    //         {isAdmin(roleName) && (
    //             <Button
    //                 size='small'
    //                 startIcon={<GroupAddIcon />}
    //                 onClick={() => onOpen()}
    //             >
    //                 Add User
    //             </Button>
    //         )}
    //     </>
    // );

    return (
        <TableToolbar
            onExport={onExport}
            selectIsExportLoading={selectIsExportUsersLoading}
            // add={add}
        />
    );
};

export default memo(UsersToolbar);
