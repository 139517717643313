import type { GridSortDirection, GridSortItem, GridSortModel } from "@mui/x-data-grid";
import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { splitWithModifier } from "util/filters";
import { sortModelQueryField } from "consts/table";

type UseSortArgs = {
    readonly fetchData: (urlSearchParams?: URLSearchParams) => Promise<void>;
};

// Sort schema: sort=column:order
export default function useSort({ fetchData }: UseSortArgs) {
    const [urlSearchParams, setUrlSearchQuery] = useSearchParams();

    const sortModelRef = useRef<GridSortModel>([]);

    useEffect(() => {
        sortModelRef.current = urlSearchParams
            .getAll(sortModelQueryField)
            .map(toSortItem);
    }, [urlSearchParams]);

    const getSortUrlSearchParams = (params = new URLSearchParams()) =>
        sortModelRef.current.reduce((
            searchParams: URLSearchParams,
            sortItem: GridSortItem
        ) => {
            searchParams.append(sortModelQueryField, fromSortItem(sortItem));

            return searchParams;
        }, params)

    const onSortModelChange = (sortModel: GridSortModel) => {
        sortModelRef.current = sortModel;

        urlSearchParams.delete(sortModelQueryField);

        const sortSearchQuery = getSortUrlSearchParams(urlSearchParams);

        setUrlSearchQuery(sortSearchQuery);

        fetchData(sortSearchQuery);
    };

    return {
        sortModel: sortModelRef.current,
        onSortModelChange,
        getSortUrlSearchParams
    };
};

function fromSortItem({ field, sort }: GridSortItem) {
    return `${field}:${sort}`;
}

function toSortItem(sortingValue: string): GridSortItem {
    const [field, sortDirection] = splitWithModifier(sortingValue);

    const sort = sortDirection as GridSortDirection;

    return {
        field,
        sort
    };
}
