import type { IncrementTaxes } from "./types";

export const groupIncrementTaxes = (incrementTaxes: Array<IncrementTaxes>) =>
    Object
        .entries(Object.groupBy(
            incrementTaxes,
            ({ tag }: IncrementTaxes) => tag
        ))
        .reduce((map, [tag, incrementTaxes]) =>
            map.set(tag, Object
                .entries(Object.groupBy(
                    incrementTaxes,
                    ({ taxName }: IncrementTaxes) => taxName
                ))
                .reduce((map, [taxName, incrementTaxes]) => {
                    const refColFromMinSumMap = new Map<number, number>();

                    return map.set(
                        taxName,
                        incrementTaxes
                            .sort((incrementTaxesA, incrementTaxesB) => (
                                incrementTaxesA.refColFromMinSum - incrementTaxesB.refColFromMinSum
                            ))
                            .reduce((incrementTaxes, incrementTaxesEntity) => {
                                const { refColFromMinSum, currentIncMultiplierForEachRefColumn } = incrementTaxesEntity;

                                if (refColFromMinSumMap.has(refColFromMinSum)) {
                                    refColFromMinSumMap.set(
                                        refColFromMinSum,
                                        refColFromMinSumMap.get(refColFromMinSum)! + (Number.parseFloat(`${currentIncMultiplierForEachRefColumn}`) || 0)
                                    );

                                    return incrementTaxes
                                        .map(incrementTaxesEntity => ({
                                            ...incrementTaxesEntity,
                                            currentIncMultiplierForEachRefColumn: Object.is(incrementTaxesEntity.refColFromMinSum, refColFromMinSum)
                                                ? refColFromMinSumMap.get(refColFromMinSum)!
                                                : incrementTaxesEntity.currentIncMultiplierForEachRefColumn
                                        }));
                                }

                                refColFromMinSumMap.set(
                                    refColFromMinSum,
                                    currentIncMultiplierForEachRefColumn
                                );

                                return [
                                    ...incrementTaxes,
                                    incrementTaxesEntity
                                ];
                            }, Array.of<IncrementTaxes>())
                    );
                }, new Map<string, IncrementTaxes[]>())
            ), new Map<
                string | null,
                Map<string, IncrementTaxes[]>
            >());
