import { memo, type ReactNode } from "react";
import Tooltip from "@mui/material/Tooltip";
import TextField, { type TextFieldProps } from "@mui/material/TextField";
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import useClipboard from "hooks/useClipboard";

type Props = TextFieldProps & {
    readonly renderTooltip?: (clipboardContent: string) => ReactNode;
};

const ClipboardTextField = ({
    renderTooltip,
    value,
    defaultValue,
    ...props
}: Props) => {
    const {
        content,
        copy,
        reset,
        cancel
    } = useClipboard();

    const handleCopyToClipboard = () =>
        copy(String(value ?? defaultValue));

    return (
        <TextField
            {...props}
            value={value}
            defaultValue={defaultValue}
            InputProps={{
                ...props.InputProps,
                endAdornment: (
                    <Tooltip
                        title={renderTooltip!(content)}
                        arrow
                        placement='top'
                        onOpen={cancel}
                        onClose={reset}
                    >
                        <IconButton
                            onClick={handleCopyToClipboard}
                        >
                            <ContentCopyIcon />
                        </IconButton>
                    </Tooltip>
                )
            }}
        />
    );
};

ClipboardTextField.defaultProps = {
    renderTooltip: (clipboardContent: string) =>
        clipboardContent
            ? 'Copied!'
            : 'Copy to clipboard'
};

export default memo(ClipboardTextField);
