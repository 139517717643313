import useAuth from "features/auth/useAuth";
import navConfig from "./config";
import { UserRolesNames } from "consts/auth";

export default function useNavConfig() {
    const { user } = useAuth();

    const getConfig = () => {
        const navItemsMap = new Map<UserRolesNames, Array<string>>([
            [UserRolesNames.MerchantEmployee, [
                'home',
                'dashboard',
                'transactions',
                'support'
            ]],
            [UserRolesNames.MerchantFinance, [
                'home',
                'dashboard',
                'transactions',
                'support',
                'finance',
                'profile'
                // TODO Profile section only: Financial Details tab
            ]],
            [UserRolesNames.MerchantTech, [
                'home',
                'transactions',
                'support',
                'profile'
                // TODO Profile section only: Fees & Methods tab, Technical information tab
            ]]
        ]);

        const hasTitle = (title: string) =>
            navItemsMap.get(user?.roleName)
                ?.includes(title) ?? true;

        return navConfig.reduce((config, navConfigItem) => [
            ...config,
            {
                ...navConfigItem,
                items: navConfigItem.items.filter(({ title }) => hasTitle(title))
            }
        ], [] as typeof navConfig);
    };

    return {
        getConfig
    };
};
