import { memo, useMemo } from "react";
import type { LastPayoutProps } from "./types";
import Cassette from "./Cassette";
import { fromArray } from "util/support";

const LastPayout = ({ lastPayedQuery }: LastPayoutProps) => {
    const { payload } = lastPayedQuery;

    const [updatedAt, amountPaid] = useMemo(() => {
        const { updatedAt, amountPaid = 0 } = fromArray(payload?.data)
            .at(0) ?? {};

        return [updatedAt, amountPaid];
    }, [payload]);

    return (
        <Cassette
            isPaid={Boolean(updatedAt)}
            labelSlot='Last payout'
            isLoading={lastPayedQuery.isLoading}
            paidDate={updatedAt}
            amountSlot={amountPaid}
        />
    );
};

export default memo(LastPayout);
