import type { User as UserType } from "features/users/types";
import { memo, useCallback } from "react";
import ResourceLayout from 'ui/layouts/Resource';
import Resource from "ui/widgets/Resource";
import type { GridRowIdGetter } from "@mui/x-data-grid";
import useUsers from "./useUsers";
import { TableGridMode } from "consts/table";
import {
    selectIsUsersLoading,
    selectIsUsersUninitialized,
    selectUserSlice
} from "features/users/selectors";
import UsersToolbar from "./UsersToolbar";
import UsersCreateEditModal from "./UsersCreateEditModal";
import { NoDataOverlay } from "ui/molecules/TableGrids";

const User = () => {
    const getRowId: GridRowIdGetter<UserType> = useCallback(({ coreId }: UserType) =>
        coreId, []);

    return (
        <ResourceLayout
            title='Merchants | Users'
            breadcrumbsProps={{
                heading: 'Users',
                links: [{ name: '' }],
                sx: {
                    ml: 4,
                    mb: 0,
                    '& + div > div': {
                        mt: 0
                    }
                }
            }}
        >
            <Resource
                useResource={useUsers}
                mode={TableGridMode.User}
                selectIsTableUninitialized={selectIsUsersUninitialized}
                selectIsTableLoading={selectIsUsersLoading}
                selectTableDataSlice={selectUserSlice}
                getRowId={getRowId}
                Toolbar={UsersToolbar}
                NoRowsOverlay={() => (
                    <NoDataOverlay
                        DataEntityName='users'
                    />
                )}
                Modal={UsersCreateEditModal}
            />
        </ResourceLayout>
    );
};

export default memo(User);
