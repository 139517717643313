import type { Status } from "types";
import { ThemePaletteMode } from "./ui";

export const Filters = {
    merchantId: 'merchantId',
    merchantName: 'merchantName',
    methodId: 'methodId',
    settlementStatus: 'settlementStatus',
    fundStatus: 'fundStatus',
    isPaid: 'isPaid',
    status: 'status',
    transactionId: 'transactionId',
    refId: 'refId',
    consumerId: 'consumerId',
    trackingMemberCode: 'trackingMemberCode',
    transactionGuid: 'transactionGuid',
    endToEndId: 'endToEndId',
    currency: 'currency',
    PSP: 'PSP',
    isSettled: 'isSettled',
    email: 'email',
    via: 'via',
    bankId: 'bankId',
    bankName: 'bankName',
    amount: 'amount',
    endToEndIdSlug: 'endToEndIdSlug',
    hashedEmail: 'hashedEmail',
    MOR: 'MOR',
    parentId: 'parentId',
    consumerFirstName: 'consumerFirstName',
    consumerLastName: 'consumerLastName',
    identifier: 'identifier',
    iban: 'iban',
    amountEur: 'amountEur',
    consumerIban: 'consumerIban',
    obResultStatus: 'obResultStatus',
    methodTag: 'methodTag',
    consumerKycTransactionId: 'consumerKycTransactionId',
    reason: 'reason',
    countryIso2: 'countryIso2',
    settledDate: 'settledDate',
    cardIssuer: 'cardIssuer',
    sourceUrl: 'sourceUrl'
};

export const OPEN_BANKING_METHOD_TAG = 'op';

export const summs = [
    Filters.amount,
    Filters.amountEur
];

export const text = [
    Filters.transactionId,
    Filters.trackingMemberCode
];

export const multi = [
    Filters.settlementStatus,
    Filters.status,
    Filters.methodTag,
];

export const single = [
    Filters.isPaid,
    Filters.isSettled,
    Filters.obResultStatus
];

export const Banking = {
    filters: [
        Filters.bankName,
        Filters.bankId,
        Filters.endToEndId,
        Filters.endToEndIdSlug
    ],
    visibilityCriterias: [
        35, // methodId (Open Banking)
        50, // (Open Banking TP)
        51, // (Open Banking GC)
        'bankingSdk' // psp bankingSdk
    ]
};

export const OpenBankingEmptyResultStatus = {
    BankInfoNotSubmitted: null,
    BankInfoSubmitted: ''
} as const;

export const enum OpenBankingMessageResultStatus {
    PaymentFinalizedWaitingConfirmation = '1',
    RedirectedConsumerToAuthorizePayment = '2',
    RequestedConsumerToAuthorizePayment = '3',
    RequestedPasswordForConsumer = '4',
    RequestedMoreInfoFromConsumer = '5',
    RequestedOptionFromConsumer = '6',
    //  ErrorHasOccurred = '7',
    RetryToFinalizePaymentAfterPeriod = '8',
    NotDefined = '0'
};

export const enum OpenBankingMessageForAccountsResultStatus {
    AccountInitFinalizedWaitingPaymentInit = '1',
    RedirectedConsumerForAccountConfirmation = '2',
    RequestedConsumerToAuthorizeAccount = '3',
    RetryToFinalizeAccountAfterPeriod = '8'
};

export const enum OpenBankingResultStatus {
    BankInfoNotSubmitted = -1,
    BankInfoSubmitted,
    PaymentFinalizedWaitingConfirmation,
    RedirectedConsumerToAuthorizePayment,
    RequestedConsumerToAuthorizePayment,
    RequestedPasswordForConsumer,
    RequestedMoreInfoFromConsumer,
    RequestedOptionFromConsumer,
    //  ErrorHasOccurred,
    RetryToFinalizePaymentAfterPeriod,
    AccountInitFinalizedWaitingPaymentInit,
    RedirectedConsumerForAccountConfirmation,
    RequestedConsumerToAuthorizeAccount,
    RetryToFinalizeAccountAfterPeriod,
    NotDefined
};

export const enum Relations {
    FlowContext = 'flowContext'
}

export const PER_PAGE_SIZE = 1000;

export const statusColorSchemeMap = new Map<Status, Record<ThemePaletteMode, string>>()
    .set('SUCCEEDED', {
        [ThemePaletteMode.Dark]: '#36FF56',
        [ThemePaletteMode.Light]: '#24AC4A'
    })
    .set('REJECT', {
        [ThemePaletteMode.Dark]: '#FF9166',
        [ThemePaletteMode.Light]: '#FF8C5A'
    })
    .set('CHARGE_BACK', {
        [ThemePaletteMode.Dark]: '#58AFFF',
        [ThemePaletteMode.Light]: '#5869FF'
    })
    .set('FAILED', {
        [ThemePaletteMode.Dark]: '#FF4853',
        [ThemePaletteMode.Light]: '#FF4D57'
    })
    .set('REFUNDED', {
        [ThemePaletteMode.Dark]: '#DA62FF',
        [ThemePaletteMode.Light]: '#CE50F5'
    })
    .set('PENDING', {
        [ThemePaletteMode.Dark]: '#FFEE53',
        [ThemePaletteMode.Light]: '#EFBA00'
    });
