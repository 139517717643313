import { Filters } from "consts/filters";
import { PayoutsBreakdown } from "consts/finance";
import {
    selectPayoutsChartLoadingState,
    selectPayoutsChartPayload
} from "features/finance/selectors";
import { useTypedSelector } from "hooks";
import { memo } from "react";
import { BarDiagram, formatXDateAxis, getBarChartData, getBarDatumKeys } from "ui/widgets/Charts";

const Payouts = () => {
    const isLoading = useTypedSelector(selectPayoutsChartLoadingState);
    const payload = useTypedSelector(selectPayoutsChartPayload);

    const data = getBarChartData(payload, {
        getDatum: row => {
            const totalGross = Number(row.at(0));
            const amountPaid = Number(row.at(1));
            const rollingReservePaid = Number(row.at(2));
            const rollingReserveRetained = Number(row.at(3));
            const updatedAtDay = formatXDateAxis(payload.columns, Filters.updatedAt)(row.at(-1));

            return {
                [PayoutsBreakdown.AmountPaid]: amountPaid,
                [PayoutsBreakdown.Fees]: totalGross - (amountPaid + rollingReserveRetained - rollingReservePaid),
                [PayoutsBreakdown.RRPaid]: rollingReservePaid,
                [PayoutsBreakdown.RRRetained]: rollingReserveRetained,
                [Filters.updatedAt]: updatedAtDay
            };
        },
        getIndex: row => String(row.at(-1)),
        applyFilter: data => {
            const validValuesRegistry = new Map<string, boolean[]>();
            const isValid = (key: string) =>
                validValuesRegistry.get(key)!.includes(true);

            for (const datum of data) {
                for (const [key, value] of Object.entries(datum)) {
                    if (!validValuesRegistry.has(key)) {
                        validValuesRegistry.set(key, []);
                    }

                    validValuesRegistry.get(key)!.push(Boolean(value));
                }
            }

            return data.map(datum => {
                for (const key of Object.keys(datum)) {
                    if (!isValid(key)) {
                        delete datum[key];
                    }
                }

                return datum;
            });
        }
    });

    return (
        <BarDiagram
            isLoading={isLoading}
            title='Paid Payouts'
            chartProps={{
                indexBy: 'updatedAt',
                keys: getBarDatumKeys([
                    PayoutsBreakdown.AmountPaid,
                    PayoutsBreakdown.Fees,
                    PayoutsBreakdown.RRPaid,
                    PayoutsBreakdown.RRRetained
                ], data),
                axisLeft: {
                    legend: `Amounts`
                },
                data,
                isStacked: true
            }}
        />
    );
};

export default memo(Payouts);
