import Screen from 'ui/layouts/Screen';
import ProfileComponent from 'ui/organizms/Profile';

const Profile = () => (
    <Screen
        title='Profile'
    >
        <ProfileComponent />
    </Screen>
);

export default Profile;