import { createSlice } from "@reduxjs/toolkit";
import {
  constantsRequestFulfilled,
  constantsRequestLoading,
  constantsRequestRejected,
  countriesRequestFulfilled,
  countriesRequestLoading,
  countriesRequestRejected,
  domainsRequestFulfilled,
  domainsRequestLoading,
  domainsRequestRejected,
  settingsRequestLoading,
  settingsRequestFulfilled,
  settingsRequestRejected,
  userPreferencesRequestFulfilled,
  userPreferencesRequestLoading,
  userPreferencesRequestRejected,
  ledgersRequestLoading,
  ledgersRequestFulfilled,
  ledgersRequestRejected
} from "./reducers";
import initialState from "./state";
import {
  createOrUpdateUserPreferencesThunk,
  getConstantsThunk,
  getCountriesThunk,
  getDomainsThunk,
  getLedgersThunk,
  getSettingsThunk,
  getUserPreferencesThunk
} from "./thunks";

const slice = createSlice({
  name: "general",
  initialState,
  reducers: {
    toggleSideMenu: (state) => {
      state.isSideMenuOpen = !state.isSideMenuOpen;
    },
    addNotification: (state, { payload }) => {
      state.notifications.push(payload);
    },
    removeNotification: (state) => {
      state.notifications.shift();
    },
    changeCountriesLoadingState: (_, __) => {},
    changeCountriesSlice: (state, { payload }) => {
      state.countries = payload.data.data;
    }
  },
  extraReducers: builder =>
    builder
      // Constants
      .addCase(getConstantsThunk.pending, constantsRequestLoading)
      .addCase(getConstantsThunk.fulfilled, constantsRequestFulfilled)
      .addCase(getConstantsThunk.rejected, constantsRequestRejected)
      .addCase(getDomainsThunk.pending, domainsRequestLoading)
      .addCase(getDomainsThunk.fulfilled, domainsRequestFulfilled)
      .addCase(getDomainsThunk.rejected, domainsRequestRejected)
      // Countries
      .addCase(getCountriesThunk.pending, countriesRequestLoading)
      .addCase(getCountriesThunk.fulfilled, countriesRequestFulfilled)
      .addCase(getCountriesThunk.rejected, countriesRequestRejected)
      // User preferences
      .addCase(getUserPreferencesThunk.pending, userPreferencesRequestLoading)
      .addCase(getUserPreferencesThunk.fulfilled, userPreferencesRequestFulfilled)
      .addCase(getUserPreferencesThunk.rejected, userPreferencesRequestRejected)
      .addCase(createOrUpdateUserPreferencesThunk.fulfilled, userPreferencesRequestFulfilled)
      .addCase(createOrUpdateUserPreferencesThunk.rejected, userPreferencesRequestRejected)
      // Settings
      .addCase(getSettingsThunk.pending, settingsRequestLoading)
      .addCase(getSettingsThunk.fulfilled, settingsRequestFulfilled)
      .addCase(getSettingsThunk.rejected, settingsRequestRejected)
      // Ledgers
      .addCase(getLedgersThunk.pending, ledgersRequestLoading)
      .addCase(getLedgersThunk.fulfilled, ledgersRequestFulfilled)
      .addCase(getLedgersThunk.rejected, ledgersRequestRejected)
});

export const { name } = slice;
export const {
  toggleSideMenu,
  addNotification,
  removeNotification,
  changeCountriesLoadingState,
  changeCountriesSlice
} = slice.actions;
export default slice.reducer;
