import type { ElasticSearchResourceResponse, ThunkReturnType } from "types";
import { useRequest } from "hooks";
import {
    getEsMerchantPayoutFilesThunk,
    getEsTransactionsThunk
} from "./thunks";
import useAggregates from "hooks/useAggregates";
import { RequestQueryMapper } from "util/request-query-mapper";
import { Aggregates } from "consts/request-query";
import { PeriodAdapter, backendRequestSearchQueryParamsAdapterFactory } from "util/period-adapter";
import { Filters as FinanceFilters } from "consts/finance";
import { Filters as TransactionFilters } from "consts/transactions";
import { Filters as CommonFilters } from "consts/filters";

const Filters = {
    ...FinanceFilters,
    ...TransactionFilters,
    ...CommonFilters
};

export default function useChartQueries() {
    const getEsMerchantPayoutFiles = useRequest<ThunkReturnType<ElasticSearchResourceResponse<string>>, string>({
        thunk: getEsMerchantPayoutFilesThunk
    });

    const getEsTransactions = useRequest<ThunkReturnType<ElasticSearchResourceResponse<string>>, string>({
        thunk: getEsTransactionsThunk
    });

    const payoutsChartQuery = useAggregates<ReturnType<typeof getEsMerchantPayoutFiles>>((requestQueryMapper: RequestQueryMapper) => (
        baseQueryFactory(requestQueryMapper, Filters.updatedAt)
            .aggregates(Aggregates.Subtotals, Filters.amountPaid)
            .aggregates(Aggregates.Subtotals, Filters.rollingReservePaid)
            .aggregates(Aggregates.Subtotals, Filters.totalGross)
            .aggregates(Aggregates.Subtotals, Filters.rollingReserveRetained)
            .aggregates(Aggregates.Subavgs)
    ));

    const chargebackRefundsChartQuery = useAggregates<ReturnType<typeof getEsTransactions>>((requestQueryMapper: RequestQueryMapper) => (
        baseQueryFactory(requestQueryMapper, Filters.createdAt)
            .containsIn(Filters.status, 'CHARGE_BACK')
            .containsIn(Filters.status, 'REFUNDED')
            .containsIn(Filters.status, 'SUCCEEDED')
            .aggregates(Aggregates.GroupBys, Filters.status)
            .aggregates(Aggregates.Subtotals)
            .aggregates(Aggregates.Subavgs)
    ));

    return {
        payoutsChartQuery: payoutsChartQuery(getEsMerchantPayoutFiles),
        chargebackRefundsChartQuery: chargebackRefundsChartQuery(getEsTransactions)
    };
};

function baseQueryFactory({ searchQueryParams }: RequestQueryMapper, field: string) {
    const [fromDate, toDate] = [
        searchQueryParams.get(
            RequestQueryMapper.getInclusiveRangeQueryName(Filters.updatedAt, 0)
        ),
        searchQueryParams.get(
            RequestQueryMapper.getInclusiveRangeQueryName(Filters.updatedAt, 1)
        )
    ];

    return RequestQueryMapper.from()
        .inclusiveRange(field, `${fromDate}`, 0)
        .inclusiveRange(field, `${toDate}`, 1)
        .aggregates(
            Aggregates.GroupBys,
            PeriodAdapter.make(
                backendRequestSearchQueryParamsAdapterFactory(Filters.updatedAt),
                field
            ).get(searchQueryParams)
        );
}
