import { memo, useMemo } from "react";
import { BooleanSwitchValue, CurrencySymbol } from "consts/general";
import { fNumber } from "util/formaters";
import Cassette from "./Cassette";
import {
    distinctEmailTransactionsRequestQueryParamsFactory,
    esTransactionsGroupByCurrencyQueryToken,
    totalCountRequestQueryParamsFactory,
    useStatisticDashboardContext
} from "../StatisticDashboardProvider";
import { useQuery } from "hooks/useQuery";
import type { ResourceResponse } from "types";
import type { TransactionsResponse } from "features/transactions/types";
import type { EsTransactionsResponse } from "../StatisticDashboardProvider";
import { getEsTransactions, getTransactions } from "features/transactions/api";
import { getPromiseSettledResourceResult } from "util/resource";
import { Filters } from "consts/transactions";

const AvgTransactionSize = () => {
    const statisticDashboard = useStatisticDashboardContext();

    const { payload, isLoading } = statisticDashboard[esTransactionsGroupByCurrencyQueryToken];

    const [totalAmountEur, totalGroupCount] = useMemo(() => {
        const initialValues = Array.of(0, 0);

        return payload?.rows.reduce(([totalAmountEur, totalGroupCount], row) => [
            Number(totalAmountEur) + Number(row.at(1)),
            Number(totalGroupCount) + Number(row.at(-3))
        ], initialValues) ?? initialValues
    }, [payload]);

    const initialState = {
        isLoading: false
    };

    const distinctEmailsQuery = useQuery<TransactionsResponse>({
        getRequestQueryParams: requestQueryMapper =>
            distinctEmailTransactionsRequestQueryParamsFactory('SUCCEEDED')(
                requestQueryMapper
                    .containsIn(Filters.parentId, `${BooleanSwitchValue.Off}`)
            ),
        fetchQuery: getTransactions,
        initialState
    });

    const totalCountTransactionsPerDistictEmailsQuery = useQuery<EsTransactionsResponse>({
        getRequestQueryParams: requestQueryMapper =>
            totalCountRequestQueryParamsFactory()(
                requestQueryMapper
                    .containsIn(Filters.status, 'SUCCEEDED')
            ),
        fetchQuery: getEsTransactions,
        initialState
    });

    const query = async (): Promise<ResourceResponse<{
        readonly distinctEmails: TransactionsResponse['data'],
        readonly totalCountTransactionsPerDistictEmails: EsTransactionsResponse['data']
    }>> => {
        const requestSearchParams = statisticDashboard.requestSearchParamsRef.current;

        const [
            distinctEmailsSettledResponse,
            totalCountTransactionsPerDistictEmailsSettledResponse
        ] = await Promise.allSettled([
            distinctEmailsQuery.query(requestSearchParams),
            totalCountTransactionsPerDistictEmailsQuery.query(requestSearchParams)
        ]);

        const [
            distinctEmailsResponse,
            totalCountTransactionsPerDistictEmailsResponse
        ] = [
                getPromiseSettledResourceResult(distinctEmailsSettledResponse),
                getPromiseSettledResourceResult(totalCountTransactionsPerDistictEmailsSettledResponse)
            ];

        return {
            data: {
                distinctEmails: (
                    distinctEmailsResponse as TransactionsResponse
                ).data,
                totalCountTransactionsPerDistictEmails: (
                    totalCountTransactionsPerDistictEmailsResponse as EsTransactionsResponse
                ).data
            },
            message: '',
            code: 200,
            success: true,
            locale: ''
        };
    };

    const getAvgUniqueEmailsPerTransaction = ({
        data: {
            distinctEmails,
            totalCountTransactionsPerDistictEmails
        }
    }: Awaited<ReturnType<typeof query>>) => (
        (totalCountTransactionsPerDistictEmails.total_rows || 0) / (distinctEmails.total || 1)
    );

    return (
        <Cassette
            isLoading={isLoading}
            labelSlot='Average transaction size'
            amountSlot={fNumber(Number(totalAmountEur) / Number(totalGroupCount), '0,0.00')}
            currencySlot={CurrencySymbol.EUR}
            footerSlot='Avg. trx. number / unique email'
            tooltipProps={{
                title: 'Calculate avg. trx. number / unique email'
            }}
            lazyQuery={{
                payload: null,
                isLoading: (
                    totalCountTransactionsPerDistictEmailsQuery.isLoading ||
                    distinctEmailsQuery.isLoading
                ),
                query
            }}
            countGetter={getAvgUniqueEmailsPerTransaction}
            countFormat='0,0.00'
            actionSlot={<></>}
        />
    );
};

export default memo(AvgTransactionSize);
