import { useFormik } from "formik";
import type { FeedbackFormData } from "features/general/types";
import type { FeedbackFormProps } from "./types";
import { getInitialValues, useValidationSchema } from "./validators";

export default function useFeedback(form: FeedbackFormProps) {
    const validationSchema = useValidationSchema();

    const onSubmit: FeedbackFormProps['onSubmit'] = async (values, ...rest) => form.onSubmit(
        validationSchema.cast(values) as FeedbackFormData,
        ...rest
    );

    return useFormik({
        ...form,
        onSubmit,
        isInitialValid: false,
        validateOnMount: true,
        validationSchema,
        initialValues: getInitialValues()
    });
};
