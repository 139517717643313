import { memo } from "react";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
    ProcessedAmountsByCurrency,
    TransactionsVolumeByStatus,
    ProcessedAmountsPerPaymentMethod
} from "./Diagrams";
import { RecentTransactions } from "./Tables";

const StatisticDashboardData = () => (
    <Box
        sx={{
            display: 'grid',
            gridTemplateColumns: `repeat(${Number(useMediaQuery('(min-width:1408px)')) + 1}, 1fr)`,
            gap: 2,
            mt: 2
        }}
    >
        <ProcessedAmountsByCurrency />
        <TransactionsVolumeByStatus />
        <RecentTransactions />
        <ProcessedAmountsPerPaymentMethod />
    </Box>
);

export default memo(StatisticDashboardData);
