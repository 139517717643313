import { memo, useMemo } from "react";
import type { TotalBalanceProps } from "./types";
import Cassette from "./Cassette";
import { fromArray } from "util/support";

const TotalBalance = ({
    rollingReverseReserveQuery,
    esPendingPayoutsQuery
}: TotalBalanceProps) => {
    const isLoading = (
        rollingReverseReserveQuery.isLoading ||
        esPendingPayoutsQuery.isLoading
    );

    const { payload: rollingReverseReserveQueryPayload } = rollingReverseReserveQuery;
    const { payload: esPendingPayoutsQueryPayload } = esPendingPayoutsQuery;

    const amount = useMemo(() => {
        const { rollingReserved = 0 } = fromArray(rollingReverseReserveQueryPayload?.data)
            .at(0) ?? {};

        const amountEur = fromArray(esPendingPayoutsQueryPayload?.rows)
            .at(0)?.[0] || 0;

        return Number(rollingReserved) + Number(amountEur);
    }, [
        rollingReverseReserveQueryPayload,
        esPendingPayoutsQueryPayload
    ]);

    return (
        <Cassette
            labelSlot='Total Balance'
            isLoading={isLoading}
            amountSlot={amount}
        />
    );
};

export default memo(TotalBalance);
