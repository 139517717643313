import type { Transaction, TransactionHistory } from "features/transactions/types";
import useActions from "features/transactions/useActions";
import { useEffect } from "react";
import useTransactionSubject from "./useTransactionSubject";

export default function useTransactionHistory({
    coreId,
    transactionId
}: Pick<
    Transaction,
    | 'coreId'
    | 'transactionId'
>) {
    const { getTransactionHistory } = useActions();

    const fetchData = async () => {
        const [transactionHistoryRequest] = getTransactionHistory(coreId);

        const transactionHistory = await transactionHistoryRequest;

        return (transactionHistory as TransactionHistory).data;
    };

    const [transactionHistory, fetchTransactionHistory] = useTransactionSubject({
        transactionId,
        fetchData
    });

    useEffect(() => {
        fetchTransactionHistory();
    }, [fetchTransactionHistory]);

    return transactionHistory?.data ?? [];
};
