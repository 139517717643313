import { ApiState } from "infrastructure/api";
import type { UsersState } from "./types";

const state: UsersState = {
    usersLoadingState: ApiState.Idle,
    exportLoadingState: ApiState.Idle,
    usersSlice: null,
    userProfileLoadingState: ApiState.Idle,
    rolesLoadingState: ApiState.Idle,
    rolesSlice: null,
    usersMerchantsLoadingState: ApiState.Idle,
    usersMerchantsSlice: null,
    usersMorsLoadingState: ApiState.Idle,
    usersMorsSlice: null
};

export const sliceToken = 'users';

export default state;
