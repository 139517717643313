import { Typography } from "@mui/material";
import Box, { type BoxProps } from "@mui/material/Box";
import type { PropsWithChildren, ReactNode } from "react";

type Props = BoxProps & {
    readonly title?: ReactNode;
};

const Tab = ({ title, children, ...props }: PropsWithChildren<Props>) => (
    <Box
        {...props}
        sx={{
            maxWidth: 1024,
            ...props.sx
        }}
    >
        <Typography
            variant='h6'
            sx={{
                mb: 2
            }}
        >
            {title}
        </Typography>
        <Box>
            {children}
        </Box>
    </Box>
);

export default Tab;
