import { memo } from "react";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Card, { type CardProps } from "ui/atoms/Card";
import type { FeeProps } from "./types";
import Fee from "./Fee";

type Props = CardProps & {
    readonly fees: ReadonlyArray<FeeProps>;
};

const GeneralFees = ({ fees, ...cardProps }: Props) => (
    <Card
        {...cardProps}
    >
        <Stack
            spacing={2}
            direction='row'
            sx={{
                alignItems: 'center'
            }}
            divider={(
                <Divider
                    orientation='vertical'
                    sx={{
                        height: 40
                    }}
                />
            )}
        >
            {fees.map((fee, index) => (
                <Fee
                    key={index}
                    {...fee}
                />
            ))}
        </Stack>
    </Card>
);

export default memo(GeneralFees);
