import { memo } from 'react';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Card from 'ui/atoms/Card';
import type { StatisticDiagramProps } from './types';

const StatisticDiagramCard = ({
    title,
    subtitle,
    subheader,
    children,
    chartProps,
    isLoading,
    isDataAvailable,
    ...restProps
}: StatisticDiagramProps) => (
    <Card
        {...restProps}
    >
        <CardHeader
            title={title}
            subheader={(
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 1
                    }}
                >
                    <Box
                        sx={{
                            width: '100%'
                        }}
                    >
                        {subtitle}
                        {subheader}
                    </Box>
                    {isLoading && isDataAvailable && (
                        <CircularProgress size={30} />
                    )}
                </Box>
            )}
        />
        <div
            style={{
                height: 370,
                width: '100%',
                position: 'relative',
                ...chartProps?.style
            }}
        >
            {children}
            {isLoading && !isDataAvailable && (
                <CircularProgress
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}
                />
            )}
        </div>
    </Card>
);

export default memo(StatisticDiagramCard);