import moment from "moment";
import { DateTimeFormatRepository } from "consts/general";
import { ThemePaletteMode } from "./ui";

export const Filters = {
    updatedAt: 'updatedAt',
    amountPaid: 'amountPaid',
    rollingReservePaid: 'rollingReservePaid',
    rollingReserveRetained: 'rollingReserveRetained',
    totalGross: 'totalGross'
};

export const date = [
    Filters.updatedAt
];

export const enum ChargebackRefundLegendStatus {
    Chargeback = 'CHARGE_BACK',
    Refund = 'REFUNDED'
};

export const enum PayoutsBreakdown {
    AmountPaid = 'Amount Paid',
    Fees = 'Fees',
    RRPaid = 'RR Paid',
    RRRetained = 'RR Retained'
};

// WTF Marius ??? (see useFinanceQueries.ts)
export const PendingPayoutRange = {
    Production: {
        from: () => '2024-01-01T00:00:00'
    },
    Default: {
        from: () => '2019-10-20T00:00:00',
        to: () => moment()
            .endOf('day')
            .format(DateTimeFormatRepository.Read)
    }
};

export const chargebackRefundsColorSchemeMap = new Map<
    ChargebackRefundLegendStatus,
    Record<ThemePaletteMode, string>
>()
    .set(ChargebackRefundLegendStatus.Chargeback, {
        [ThemePaletteMode.Dark]: '#58AFFF',
        [ThemePaletteMode.Light]: '#5869FF'
    })
    .set(ChargebackRefundLegendStatus.Refund, {
        [ThemePaletteMode.Dark]: '#DA62FF',
        [ThemePaletteMode.Light]: '#CE50F5'
    });
