import { memo } from "react";
import { LinearStatisticDiagram } from "ui/molecules/StatisticDiagram";
import type { LinearDiagramProps } from "../types";
import { formatXDateAxis, getAxisBottomTickRotation } from "../support/helpers";
import SliceTooltip from "./SliceTooltip";
import AxisBottomTick from "./AxisBottomTick";

const LinearDiagram = ({
    sx,
    className,
    isLoading,
    title,
    subtitle,
    subheader,
    chartProps,
    periodGroupingField,
    columns = []
}: LinearDiagramProps) => (
    <LinearStatisticDiagram
        sx={{
            position: 'relative',
            overflow: 'visible',
            ...sx
        }}
        className={className}
        isLoading={isLoading}
        isDataAvailable={Boolean(chartProps!.data.length)}
        title={title}
        subtitle={subtitle}
        subheader={subheader}
        chartProps={{
            xScale: {
                type: 'point'
            },
            yScale: {
                type: 'linear'
            },
            enableSlices: 'x',
            curve: 'monotoneX',
            axisTop: null,
            axisRight: null,
            enablePoints: true,
            colors: { scheme: 'category10' },
            useMesh: true,
            legends: [
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)'
                }
            ],
            sliceTooltip: props => (
                <SliceTooltip
                    {...props}
                    columns={columns}
                    periodGroupingField={periodGroupingField}
                />
            ),
            ...chartProps,
            margin: {
                top: 50,
                bottom: 50,
                left: 60,
                ...chartProps?.margin
            },
            axisBottom: {
                format: formatXDateAxis(columns, periodGroupingField),
                tickSize: 5,
                tickPadding: 5,
                tickRotation: getAxisBottomTickRotation(chartProps!.data),
                legendOffset: 36,
                legendPosition: 'middle',
                renderTick: props => (
                    <AxisBottomTick
                        {...props}
                        isLoading={isLoading}
                        data={chartProps!.data}
                        formatValue={formatXDateAxis(columns, periodGroupingField)}
                    />
                ),
                ...chartProps?.axisBottom,
            },
            axisLeft: {
                format: '.2s',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendOffset: -45,
                legendPosition: 'middle',
                ...chartProps?.axisLeft
            },
            data: chartProps!.data
        }}
    />
);

export default memo(LinearDiagram);
