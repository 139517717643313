import { MerchantPayoutsTabs, merchantPayoutTabValueMapping } from "consts/merchantPayouts";
import { getSearchParameterFromUrl } from "util/support";
import type { MerchantPayoutsState } from "./types";
import { resourceSliceFactory } from "util/resource";

const merchantPayoutFilableMixin = {
    isMultiFileMergeEnabled: false,
    selectionModel: []
};

const merchantPayoutEntity = resourceSliceFactory();

const state: MerchantPayoutsState = {
    activeTab: merchantPayoutTabValueMapping.get(getSearchParameterFromUrl({
        name: 'activeTab',
        fallbackValue: '1'
    })) ?? MerchantPayoutsTabs.SummaryFiles,
    [MerchantPayoutsTabs.SummaryFiles]: {
        ...merchantPayoutEntity,
        ...merchantPayoutFilableMixin
    },
    [MerchantPayoutsTabs.Files]: {
        ...merchantPayoutEntity,
        ...merchantPayoutFilableMixin
    },
    [MerchantPayoutsTabs.Calculations]: {
        ...merchantPayoutEntity
    },
    [MerchantPayoutsTabs.FileTaxCalculations]: {
        ...merchantPayoutEntity
    },
    [MerchantPayoutsTabs.IncrementsPivot]: {
        ...merchantPayoutEntity
    },
    [MerchantPayoutsTabs.IncrementsTaxCalculations]: {
        ...merchantPayoutEntity
    },
    [MerchantPayoutsTabs.Increments]: {
        ...merchantPayoutEntity
    },
    [MerchantPayoutsTabs.IncrementExchangeRates]: {
        ...merchantPayoutEntity
    }
};

export const sliceToken = 'merchantPayouts';

export default state;
