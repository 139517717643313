import { memo } from "react";
import Box from "@mui/material/Box";
import TransactionStatusCassette from "./TransactionStatusCassette";
import AvgTransactionSize from "./AvgTransactionSize";
// import ActiveEmails from "./ActiveEmails";

const StatisticDashboardCassettes = () => (
    <Box
        sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
            gap: 2,
            mt: 5.5
        }}
    >
        <TransactionStatusCassette
            statusLabel='Succeeded trx.'
            status='SUCCEEDED'
        />
        <TransactionStatusCassette
            statusLabel='Failed trx.'
            status='FAILED'
        />
        <TransactionStatusCassette
            statusLabel='Rejected trx.'
            status='REJECT'
        />
        <AvgTransactionSize />
        {/* <ActiveEmails /> */}
    </Box>
);

export default memo(StatisticDashboardCassettes);
