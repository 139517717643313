import type { Status } from "types";
import type { UseQueryArg } from "hooks/useQuery";
import { Paginate } from "consts/table";
import { Filters } from "consts/transactions";
import { Aggregates } from "consts/request-query";
import { PeriodAdapter, backendRequestSearchQueryParamsAdapterFactory } from "util/period-adapter";

export const totalCountRequestQueryParamsFactory: (
    perPage?: number
) => UseQueryArg['getRequestQueryParams'] = (perPage = 1) => requestQueryMapper => (
    requestQueryMapper
        .contains(Paginate.perPage, String(perPage))
);

export const transactionsRequestQueryParamsFactory: (
    groupBy:
        | typeof Filters.currency
        | typeof Filters.status
        | typeof Filters.methodTag

) => UseQueryArg['getRequestQueryParams'] = groupBy => requestQueryMapper => (
    requestQueryMapper
        .aggregates(
            Aggregates.GroupBys,
            PeriodAdapter.make(backendRequestSearchQueryParamsAdapterFactory())
                .get(requestQueryMapper.searchQueryParams)
        )
        .aggregates(Aggregates.GroupBys, groupBy)
);

export const distinctEmailTransactionsRequestQueryParamsFactory: (
    transactionStatus?: Status
) => UseQueryArg['getRequestQueryParams'] = transactionStatus => requestQueryMapper => {
    if (transactionStatus) {
        requestQueryMapper
            .containsIn(Filters.status, transactionStatus);
    }

    return totalCountRequestQueryParamsFactory()(
        requestQueryMapper
            .distincts(Filters.consumerId)
    );
};
