import dashboard from 'config/filters/dashboard.json';
import { selectLedgerCurrencies } from 'features/general/selectors';
import { useTypedSelector } from 'hooks';
import { UseFiltersProps, useFilters, useFiltersModel } from "ui/widgets/Filters";
import { typed } from 'ui/widgets/Filters/hooks/useFiltersConfigParser';

export default function useStatisticDashboardFilters({ fetchData, filterTypes }: Pick<
    UseFiltersProps<string>,
    | 'fetchData'
    | 'filterTypes'
>) {
    const { getFilterOptions } = useFiltersModel({
        Currencies: useTypedSelector(selectLedgerCurrencies)
    });

    return {
        ...useFilters({
            filtersConfig: typed(dashboard),
            filterTypes,
            fetchData,
        }),
        getFilterOptions
    };
};
