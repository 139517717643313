import { useTypedSelector } from "hooks";
import { selectCountries, selectIsCountriesLoading } from "./selectors";
import useFetchFeatureResource from "hooks/useFetchFeatureResource";
import { getCountries as getCountriesRequest } from "./api";
import { changeCountriesLoadingState, changeCountriesSlice } from "./slice";
import useSessionEffect from "hooks/useSessionEffect";
import { PER_PAGE_SIZE } from "consts/transactions";
import { FetchResourceSettings } from "hooks/useFetchResource";

const countriesSessionEffectSymbol = Symbol('countriesSessionEffect');

export default function useCountries({
    shouldFetchAll = true,
    perPage = PER_PAGE_SIZE
}: FetchResourceSettings = {}) {
    const countries = useTypedSelector(selectCountries);
    const isCountriesLoading = useTypedSelector(selectIsCountriesLoading);

    const getCountries = useFetchFeatureResource({
        shouldFetchAll,
        perPage,
        resourceRequest: getCountriesRequest,
        changeLoadingState: changeCountriesLoadingState,
        changeSlice: changeCountriesSlice
    });

    useSessionEffect(() => {
        getCountries();
    }, countriesSessionEffectSymbol);

    return {
        countries,
        isCountriesLoading
    };
};
