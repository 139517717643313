import type { FormikValues } from "formik";
import type { Option } from "types";
import type { User } from "features/users/types";
import { isEmployee, isMerchant } from "features/auth/helpers";
import { getOptionById, getOptionName } from "util/option";

export const isRoleEnabled = (userRoleName?: User['roleName']) =>
    !isEmployee(`${userRoleName}`);

export const isMerchantRequired = (roles: Array<Option>) => (roleId: Option['id']) => {
    const roleOption = getOptionById(roles, roleId);

    return isMerchant(
        getOptionName(roleOption)
    );
};

export const isMerchantEnabled = (roles: Array<Option>, userRoleName?: User['roleName']) =>
    ({ roleId }: FormikValues) => Boolean(
        roleId &&
        isRoleEnabled(userRoleName) &&
        isMerchantRequired(roles)(roleId)
    );
