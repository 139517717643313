import { useAppDispatch } from "hooks";
import { useCallback } from "react";
import { addNotification, removeNotification, toggleSideMenu } from "./slice";
import type { Notification } from "./types";

/**
 * TODO:
 * Renalda please refactor ASAP,
 * move to util, this is not a redux related function
 */
const getEnvironment = () => {
  const host = window.location.hostname.includes("localhost") ? new URL(`${process.env.REACT_APP_BACKEND_ORIGIN_URL}`)?.hostname : window.location.hostname;
  let environment = "";
  if (host.includes("sandbox")) {
    environment = "Sandbox";
  } else if (host.includes("stg") || host.includes("stage")) {
    environment = "Stage";
  }

  return environment;
};
const useActions = () => {
  const dispatch = useAppDispatch();
  return {
    toggleSideMenu: useCallback(() => {
      dispatch(toggleSideMenu());
    }, [dispatch]),
    showNotication: useCallback(
      (notification: Notification) => {
        dispatch(addNotification(notification));
      },
      [dispatch]
    ),
    hideNotication: useCallback(() => {
      dispatch(removeNotification());
    }, [dispatch]),
    getEnvironmentVariable: getEnvironment(),
  };
};

export default useActions;
