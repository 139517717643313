import { memo, Fragment } from "react";
import Box from "@mui/material/Box";
import type { SliceTooltipProps } from "@nivo/line";
import type { Theme } from "@mui/material";
import type { TooltipProps } from "../types";
import { formatXDateAxis } from "../support/helpers";

type Props =
    & SliceTooltipProps
    & TooltipProps
    & {
        readonly periodGroupingField: string;
    };

const SliceTooltip = ({ slice, postfix, columns, periodGroupingField }: Props) => (
    <Box
        sx={({ palette }: Theme) => ({
            fontFamily: 'var(--manrope-font)',
            bgcolor: palette.background.paper,
            color: palette.text.primary,
            borderRadius: 1,
            boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px',
            p: '5px 9px',
            display: 'grid',
            gridTemplateColumns: 'repeat(3, auto)',
            alignItems: 'center',
            gap: 1,
        })}
    >
        <Box
            sx={{
                gridColumn: '1 / -1',
                fontWeight: 'bold'
            }}
        >
            {formatXDateAxis(columns, periodGroupingField)(slice.points[0].data.xFormatted)}
        </Box>
        {slice.points.map(({ id, serieColor, serieId, data }) => (
            <Fragment
                key={id}
            >
                <Box
                    sx={{
                        width: 12,
                        height: 12,
                        bgcolor: serieColor
                    }}
                />
                <Box>{serieId}</Box>
                <Box
                    sx={{
                        fontWeight: 'bold'
                    }}
                >
                    {data.yFormatted} {postfix}
                </Box>
            </Fragment>
        ))}
    </Box>
);

export default memo(SliceTooltip);
