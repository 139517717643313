import { memo } from "react";
import Box from '@mui/material/Box';
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from '@mui/icons-material/Info';
import Card from "ui/atoms/Card";
import type { FeeCardProps } from "./types";
import useFeeCard from "./useFeeCard";

const FeeCard = ({
    taxName,
    legend,
    ...props
}: FeeCardProps) => {
    const {
        getNormalizedIncrementTaxes,
        renderInterval
    } = useFeeCard(props);

    if (!props.incrementTaxes.length) {
        return null;
    }

    return (
        <Card
            sx={{
                px: 3,
                pb: 3,
                pt: 1
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    minHeight: 50
                }}
            >
                <Typography
                    variant='subtitle2'
                >
                    {taxName}
                </Typography>
                {Boolean(legend) && (
                    <Tooltip
                        title={legend}
                    >
                        <InfoIcon color='disabled' />
                    </Tooltip>
                )}
            </Box>
            <Divider />
            <Box>
                {getNormalizedIncrementTaxes()
                    .map(({
                        refColFromMinSum,
                        currentIncMultiplierForEachRefColumn
                    }, index, incrementTaxes) => (
                        <Box
                            key={String(index)}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: 2,
                                mt: 1
                            }}
                        >
                            <Box>
                                {renderInterval(refColFromMinSum, index, incrementTaxes)}
                            </Box>
                            <Box
                                sx={{
                                    textAlign: 'right',
                                    fontWeight: 600
                                }}
                            >
                                {currentIncMultiplierForEachRefColumn}
                            </Box>
                        </Box>
                    ))}
            </Box>
        </Card>
    );
};

export default memo(FeeCard);
