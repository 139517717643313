import IconButton from "@mui/material/IconButton";
import ProgressLoader from "ui/atoms/ProgressLoader";

const AccountSelectorProgress = () => (
    <ProgressLoader
        isLoading
        size={30}
        WrapperComponent={IconButton}
        sx={{
            '&.MuiCircularProgress-root': {
                '.MuiCircularProgress-circle': {
                    stroke: theme => theme.palette.primary.main
                }
            }
        }}
    />
);

export default AccountSelectorProgress;
