import Screen from 'ui/layouts/Screen';
import FinanceComponent, { FinanceProvider } from 'ui/organizms/Finance';

const Finance = () => (
    <Screen
        title='Finance'
    >
        <FinanceProvider>
            <FinanceComponent />
        </FinanceProvider>
    </Screen>
);

export default Finance;
