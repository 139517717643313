import { useMemo } from 'react';
import { capitalizeFirstLetterFormatter } from 'util/formaters';
import { object, string } from 'yup';

export const enum FormFields {
    Subject = 'subject',
    Department = 'department',
    Description = 'description'
};

export const getInitialValues = () => ({
    [FormFields.Subject]: '',
    [FormFields.Department]: '',
    [FormFields.Description]: ''
});

export const useValidationSchema = () =>
    useMemo(() =>
        object().shape({
            [FormFields.Subject]: string()
                .trim()
                .min(10)
                .required()
                .label(capitalizeFirstLetterFormatter(FormFields.Subject)),
            [FormFields.Department]: string()
                .required()
                .label(capitalizeFirstLetterFormatter(FormFields.Department)),
            [FormFields.Description]: string()
                .trim()
                .min(10)
                .max(2500)
                .required()
                .label(capitalizeFirstLetterFormatter(FormFields.Description))
        })
        , []);
