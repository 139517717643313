import { createSlice } from "@reduxjs/toolkit";
import {
    payoutAccountsRequestLoading,
    payoutAccountsRequestFulfilled,
    payoutAccountsRequestRejected,
    payoutCryptoAccountsRequestFulfilled,
    payoutCryptoAccountsRequestRejected,
    payoutCryptoAccountsRequestLoading,
    selectAccount as selectAccountReducer
} from "./reducers";
import initialState, { sliceToken } from "./state";
import { getPayoutAccountsThunk, getPayoutCryptoAccountsThunk } from "./thunks";

const slice = createSlice({
    name: sliceToken,
    initialState,
    reducers: {
        selectAccount: selectAccountReducer
    },
    extraReducers: builder =>
        builder
            //PayoutAccounts
            .addCase(getPayoutAccountsThunk.pending, payoutAccountsRequestLoading)
            .addCase(getPayoutAccountsThunk.fulfilled, payoutAccountsRequestFulfilled)
            .addCase(getPayoutAccountsThunk.rejected, payoutAccountsRequestRejected)
            //PayoutCryptoAccounts
            .addCase(getPayoutCryptoAccountsThunk.pending, payoutCryptoAccountsRequestLoading)
            .addCase(getPayoutCryptoAccountsThunk.fulfilled, payoutCryptoAccountsRequestFulfilled)
            .addCase(getPayoutCryptoAccountsThunk.rejected, payoutCryptoAccountsRequestRejected)
});

export const {
    name,
    actions: {
        selectAccount
    }
} = slice;

export default slice.reducer;
