import { memo, PropsWithChildren } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

type Props = {
    readonly isLoading: boolean;
};

const DetailsLoader = ({ isLoading, children }: PropsWithChildren<Props>) => (
    <>
        {isLoading ?
            (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : children}
    </>
);

export default memo(DetailsLoader);
