import { useRoutes } from "react-router-dom";
import { WebRouteTypes, WebRoutes } from "consts/enpoints/web";
import { RouteObject } from "react-router-dom";
import Protected from "ui/layouts/Protected";
import RedirectIfAuthenticated from "ui/layouts/RedirectIfAuthenticated";
import Dashboard from "ui/layouts/Dashboard";
import Login from "ui/screens/Login";
import NotAuthorized from "ui/screens/NotAuthorized";
import Transactions from "ui/screens/Transactions";
import UserManagement from "ui/screens/UserManagement";
import useAuth from "features/auth/useAuth";
import StatisticDashboard from "ui/screens/StatisticDashboard";
import Home from "ui/screens/Home";
import Finance from "ui/screens/Finance";
import Profile from "ui/screens/Profile";
import Support from "ui/screens/Support";
import { UserRolesNames } from "consts/auth";

const useRouter = () => {
    const { user } = useAuth();

    const getAppRoutes = () => {
        const baseRoutes: RouteObject[] = [
            {
                path: "*",
                element: (
                    <NotAuthorized />
                )
            }
        ];

        if (!user) {
            return baseRoutes;
        }

        const userRoleName = user.roleName as UserRolesNames;

        const route = new Map<WebRouteTypes, RouteObject>()
            .set(WebRouteTypes.Root, {
                path: WebRoutes.Root,
                element: <Home />
            })
            .set(WebRouteTypes.Dashboard, {
                path: WebRoutes.Dashboard,
                element: <StatisticDashboard />
            })
            .set(WebRouteTypes.Transactions, {
                path: WebRoutes.Transactions,
                element: (
                    <Transactions />
                )
            })
            .set(WebRouteTypes.Finance, {
                path: WebRoutes.Finance,
                element: (
                    <Finance />
                )
            })
            .set(WebRouteTypes.Users, {
                path: WebRoutes.Users,
                element: (
                    <UserManagement />
                )
            })
            .set(WebRouteTypes.Profile, {
                path: WebRoutes.Profile,
                element: (
                    <Profile />
                )
            })
            .set(WebRouteTypes.Support, {
                path: WebRoutes.Support,
                element: (
                    <Support />
                )
            });

        const roleBasedRoutes = new Map<UserRolesNames, RouteObject[]>([
            [UserRolesNames.MerchantAdmin, [
                route.get(WebRouteTypes.Root)!,
                route.get(WebRouteTypes.Dashboard)!,
                route.get(WebRouteTypes.Transactions)!,
                route.get(WebRouteTypes.Finance)!,
                route.get(WebRouteTypes.Users)!,
                route.get(WebRouteTypes.Profile)!,
                route.get(WebRouteTypes.Support)!,
            ]],
            [UserRolesNames.MerchantEmployee, [
                route.get(WebRouteTypes.Root)!,
                route.get(WebRouteTypes.Dashboard)!,
                route.get(WebRouteTypes.Transactions)!,
                route.get(WebRouteTypes.Support)!,
            ]],
            [UserRolesNames.MerchantFinance, [
                route.get(WebRouteTypes.Root)!,
                route.get(WebRouteTypes.Dashboard)!,
                route.get(WebRouteTypes.Transactions)!,
                route.get(WebRouteTypes.Finance)!,
                route.get(WebRouteTypes.Profile)!,
                route.get(WebRouteTypes.Support)!,
            ]],
            [UserRolesNames.MerchantTech, [
                route.get(WebRouteTypes.Root)!,
                route.get(WebRouteTypes.Transactions)!,
                route.get(WebRouteTypes.Profile)!,
                route.get(WebRouteTypes.Support)!
            ]]
        ]);

        if (roleBasedRoutes.has(userRoleName)) {
            return [
                ...roleBasedRoutes.get(userRoleName)!,
                ...baseRoutes
            ];
        }

        return baseRoutes;
    };

    const routes: RouteObject[] = [
        {
            path: WebRoutes.Root,
            element: (
                <Protected>
                    <Dashboard />
                </Protected>
            ),
            children: getAppRoutes()
        },
        {
            path: WebRoutes.Login,
            element: (
                <RedirectIfAuthenticated>
                    <Login />
                </RedirectIfAuthenticated>
            ),
        },
    ];

    return useRoutes(routes);
};

export default useRouter;
