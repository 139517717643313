import { memo } from "react";
import Box from "@mui/material/Box";
import { CurrencySymbol } from "consts/general";
import { fNumber } from "util/formaters";
import SummaryCard, { type SummaryCardProps } from "ui/molecules/SummaryCard";
import { formatDate } from "util/formaters";

type Props = Pick<
    SummaryCardProps,
    | 'isLoading'
    | 'labelSlot'
    | 'amountSlot'
    | 'currencySlot'
> & {
    readonly isPaid?: boolean;
    readonly paidDate?: string;
    readonly format?: string;
};

const Cassette = ({
    isLoading,
    labelSlot,
    amountSlot,
    isPaid,
    paidDate,
    format = '0,0.00',
    currencySlot = CurrencySymbol.EUR
}: Props) => (
    <SummaryCard
        sx={{
            width: 'auto',
            minWidth: 250,
            '& > div:nth-of-type(2)': {
                display: 'grid',
                gridTemplateColumns: '1fr auto',
                alignItems: 'baseline',
                justifyContent: 'space-between'
            }
        }}
        isLoading={isLoading}
        labelSlot={labelSlot}
        amountSlot={(
            <Box
                sx={{
                    fontSize: 27
                }}
            >
                {currencySlot}
                &nbsp;
                {fNumber(String(amountSlot), format)}
            </Box>
        )}
        currencySlot={Boolean(paidDate) && (
            <Box
                sx={{
                    fontSize: 14,
                    fontWeight: 400
                }}
            >
                {formatDate(paidDate, {
                    pattern: 'DD/MM/YYYY'
                })}
            </Box>
        )}
        chipProps={{
            size: 'small',
            variant: 'soft',
            color: 'success',
            label: isPaid && 'Paid'
        }}
    />
);

export default memo(Cassette);
