import Box from "@mui/material/Box";
import { memo } from "react";
import jsonFormatHighlight from 'json-format-highlight';
import { TabProps } from "./Props";
import DetailsLoader from "./DetailsLoader";

const CodeSnippet = ({ data }: TabProps) => (
    <DetailsLoader isLoading={!data}>
        <Box
            sx={{
                backgroundColor: '#f5f5f5',
                borderRadius: 2,
                border: '1px solid #ccc',
                overflow: 'auto',
                '& pre span': {
                    whiteSpace: 'nowrap !important'
                }
            }}
            p={1}
        >
            <pre
                dangerouslySetInnerHTML={{
                    __html: jsonFormatHighlight(
                        typeof data === 'string'
                            ? JSON.parse(data)
                            : data
                    )
                }}
            />
        </Box>
    </DetailsLoader>
);

export default memo(CodeSnippet);
