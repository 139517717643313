// @mui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import useAuth from 'features/auth/useAuth';
// locales
import { useLocales } from 'locales';
import Documentation from 'ui/widgets/Documentation';

// ----------------------------------------------------------------------

export default function NavDocs() {
  const { getUserName } = useAuth();

  const { translate } = useLocales();

  return (
    <Documentation>
      <Box>
        <Typography gutterBottom variant="subtitle1">
          {translate('docs.hi')}, {getUserName()}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}>
          <>{translate('docs.description')}</>
        </Typography>
      </Box>
    </Documentation>
  );
}
