import { ApiState } from "infrastructure/api";
import { MerchantsState } from "./types";

const state: MerchantsState = {
    merchantsLoadingState: ApiState.Idle,
    exportLoadingState: ApiState.Idle,
    merchantsSlice: null,
    merchantProfileLoadingState: ApiState.Idle
};

export const sliceToken = 'merchants';

export default state;
