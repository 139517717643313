import type { FilterVariant } from "consts/filters";
import { memo } from "react";
import Table from "ui/widgets/Table";
import ResourceProvider from "./ResourceProvider";
import { ResourceProps } from "./types";

const Resource = ({
    useResource,
    getRowId,
    mode,
    selectIsTableUninitialized,
    selectIsTableLoading,
    selectTableDataSlice,
    Toolbar,
    Modal,
    filterTypes = new Map<FilterVariant, string[]>(),
}: ResourceProps) => {
    const {
        getResource,
        downloadCsv,
        onRowClick
    } = useResource();

    return (
        <>
            <Table
                mode={mode}
                selectIsTableUninitialized={selectIsTableUninitialized}
                selectIsTableLoading={selectIsTableLoading}
                selectTableDataSlice={selectTableDataSlice}
                fetchTableData={getResource}
                downloadCsv={downloadCsv}
                filterTypes={filterTypes}
                Toolbar={Toolbar}
                getRowId={getRowId}
                CardProps={{
                    sx: {
                        height: 'calc(100vh - 100px)'
                    }
                }}
                onRowClick={onRowClick}
            />
            <Modal />
        </>
    );
};

export default memo((props: ResourceProps) => (
    <ResourceProvider>
        <Resource
            {...props}
        />
    </ResourceProvider>
));
