import clsx from "clsx";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import type { Option } from "types";
import { useFormik } from "formik";
import { memo, SyntheticEvent } from "react";
import { getFormInputProps } from "util/forms";
import useValidationSchema, { formFields } from "./useValidationSchema";
import {
    isRoleEnabled,
    isMerchantRequired
} from './helpers';
import type { UserFormProps } from "./types";
import { getOptionById, getOptionName } from "util/option";

const User = ({
    onSaveOrCreate,
    initialValues,
    isLoading,
    actionLabel,
    bootstrapData,
    isBootstrapDataLoading,
    user,
    settings
}: UserFormProps) => {
    const { coreId } = initialValues;
    const isNew = typeof coreId === 'undefined';

    const formik = useFormik({
        initialValues: {
            [formFields.firstName]: '',
            [formFields.lastName]: '',
            [formFields.email]: '',
            [formFields.passwd]: '',
            [formFields.passwordConfirmation]: '',
            ...initialValues,
            [formFields.merchants]: [{
                id: settings.pki,
                name: settings.fullName
            }],
        },
        validateOnMount: true,
        validationSchema: useValidationSchema({
            isNew,
            bootstrapData
        }),
        onSubmit: async (values) => {
            await onSaveOrCreate(values);
        },
    });

    const getValue = (formikValue?: Option[]) =>
        [formikValue]
            .flat()
            .filter(Boolean) as Option[];

    const onOptionChange = (
        fieldName: string,
        valueGetter = (newValue: any) => newValue
    ) => (_: SyntheticEvent | undefined, newValue: (Option | undefined)[] | Option | null) => {
        formik.setFieldValue(
            fieldName,
            Array.isArray(newValue)
                ? newValue
                : valueGetter(newValue),
            true
        );
    };

    const getRoleChangeValue = (roleOption: Option) => roleOption?.id;

    const sx = {
        mt: 2
    };

    return (
        <Box
            component="form"
            autoComplete="off"
            id={String(coreId)}
            onSubmit={formik.handleSubmit}
            noValidate
            sx={{
                display: 'grid'
            }}
        >
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: '20px'
                }}
            >
                <Box>
                    <TextField
                        {...getFormInputProps(formFields.firstName, formik)}
                        fullWidth
                        variant='outlined'
                        name={formFields.firstName}
                        id={formFields.firstName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstName}
                        sx={sx}
                        label='First Name*'
                    />
                    <TextField
                        {...getFormInputProps(formFields.lastName, formik)}
                        fullWidth
                        variant='outlined'
                        name={formFields.lastName}
                        id={formFields.lastName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.lastName}
                        sx={sx}
                        label='Last Name*'
                    />
                    <TextField
                        {...getFormInputProps(formFields.email, formik)}
                        fullWidth
                        variant='outlined'
                        name={formFields.email}
                        id={formFields.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        sx={sx}
                        label='Email*'
                        type='email'
                        autoComplete="false"
                    />
                    <TextField
                        {...getFormInputProps(formFields.passwd, formik)}
                        fullWidth
                        variant='outlined'
                        name={formFields.passwd}
                        id={formFields.passwd}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.passwd}
                        sx={sx}
                        label={`Password${clsx(isNew && '*')}`}
                        type='password'
                        autoComplete="false"
                    />
                    <TextField
                        {...getFormInputProps(formFields.passwordConfirmation, formik)}
                        fullWidth
                        variant='outlined'
                        name={formFields.passwordConfirmation}
                        id={formFields.passwordConfirmation}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.passwordConfirmation}
                        sx={sx}
                        label={`Confirm Password${clsx(isNew && '*')}`}
                        type='password'
                        autoComplete="false"
                    />
                </Box>
                <Box>
                    <Autocomplete
                        fullWidth
                        disablePortal
                        disabled={!isRoleEnabled(user.roleName)}
                        options={bootstrapData.roles}
                        getOptionLabel={getOptionName}
                        value={getOptionById(bootstrapData.roles, formik.values.roleId) || null}
                        onChange={onOptionChange(formFields.roleId, getRoleChangeValue)}
                        loading={isBootstrapDataLoading}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label='Role*'
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {
                                                isBootstrapDataLoading
                                                    ? (
                                                        <CircularProgress
                                                            color='primary'
                                                            size={20}
                                                        />
                                                    )
                                                    : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                        sx={sx}
                    />
                    <Autocomplete
                        fullWidth
                        multiple
                        disablePortal
                        disabled
                        options={bootstrapData.merchants}
                        getOptionLabel={getOptionName}
                        value={getValue(formik.values.merchants)}
                        onChange={onOptionChange(formFields.merchants)}
                        loading={isBootstrapDataLoading}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={clsx(
                                    'Merchants',
                                    isMerchantRequired(bootstrapData.roles)(`${formik.values.roleId}`) && '*'
                                )}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {
                                                isBootstrapDataLoading
                                                    ? (
                                                        <CircularProgress
                                                            color='primary'
                                                            size={20}
                                                        />
                                                    )
                                                    : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                        sx={sx}
                    />
                </Box>
            </Box>
            <LoadingButton
                type="submit"
                variant="contained"
                loading={formik.isSubmitting || isLoading}
                disabled={!formik.isValid}
                sx={{
                    mt: 3,
                    mb: 2,
                    justifySelf: 'flex-end'
                }}
            >
                {actionLabel}
            </LoadingButton>
        </Box>
    );
};

export default memo(User);
