import { postSupport } from "features/general/api";
import { NotificationSeverity, SupportFormData } from "features/general/types";
import useActions from "features/general/useActions";
import { useSimpleRequest } from "hooks/useRequest";
// import { ApiState } from "infrastructure/api";
// import { useState } from "react";
import type { SupportFormProps } from "ui/forms/Support";

export default function useSupport() {
    // const [status, setStatus] = useState<ApiState | null>(null);

    const {
        showNotication
    } = useActions();
    const request = useSimpleRequest();

    const onSubmit: SupportFormProps['onSubmit'] = async (
        supportFormData: SupportFormData,
        { resetForm }
    ) => request(() => postSupport(supportFormData), {
        onSuccess: () => {
            resetForm();
            showNotication({
                message: `
                    Ticket sent.
                    We will reply to you as soon as possible.
                `,
                severity: NotificationSeverity.Success
            });
        }
    });

    return {
        onSubmit
    };
};
