import BusinessLogicException from "exceptions/BusinessLogicException";
import type { AbstractFileEntity } from "features/merchantPayouts/types";
import type { ActionHookArg } from "../Props";
import useActionHandler from "./useActionHandler";

const defaultRequestHandler = <T extends AbstractFileEntity>(row: T) => new Response();

export default function useSettlementXLS<T extends AbstractFileEntity>({
    row,
    handler = defaultRequestHandler
}: ActionHookArg<
    T,
    typeof defaultRequestHandler
>) {
    const actionHandler = useActionHandler(row);

    return actionHandler(async () => {
        if (!row.fileKey) {
            throw new BusinessLogicException(`Invalid fileKey of type ${row.fileKey}!`, {});
        }

        const response = await handler(row);

        if (!response.ok) {
            throw new BusinessLogicException("Something went wrong while generating .XLS file", { response });
        }
    });
};
