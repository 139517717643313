import {
    topRecentTransactionsQueryToken,
    useStatisticDashboardContext,
} from "ui/organizms/StatisticDashboard/StatisticDashboardProvider";

export default function useRecentTransactions() {
    const statisticDashboard = useStatisticDashboardContext();

    const {
        payload: topRecentTransactionsQuery,
        isLoading
    } = statisticDashboard[topRecentTransactionsQueryToken];

    const getTopRecentTransactions = () => topRecentTransactionsQuery?.data ?? [];

    return {
        isLoading,
        getTopRecentTransactions
    };
};
