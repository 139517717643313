import MuiCard, { type CardProps } from "@mui/material/Card";
import Tooltip, { type TooltipProps } from "@mui/material/Tooltip";

export type Props = CardProps & {
    readonly tooltipProps?: Omit<TooltipProps, 'children'>;
};

const Card = ({ tooltipProps, ...props }: Props) => {

    return (
    <Tooltip
        arrow
        placement='top'
        title={null}
        {...tooltipProps}
    >
        <MuiCard
            {...props}
            sx={{
                ...props.sx
            }}
        />
    </Tooltip>

)};

export default Card;
