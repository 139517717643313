import { ReactElement, ComponentType, isValidElement } from "react";
import type { ChartProps } from "./types";
import EmptyData from "./EmptyData";

const withData = <TChartProps extends ChartProps<unknown>>(ChartComponent: ComponentType<TChartProps> | ReactElement<TChartProps>) =>
    (props: TChartProps) => {
        if (props.data.length) {
            return isValidElement(ChartComponent)
                ? ChartComponent
                : (
                    <ChartComponent
                        {...props}
                    />
                );
        }

        return !props.isLoading && (
            <EmptyData />
        );
    };

export default withData;