export const Filters = {
    coreId: 'coreId',
    consumerId: 'consumerId',
    active: 'active',
    kycStatus: 'kycStatus',
    email: 'email',
    phone: 'phone',
    kycStatusFace: 'kycStatusFace',
    kycStatusId: 'kycStatusId',
    kycStatusAddress: 'kycStatusAddress',
    kycStatusAml: 'kycStatusAml',
    kycStatusPhone: 'kycStatusPhone',
    kycStatusEidv: 'kycStatusEidv',
    status: 'status',
    isOnConsumer: 'is_on_consumer',
    provider: 'provider',
    pin: 'pin',
    hasImmunityForForbiddenWords: 'hasImmunityForForbiddenWords',

    lastKyc: 'lastKyc',
    lastKyc0: 'lastKyc0',
    lastKyc1: 'lastKyc1',
    lastKyc2: 'lastKyc2',
    lastKyc3: 'lastKyc3',
    lastKyc4: 'lastKyc4',

    lastKyc11: 'lastKyc11',
    lastKyc22: 'lastKyc22',

    lastKyc0Status: 'lastKyc0Status',
    lastKyc1Status: 'lastKyc1Status',
    lastKyc2Status: 'lastKyc2Status',
    lastKyc3Status: 'lastKyc3Status',
    lastKyc4Status: 'lastKyc4Status',

    lastKyc0TypeId: 'lastKyc0TypeId',
    lastKyc1TypeId: 'lastKyc1TypeId',
    lastKyc2TypeId: 'lastKyc2TypeId',
    lastKyc3TypeId: 'lastKyc3TypeId',
    lastKyc4TypeId: 'lastKyc4TypeId',

    samePersonIdentifier: 'samePersonIdentifier',
    samePersonIdentifierSource: 'samePersonIdentifierSource',

    kycStatusFaceReason: 'kycStatusFaceReason',
    kycStatusIdReason: 'kycStatusIdReason',
    kycStatusAmlReason: 'kycStatusAmlReason',
    kycStatusAddressReason: 'kycStatusAddressReason',
    kycStatusPhoneReason: 'kycStatusPhoneReason',
    kycStatusEidvReason: 'kycStatusEidvReason',

    kycStatusFaceFileKey: 'kycStatusFaceFileKey',
    kycStatusIdFileKey: 'kycStatusIdFileKey',
    kycStatusAmlFileKey: 'kycStatusAmlFileKey',
    kycStatusAddressFileKey: 'kycStatusAddressFileKey',
    kycStatusPhoneFileKey: 'kycStatusPhoneFileKey',
    kycStatusEidvFileKey: 'kycStatusEidvFileKey',

    kycTransactionId: 'kycTransactionId',
    lastSucceededFaceKycReference: 'lastSucceededFaceKycReference',
    lastSucceededIdKycReference: 'lastSucceededIdKycReference',
    lastSucceededEidvKycReference: 'lastSucceededEidvKycReference',
    lastSucceededAddressKycReference: 'lastSucceededAddressKycReference',
    lastSucceededAmlKycReference: 'lastSucceededAmlKycReference',
    lastSucceededPhoneKycReference: 'lastSucceededPhoneKycReference',

    manualBulkKycImportStatus: 'manualBulkKycImportStatus',
    kycFileKey: 'kycFileKey',
    manualBulkKycImportHistoryId: 'manualBulkKycImportHistoryId',
    importStatus: 'importStatus',
    kycTypeId: 'kycTypeId',
    MOR: 'MOR',
    merchantId: 'merchantId',
    emailOrHash: 'emailOrHash',
    createdAt: 'createdAt',
    merchantName: 'merchantName',
    kycTypeName: 'kycTypeName',
    pki: 'pki'
};

export const enum Relations {
    ConsumerEmail = 'consumerEmail',
    LastKycs11 = 'lastKycs11',
    LastKycs22 = 'lastKycs22',
    LastKycs0 = 'lastKycs0',
    LastKycs = 'lastKycs',
    LastKycs1 = 'lastKycs1',
    LastKycs2 = 'lastKycs2',
    ConsumerKycSettings = 'consumerKycSettings',
    Merchants = 'merchants',
    FirstTransaction = 'firstTransaction',
    SamePersonConsumerEmails = 'samePersonConsumerEmails',
    ConsumersManualBulkKycsImportHistoryPivot  = 'consumersManualBulkKycsImportHistoryPivot'
};

export const enum LastKycs {
    TransactionId = 'TransactionId',
    KycTypeId = 'TypeId',
    Provider = 'Provider',
    Status = 'Status',
    Reason = 'Reason',
    Decision = 'Decision',
    IsPerConsumer = 'IsPerConsumer',
    FileKey = 'FileKey',
    RedirectOk = 'RedirectOk',
    CreatedAt = 'CreatedAt',
    UpdatedAt = 'UpdatedAt'
};

export const single = [
    Filters.active,
    Filters.kycStatus,
    Filters.samePersonIdentifierSource,
    Filters.hasImmunityForForbiddenWords,
    Filters.manualBulkKycImportStatus
];

export const text = [
    Filters.email,
    Filters.phone,
    Filters.pin,
    Filters.kycTransactionId,
    Filters.kycFileKey,
];

export const multi = [
    Filters.kycStatusFace,
    Filters.kycStatusId,
    Filters.kycStatusAml,
    Filters.kycStatusAddress,
    Filters.kycStatusPhone,
    Filters.kycStatusEidv,
];

export const numericRange = [
    Relations.SamePersonConsumerEmails
];

export const BLACKLIST_CONSUMER_REASON = 'Blacklisted from whitelist';

export const enum SamePersonIdentifierSource {
    Original = 'Original',
    Iban = 'IBAN',
    Manual = 'MANUAL',
    KYC = 'KYC'
}
