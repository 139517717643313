import { selectIsMerchantPayoutEntityLoading, selectMerchantPayoutEntitySlice } from "features/merchantPayouts/selectors";
import useActions from "features/merchantPayouts/useActions";
import useSessionEffect from "hooks/useSessionEffect";
import { groupIncrementTaxes } from "./helpers";
import { FeesMethodsCategory } from "consts/merchantPayouts";
import { useTypedSelector } from "hooks";
import type { FeeProps } from "./GeneralFees";
import { selectSettings } from "features/general/selectors";

const feesMethodsSessionEffectSymbol = Symbol('feesMethodsSessionEffect');

export default function useFeesMethods() {
    const { data = [] } = useTypedSelector(selectMerchantPayoutEntitySlice) ?? {};
    const isLoading = useTypedSelector(selectIsMerchantPayoutEntityLoading);
    const settings = useTypedSelector(selectSettings);
    const { getMerchantPayoutIncrementTaxes } = useActions();

    useSessionEffect(() => {
        getMerchantPayoutIncrementTaxes('')
    }, feesMethodsSessionEffectSymbol);

    const getFeesMethodsEntries = () =>
        Array.from(groupIncrementTaxes(data).entries());

    const getGeneralFeesMethods = () =>
        getFeesMethodsEntries()
            .filter(([tag]) => Object.is(tag, FeesMethodsCategory.General));

    const getFeesMethods = () =>
        getFeesMethodsEntries()
            .filter(([tag]) => !Object.is(tag, FeesMethodsCategory.General));

    const getGeneralFees = () =>
        Array.from(
            new Map<string, FeeProps>([
                ['Chargeback Fee', {
                    label: 'Chargeback Fee',
                    fee: Number(settings?.chargebackUnitFee),
                    legend: 'Fee taken from the number of chargebacks if the specific threshold, stipulated in the contract, is exceeded',
                }],
                ['Chargeback No. %', {
                    label: 'Chargeback No. %',
                    fee: Number(settings?.chargebacksNoThresholdPercent),
                    legend: 'The percentage (threshold) of chargebacks, stipulated in the contract, from which the chargeback fee will be applied',
                    currency: '%'
                }],
                ['Transfer fee', {
                    label: 'Transfer fee',
                    fee: Number(settings?.payoutTransFee),
                    legend: 'Fixed amount per payout for initializing the transfer'
                }],
                ['Min. Settlement Threshold', {
                    label: 'Min. Settlement Threshold',
                    fee: Number(settings?.payoutTransAmountThreshold),
                    legend: 'Min. amount needed to be processed in order for settlements to be issues'
                }]
            ]).values()
        );

    return {
        isLoading,
        getGeneralFeesMethods,
        getFeesMethods,
        getGeneralFees
    };
};