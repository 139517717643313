import { memo, type ReactNode } from "react";
import type { IncrementTaxes } from "./types";
import FeeCard from "./FeeCard";
import { MethodFees } from "consts/merchantPayouts";
import { CurrencySymbol } from "consts/general";
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Card, { type CardProps } from "ui/atoms/Card";

export type FeesProps = Pick<CardProps, 'sx'> & {
    readonly tag: IncrementTaxes['tag'];
    readonly title: ReactNode;
    readonly incrementTaxes: Map<string, IncrementTaxes[]>;
    readonly defaultExpanded?: boolean;
};

const Fees = ({
    tag,
    title,
    incrementTaxes,
    sx,
    defaultExpanded
}: FeesProps) => {
    const renderLegend = (taxName: string) => {
        // const isTagExist = FeesMethodsCategory.General !== tag;

        return new Map<string, () => ReactNode>()
            .set(MethodFees.MerchantDiscountRate, () => (
                `% taken from gross ${CurrencySymbol.EUR} amount processed`
            ))
            .set(MethodFees.TransactionFee, () =>  (
                'Fixed fee taken from total number of transactions processed'
            ))
            .set(MethodFees.RollingReserve, () =>  (
                `% retained from gross ${CurrencySymbol.EUR}
                amount processed for a specific period of time, stipulated in the contract`
            ))
            .set(MethodFees.RefundFee, () =>  (
                ' Fee taken from the number of refunds issued'
            ))
            .get(taxName)?.();
    };

    return (
        <Card
            sx={sx}
        >
            <Accordion
                defaultExpanded={defaultExpanded}
            >
                {Boolean(title) && (
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        {title}
                    </AccordionSummary>
                )}
                <AccordionDetails
                    sx={{
                        display: 'grid',
                        gap: '1rem',
                        gridTemplateColumns: 'repeat(3, 1fr)'
                    }}
                >
                    {Array.from(incrementTaxes.entries())
                        .map(([taxName, incrementTaxes]) => (
                            <FeeCard
                                key={taxName}
                                taxName={taxName}
                                incrementTaxes={incrementTaxes}
                                legend={renderLegend(taxName)}
                            />
                        ))}
                </AccordionDetails>
            </Accordion>
        </Card>
    );
};

export default memo(Fees);
