import { useAppDispatch, useRequest } from "hooks";
import { changeExportLoading, resetTableLoadingState, savePreviousUrl } from "./slice";
import type { MerchantPayoutFilesResponse } from "./types";
import type { ThunkReturnType } from "types";
import { getMerchantPayoutFilesThunk } from "./thunks";
import useExportTable from "hooks/useExportTable";
import { ApiRouteTypes } from "consts/enpoints/api";
import useChartQueries from "./useChartQueries";

const useActions = () => {
    const dispatch = useAppDispatch();
    const {
        payoutsChartQuery,
        chargebackRefundsChartQuery
    } = useChartQueries();

    const getMerchantPayoutFiles = useRequest<ThunkReturnType<MerchantPayoutFilesResponse>, string>({
        thunk: getMerchantPayoutFilesThunk
    });

    const downloadCsv = useExportTable({
        changeExportLoading,
        apiResourceType: ApiRouteTypes.GetMerchantPayoutFiles
    });

    const reset = () => dispatch(resetTableLoadingState());

    return {
        getMerchantPayoutFiles: (params: string) => {
            dispatch(savePreviousUrl());

            payoutsChartQuery(params);
            chargebackRefundsChartQuery(params);
            return getMerchantPayoutFiles(params);
        },
        downloadCsv,
        reset
    };
};

export default useActions;
