import { memo } from "react";
import Modal from "ui/atoms/Modal";
import { useResource } from "ui/widgets/Resource";
import Profile from "./Profile";

const UsersCreateEditModal = () => {
    const {
        open,
        isNew,
        onClose
    } = useResource();

    const renderTitle = () => [
        isNew() ? 'Add' : 'Update',
        'User'
    ].join(' ');

    return (
        <Modal
            isOpen={open}
            onClose={onClose}
            titleSlot={renderTitle()}
        >
            <Profile />
        </Modal>
    );
};

export default memo(UsersCreateEditModal);
