import { memo, useContext } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FileSummary from './FileSummary';
import type { ManageFileTaxesProps } from './Props';
import FileTaxesGrid from './FileTaxesGrid';
import Icon from 'ui/atoms/Icon';
import { DialogVisibilityDispatchContext } from 'ui/widgets/Table';

const ManageFileTaxes = ({ file }: ManageFileTaxesProps) => {
    const p = '34px';

    const dialogVisibilityDispatch = useContext(DialogVisibilityDispatchContext);

    const handleClose = () => {
        dialogVisibilityDispatch(false);
    };

    return (
        <Box
            sx={{
                fontWeight: 400,
                p
            }}
        >
            <IconButton
                sx={{
                    position: 'absolute',
                    top: p,
                    right: p
                }}
                onClick={handleClose}
            >
                <Icon name='Close' />
            </IconButton>
            <FileSummary file={file} />
            <Box
                sx={{
                    mt: '10px',
                    fontSize: '24px',
                    fontWeight: 600
                }}
            >
                Manage File Taxes
            </Box>
            <Box
                sx={{
                    mt: '50px',
                    height: 'calc(100vh - 300px)',
                    width: '100%',
                    '& .actions': {
                        color: 'text.secondary',
                    },
                    '& .textPrimary': {
                        color: 'text.primary',
                    },
                }}
            >
                <FileTaxesGrid file={file} />
            </Box>
        </Box>
    );
};

export default memo(ManageFileTaxes);
