import type { Transaction } from "features/transactions/types";
import { useState, useRef } from "react";

type UseTransactionSubject<T> = Pick<Transaction, 'transactionId'> & {
    readonly fetchData: () => Promise<T>;
};

export default function useTransactionSubject<T>({
    transactionId,
    fetchData
}: UseTransactionSubject<T>): [T | null, () => Promise<void>] {
    const transactionDetailIdRef = useRef<Transaction['transactionId']>();

    const [subjectDetail, setSubjectDetail] = useState<T | null>(null);

    const fetchSubject = async () => {
        if (transactionDetailIdRef.current !== transactionId) {
            transactionDetailIdRef.current = transactionId;
            setSubjectDetail(null);

            const result = await fetchData();

            setSubjectDetail(result);
        }
    };

    return [subjectDetail, fetchSubject];
};
