import { memo, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
    selectIsRolesLoading,
    selectRoleOptions
} from "features/users/selectors";
import { useTypedSelector } from "hooks";
import { useResource } from "ui/widgets/Resource";
import useAuth from "features/auth/useAuth";
import { selectSettings } from "features/general/selectors";
import UserForm, { type UserFormWithRelations } from "ui/forms/User";
import useActions from "features/users/useActions";

const Profile = () => {
    const { resource: row, isNew, onClose } = useResource();
    const resource = (row ?? {}) as UserFormWithRelations;

    const { user } = useAuth();
    const settings = useTypedSelector(selectSettings);

    const [isRequestProcessing, setRequestProcessing] = useState(false);

    const {
        postUser,
        getRoles,
        getUsers
    } = useActions();

    const gettersRef = useRef({
        getRoles
    });

    const isBootstrapDataLoading = useTypedSelector(selectIsRolesLoading);

    const onSaveOrCreate = async (userFormData: Partial<UserFormWithRelations>) => {
        setRequestProcessing(true);

        try {
            let [request] = postUser(userFormData);
            const response = await request;

            if (!response) {
                return;
            }

            [request] = getUsers('');
            await request;

            onClose();
        } finally {
            setRequestProcessing(false);
        }
    };

    useEffect(() => {
        const { getRoles } = gettersRef.current;

        getRoles();
    }, []);

    return (
        <Box
            sx={{
                m: 3,
                pb: 3
            }}
        >
            <Card>
                <CardContent>
                    <UserForm
                        user={user}
                        settings={settings}
                        onSaveOrCreate={onSaveOrCreate}
                        initialValues={resource}
                        isLoading={isRequestProcessing}
                        actionLabel={isNew()
                            ? 'Save and create user'
                            : 'Update user'}
                        bootstrapData={{
                            roles: useTypedSelector(selectRoleOptions),
                            merchants: []
                        }}
                        isBootstrapDataLoading={isBootstrapDataLoading}
                    />
                </CardContent>
            </Card>
        </Box>
    );
};

export default memo(Profile);
