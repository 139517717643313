import { filterByRoles } from 'features/auth/helpers';
import type { Authorizable } from 'features/auth/types';
import useAuth from 'features/auth/useAuth';
import { memo, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Tabs, { type TabProps } from 'ui/atoms/Tabs';

type Props = TabProps<Authorizable> & {
    readonly searchParamName?: string;
};

const StatefulTabs = ({
    onChange,
    value = '1',
    searchParamName = 'activeTab',
    ...restTabProps
}: Props) => {
    const { user } = useAuth();

    const [searchParams, setSearchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState(() => searchParams.get(searchParamName) ?? value);

    const initialSearchParamsRef = useRef({
        searchParamName,
        value,
        setSearchParams
    });

    useEffect(() => {
        const { searchParamName, value, setSearchParams } = initialSearchParamsRef.current;

        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set(searchParamName, searchParams.get(searchParamName) ?? value);

        setSearchParams(searchParams);
    }, []);

    const onTabChange = (value: string) => {
        searchParams.set(searchParamName, value);

        setSearchParams(searchParams);
        setActiveTab(value);

        onChange(value);
    };

    const onTabMouseDown = () => setSearchParams();

    const { model } = restTabProps;

    return (
        <Tabs
            {...restTabProps}
            model={filterByRoles(user?.roleName, model) as typeof model}
            value={activeTab}
            onChange={onTabChange}
            TabProps={{
                ...(restTabProps.TabProps ?? {}),
                onMouseDown: onTabMouseDown
            }}
        />
    );
};

export default memo(StatefulTabs);
