import { memo, useCallback } from 'react';
import { selectConstants } from 'features/general/selectors';
import {
    selectIsTransactionsLoading,
    selectIsTransactionsUninitialized,
    selectTransactionSlice
} from 'features/transactions/selectors';
import Table from "ui/widgets/Table";
import TransactionDetails from './TransactionDetails';
import TransactionsToolbar from './TransactionsToolbar';
import {  TableGridMode } from 'consts/table';
import { date, FilterVariant } from 'consts/filters';
import { multi, single, text } from 'consts/transactions';
import { useTypedSelector } from 'hooks';
import useActions from 'features/transactions/useActions';
import type { GridRowIdGetter } from '@mui/x-data-grid';
import type { Transaction } from 'features/transactions/types';
import useVisibilityRules from './useFiltersVisibilityRules';
import TransactionsSummableWidget from './TransactionsSummableWidget';
import useRequestQueryOverrideDecorator from './useRequestQueryOverrideDecorator';
import Resource from 'ui/layouts/Resource';

const Transactions = () => {
    const { TransactionCurrencies: Currencies } = useTypedSelector(selectConstants);
    const {
        getTransactions,
        downloadCsv
    } = useActions();

    const getRowId: GridRowIdGetter<Transaction> = useCallback(({ transactionId }: Transaction) =>
        transactionId, []);

    // const getSummableColumns = useCallback(() => allSummableColumnsAlias, []);

    return (
        <Resource
            title='Merchants | Transactions'
            breadcrumbsProps={{
                heading: 'Transactions',
                links: [{ name: '' }],
                sx: {
                    pl: '24px',
                    mb: 0,
                    '& + div > div': {
                        mt: 0
                    }
                }
            }}
        >
            <Table
                mode={TableGridMode.Transaction}
                selectIsTableUninitialized={selectIsTransactionsUninitialized}
                selectIsTableLoading={selectIsTransactionsLoading}
                selectTableDataSlice={selectTransactionSlice}
                fetchTableData={getTransactions}
                downloadCsv={downloadCsv}
                filterTypes={new Map<FilterVariant, string[]>([
                    [FilterVariant.Text, text],
                    [FilterVariant.DateRange, date],
                    [FilterVariant.MultiOption, multi],
                    [FilterVariant.SingleOption, single]
                ])}
                Toolbar={TransactionsToolbar}
                DetailPanelContent={TransactionDetails}
                getRowId={getRowId}
                requestQueryOverrideDecorator={useRequestQueryOverrideDecorator}
                FiltersProps={{
                    useVisibilityRules,
                    SummableWidget: TransactionsSummableWidget,
                    filtersModelOverrides: {
                        Currencies
                    }
                }}
                // getSummableColumns={getSummableColumns}
            />
        </Resource>
    );
};

export default memo(Transactions);
