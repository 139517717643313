import type { RootState } from "infrastructure/store";
import { Account, PayoutAccount, PayoutCryptoAccount } from "./types";
import { ApiState } from "infrastructure/api";

export const selectPayoutAccounts = (state: RootState) =>
    state.payoutAccounts.payoutAccounts?.data ?? [];

export const selectIsPayoutAccountsLoading = (state: RootState) =>
    state.payoutAccounts.payoutAccountsLoadingState === ApiState.Pending;

export const selectPayoutCryptoAccounts = (state: RootState) =>
    state.payoutAccounts.payoutCryptoAccounts?.data ?? [];

export const selectIsPayoutCryptoAccountsLoading = (state: RootState) =>
    state.payoutAccounts.payoutCryptoAccountsLoadingState === ApiState.Pending;

export const selectAccounts = (state: RootState) => [
    ...selectPayoutAccounts(state),
    ...selectPayoutCryptoAccounts(state)
];

export const selectIsAccountsLoading = (state: RootState) => [
    selectIsPayoutAccountsLoading(state),
    selectIsPayoutCryptoAccountsLoading(state)
].some(Boolean);

export const selectAccount = (state: RootState) => state.payoutAccounts.account;

export const getValue = (account: Account) => (
    (account as PayoutAccount).iban ?? (account as PayoutCryptoAccount).address
);
