import { memo } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select, { type SelectProps } from "@mui/material/Select";
import {
    getValue,
} from "features/payoutAccounts/selectors";
import useActions from "features/payoutAccounts/useActions";
import { Account } from "features/payoutAccounts/types";
import { compareIds } from "util/support";
import AccountSelectorProgress from "./AccountSelectorProgress";
import useFinancialDetails from "../useFinancialDetails";

const AccountSelector = () => {
    const {
        selectAccount: selectAccountAction
    } = useActions();
    const { isAccountsLoading, account, accounts } = useFinancialDetails()

    const value = getValue(account ?? {}) || '';

    const onChange: SelectProps['onChange'] = ({ target }) =>
        selectAccountAction(
            accounts.find((account: Account) =>
                compareIds(getValue(account), `${target.value}`))
        );

    return (
        <Box sx={{ minWidth: 120 }}>
            <Typography
                variant='subtitle2'
                sx={{ mb: 1 }}
            >
                Select Account
            </Typography>
            <FormControl fullWidth>
                <Select
                    size='small'
                    value={value}
                    disabled={!value}
                    onChange={onChange}
                    IconComponent={(
                        isAccountsLoading
                            ? AccountSelectorProgress
                            : undefined
                    )}
                >
                    {accounts.map((account: Account) => {
                        const key = getValue(account);

                        return (
                            <MenuItem
                                key={key}
                                value={key}
                            >
                                {account.currency} - {key}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </Box>
    );
};

export default memo(AccountSelector);
