import { useEffect } from 'react';
import type { Transaction, TransactionDetail, TransactionDetailResponse } from "features/transactions/types";
import useActions from "features/transactions/useActions";
import useTransactionSubject from "./useTransactionSubject";

export default function useTransactionDetails({
    pki
}: Pick<Transaction, 'pki'>) {
    const { getTransactionDetails } = useActions();

    const fetchData = async () => {
        const [transactionDetailsRequest] = getTransactionDetails(String(pki));

        const transactionDetails = await transactionDetailsRequest;

        return (transactionDetails as TransactionDetailResponse)?.data;
    };

    const [transactionDetails, fetchTransactionDetails] = useTransactionSubject({
        transactionId: String(pki),
        fetchData
    });

    // const getTransactionHistory = () => {
    //     if (transactionDetails) {
    //         return transactionDetails.histories.data;
    //     }

    //     return [];
    // };

    useEffect(() => {
        fetchTransactionDetails();
    }, [fetchTransactionDetails]);

    return transactionDetails as TransactionDetail; //{
        // transactionDetails: transactionDetails as TransactionDetail,
        // transactionHistory: getTransactionHistory()
    //}
};
