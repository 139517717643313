import { useState } from 'react';
import { Transaction, TransactionAction, TransactionActionResponse } from "features/transactions/types";
import useGeneralActions from "features/general/useActions";
import useActions from "features/transactions/useActions";
import useTransactionSubject from "./useTransactionSubject";
import { NotificationSeverity } from "features/general/types";
import { ID } from 'types';

type ResponseMapperReturnType<T> = {
    readonly message: string;
    readonly data: T;
};

export default function useTransactionActions({
    coreId
}: Pick<Transaction, 'coreId'>) {
    const {
        showNotication
    } = useGeneralActions();

    const {
        checkTransactionStatus,
        refundTransactionAction,
    } = useActions();

    const [actionLoading, setActionLoading] = useState(() => ({
        [TransactionAction.CheckStatus]: false,
        [TransactionAction.Refund]: false,
    }));

     const transactionId = `${coreId}`;

    const setLoading = (
        actionType: TransactionAction,
        isLoading: boolean) => setActionLoading(state => ({
            ...state,
            [actionType]: isLoading
        }));

    const fetchData = async ({
        actionType,
        fetchDataHandler,
        severity,
        id = coreId,
        responseAdapter = defaultResponseAdapter
    }: {
        readonly actionType: TransactionAction;
        readonly fetchDataHandler: (transactionId: string) => any;
        readonly severity: NotificationSeverity;
        readonly id?: ID;
        readonly responseAdapter?: typeof defaultResponseAdapter;
    }) => {
        setLoading(actionType, true);

        const [request] = fetchDataHandler(String(id));

        const transactionActionResponse = await request;

        const { data } = (transactionActionResponse as TransactionActionResponse);

        const { message, data: responseData } = responseAdapter(data);

        // let message = '';
        // if (typeof data === 'object') {

        //     ({ message } = data);
        // }

        if (message) {
            showNotication({
                severity,
                message
            });
        }

        setLoading(actionType, false);

        return responseData;
    };

    const [, checkStatus] = useTransactionSubject({
        transactionId,
        fetchData: async () => {
            fetchData({
                actionType: TransactionAction.CheckStatus,
                fetchDataHandler: checkTransactionStatus,
                severity: NotificationSeverity.Success
            });
        }
    });
    const [, refund] = useTransactionSubject({
        transactionId,
        fetchData: async () => {
            fetchData({
                actionType: TransactionAction.Refund,
                fetchDataHandler: refundTransactionAction,
                severity: NotificationSeverity.Success,
                responseAdapter: data => {
                    // TODO: replace any type
                    const { ResultCdcData } = data as any;

                    return {
                        message: ResultCdcData.DESCRIPTION,
                        data
                    };
                }
            });
        }
    });

    return {
        actionLoading,
        checkStatus,
        refund
    };
};

type ResponseAdapterData = {
    readonly message?: string;
};

function defaultResponseAdapter<T extends ResponseAdapterData>(data: T): ResponseMapperReturnType<T> {
    const { message = '' } = data;

    return {
        message: String(message),
        data
    };
}
