import { DateTimeFormatRepository } from "consts/general";
import moment, { Moment, MomentInput } from "moment";
import numeral from 'numeral';

type InputValue = string | number | null;

type NumberFormatterSettings =
    & Intl.NumberFormatOptions
    & {
        readonly locales?: string | string[];
    };

export class UiDateTimeFormatter extends DateTimeFormatRepository {
    static get Ui() {
        return this.Default;
    }

    static withISO8601 = (format: string) => `${format}T00:00:00.000Z`;

    static withHour = (format: string | number) => `${format}:00`;

    static withYearWeekUi = (year: string, week: string) => `${year}:${week} week`;
}

export const numberFormatter = (
    number: number,
    {
        locales = 'en-US',
        ...options
    }: NumberFormatterSettings = {}
) => new Intl.NumberFormat(locales, options).format(number);

export const capitalizeFirstLetterFormatter = (word: string) =>
    word.charAt(0).toUpperCase() + word.slice(1);

export const splitCamelCaseFormatter = (title: string) =>
    title.replace(/([a-z])([A-Z])/g, '$1 $2');

export const removeUnderscoreFormatter = (str: string, replaceValue = ' ') =>
    str.replace(/_/g, replaceValue);

type DateRangeFormatterSettings = {
    readonly pattern?: string;
};

export const dateRangeFormatter = <T = string>(
    [dateFrom, dateTo]: [(Moment | string)?, (Moment | string)?],
    { pattern = UiDateTimeFormatter.Write }: DateRangeFormatterSettings = {}
) => [
    moment(dateFrom)
        .startOf('day'),
    moment(dateTo)
        .endOf('day')
]
    .map((dateValue: Moment) =>
        dateValue.isValid()
            ? dateValue.format(pattern)
            : undefined) as Array<T>;

export const fNumber = (number: InputValue, inputString?: string) => numeral(number).format(inputString);

export const fCurrency = (number: InputValue) => {
    const format = number ? numeral(number).format('$0,0.00') : '';

    return result(format, '.00');
};

export const fPercent = (number: InputValue) => {
    const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

    return result(format, '.0');
}

export const fShortenNumber = (number: InputValue) => {
    const format = number ? numeral(number).format('0.00a') : '';

    return result(format, '.00');
}

export const fData = (number: InputValue) => {
    const format = number ? numeral(number).format('0.0 b') : '';

    return result(format, '.0');
}

export const formatDate = (value: MomentInput, {
    pattern,
    fallback = '-'
}: {
    readonly pattern?: string;
    readonly fallback?: string;
}) => {
    const date = moment(value);

    return date.isValid()
        ? date.format(pattern)
        : fallback;
};

export const formatWithMap = (key: string, map: Map<string, (() => string) | string>) => {
    const value = map.get(key);

    return typeof value === 'function'
        ? value()
        : value;
};

function result(format: string, key = '.00') {
    const isInteger = format.includes(key);

    return isInteger ? format.replace(key, '') : format;
}
