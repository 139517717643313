import { createSlice } from "@reduxjs/toolkit";
import initialState, { sliceToken } from "./state";
import {
    getEsMerchantPayoutFilesThunk,
    getEsTransactionsThunk,
    getMerchantPayoutFilesThunk
} from "./thunks";
import sliceMixin from "infrastructure/store/reducers/sliceMixin";
import type { FinanceState } from "./types";
import {
    merchantPayoutsRequestLoading,
    merchantPayoutsRequestFulfilled,
    merchantPayoutsRequestRejected,
    payoutsChatRequestLoading,
    payoutsChatRequestFulfilled,
    payoutsChatRequestRejected,
    chargebackRefundsChatRequestLoading,
    chargebackRefundsChatRequestFulfilled,
    chargebackRefundsChatRequestRejected,
    savePreviousUrl as savePreviousUrlReducer
} from "./reducers";

const slice = createSlice({
    name: sliceToken,
    initialState,
    reducers: {
        ...sliceMixin<FinanceState>(initialState),
        savePreviousUrl: savePreviousUrlReducer
    },
    extraReducers: builder => {
        builder
            // Merchant payouts table
            .addCase(getMerchantPayoutFilesThunk.pending, merchantPayoutsRequestLoading)
            .addCase(getMerchantPayoutFilesThunk.fulfilled, merchantPayoutsRequestFulfilled)
            .addCase(getMerchantPayoutFilesThunk.rejected, merchantPayoutsRequestRejected)
            // Payouts chart
            .addCase(getEsMerchantPayoutFilesThunk.pending, payoutsChatRequestLoading)
            .addCase(getEsMerchantPayoutFilesThunk.fulfilled, payoutsChatRequestFulfilled)
            .addCase(getEsMerchantPayoutFilesThunk.rejected, payoutsChatRequestRejected)
            // Chargeback refunds chart
            .addCase(getEsTransactionsThunk.pending, chargebackRefundsChatRequestLoading)
            .addCase(getEsTransactionsThunk.fulfilled, chargebackRefundsChatRequestFulfilled)
            .addCase(getEsTransactionsThunk.rejected, chargebackRefundsChatRequestRejected);
    }
});

export const {
    name,
    actions: {
        changeExportLoading,
        resetState,
        savePreviousUrl,
        resetTableLoadingState
    }
} = slice;
export default slice.reducer;
