import type { PayloadAction } from "@reduxjs/toolkit";
import type { WritableDraft } from "immer/dist/internal"
import { ApiState } from "infrastructure/api";
import type { ThunkReturnType } from "types";
import type { MerchantsResponse, MerchantsState } from "./types";

export const merchantsRequestLoading = (state: WritableDraft<MerchantsState>) => {
    state.merchantsLoadingState = ApiState.Pending;
};

export const merchantsRequestFulfilled = (
    state: WritableDraft<MerchantsState>,
    { payload }: PayloadAction<ThunkReturnType<MerchantsResponse>>) => {
    state.merchantsLoadingState = ApiState.Succeeded;
    state.merchantsSlice = payload!.data;
};

export const merchantsRequestRejected = (state: WritableDraft<MerchantsState>) => {
    state.merchantsLoadingState = ApiState.Failed;
    state.merchantsSlice = null;
};
