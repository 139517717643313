import { memo, type PropsWithChildren } from "react";
import type { Serie } from "@nivo/line";
import type { Status } from "types";
import type { useLinearChartColors } from "ui/widgets/Charts";
import Box from "@mui/material/Box";
import { ChargebackRefundLegendStatus } from "consts/finance";
import numeral from "numeral";

type Props = ReturnType<typeof useLinearChartColors> & {
    readonly status: ChargebackRefundLegendStatus;
    readonly data: Serie[];
};

const ChargebackRefundsLegend = ({ colors, data, status, children }: PropsWithChildren<Props>) => {
    const getColor = () => {
        if (typeof colors === 'function') {
            return colors({ id: status }).toString();
        }

        return String(colors);
    };

    const getTotalCountByStatus = (status: Status) => {
        for (const datum of data) {
            if (datum.id === status) {
                return datum.data.reduce((acc, { y }) => acc + Number(y), 0);
            }
        }

        return 0;
    };

    return (
        <Box
            sx={{
                px: '40px'
            }}
        >
            <Box
                sx={{
                    color: getColor(),
                    fontFamily: 'var(--manrope-font)',
                    fontSize: 27,
                    fontWeight: 800
                }}
            >
                {numeral(
                    (getTotalCountByStatus(status) / getTotalCountByStatus('SUCCEEDED')) * 100
                ).format('0.00')}%
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    color: 'text.secondary',
                    fontSize: 13,
                    ':before': {
                        content: '""',
                        display: 'inline-block',
                        width: 10,
                        height: 10,
                        borderRadius: '50%',
                        backgroundColor: getColor(),
                        marginRight: 1
                    }
                }}
            >
                {children}
            </Box>
        </Box>
    );
};

export default memo(ChargebackRefundsLegend);
