import { memo, type PropsWithChildren, type ReactNode } from "react";
import { UserRolesNames } from "consts/auth";
import useAuth from "features/auth/useAuth";

type Props = {
    readonly loadingSlot?: ReactNode;
    readonly fallbackSlot?: ReactNode;
    readonly notInRoles?: UserRolesNames[];
    readonly inRoles?: UserRolesNames[];
};

const Authorized = ({
    children,
    loadingSlot,
    fallbackSlot,
    notInRoles,
    inRoles
}: PropsWithChildren<Props>) => {
    const { user } = useAuth();

    const isPending = !user;

    if (isPending) {
        return (
            <>
                {loadingSlot}
            </>
        );
    }

    const isAuthorized = inRoles
        ? inRoles.includes(user.roleName)
        : !notInRoles?.includes(user.roleName);

    if (isAuthorized) {
        return (
            <>
                {children}
            </>
        );
    }

    return (
        <>
            {fallbackSlot}
        </>
    );
};

export default memo(Authorized);
