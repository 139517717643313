import { memo } from 'react';
import { ResponsiveLine } from '@nivo/line';
import StatisticDiagramCard from './StatisticDiagramCard';
import type { LinearChartProps } from './types';
import withData from './withData';

const Linear = (props: LinearChartProps) => (
    <StatisticDiagramCard
        {...props}
    >
        {withData(
            <ResponsiveLine
                data={[]}
                {...props.chartProps}
            />
        )({
            ...props.chartProps,
            isLoading: props.isLoading
        })}
    </StatisticDiagramCard>
);

export default memo(Linear);
