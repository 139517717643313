import type { PayloadAction } from "@reduxjs/toolkit";
import { ApiState } from "infrastructure/api";
import type { WritableDraft } from "immer/dist/internal";
import type {
    ConstantsResponse,
    CountriesPaginatedResponse,
    DomainsResourceResponse,
    GeneralState,
    LedgersResponse,
    SettingsResponse,
    UserPreferencesResponse
} from "./types";
import { booleanFilterFactory } from "util/filters";
import type { ThunkReturnType } from "types";
// import { convertArrayToOption, convertToOption } from "util/transformers";
// import { capitalizeFirstLetterFormatter } from "util/formaters";
// import { KYCProcessingStatus, KYCStatus } from "consts/kyc";
// import { MULTI_COLUMN_SEPARATOR } from "consts/filters";
import { SamePersonIdentifierSource } from "consts/consumers";
import { OpenBankingResultStatus } from "consts/transactions";
import { parse } from "util/parsers";
import { convertToOption } from "util/transformers";
import { capitalizeFirstLetterFormatter } from "util/formaters";

export const constantsRequestLoading = (state: WritableDraft<GeneralState>) => {
    state.constantsLoadingState = ApiState.Pending;
};

export const constantsRequestFulfilled = (
    state: WritableDraft<GeneralState>,
    { payload }: PayloadAction<ThunkReturnType<ConstantsResponse>>
) => {
    state.constantsLoadingState = ApiState.Succeeded;
    state.constants = {
        ...state.constants,
        ...payload!.data,
        TransactionPayoutStatus: booleanFilterFactory(['Not Paid', 'Paid']),
        TransactionSettlementStatus: booleanFilterFactory(['Not Settled', 'Settled']),
        Via: [],/*convertToOption(
            payload!.data.TransactionResultMessage,
            capitalizeFirstLetterFormatter
        ),*/
        TransactionCurrencies: Array.from(new Set<string>(payload!.data.TransactionCurrencies ?? [])),
        MerchantPayoutIncrementTaxName: [],//Object.values(payload!.data.MerchantPayoutIncrementTaxName),
        MerchantPayoutIncrementTaxRefColumnsFrom: [],//Object.values(payload!.data.MerchantPayoutIncrementTaxRefColumnsFrom),
        MerchantPayoutFileTaxName: [],//Object.values(payload!.data.MerchantPayoutFileTaxName),
        KYCStatusEnums: [],/*convertArrayToOption(
            `${
                KYCStatus.Pending}${
                MULTI_COLUMN_SEPARATOR}${
                KYCStatus.Success}${
                MULTI_COLUMN_SEPARATOR}${
                KYCStatus.Failed}${
                MULTI_COLUMN_SEPARATOR}${
                KYCStatus.NA}${
                MULTI_COLUMN_SEPARATOR}${
                KYCStatus.ManuallyCompleted}${
                MULTI_COLUMN_SEPARATOR}${
                KYCStatus.ManuallyPending}${
                MULTI_COLUMN_SEPARATOR}${
                KYCStatus.Triggered}`
                .split(MULTI_COLUMN_SEPARATOR)
        ),*/
        KYCStatusesMap: [
            // { id: KYCProcessingStatus.NA, name: 'N/A' },
            // ...convertToOption(payload!.data.KycStatusesMap)
        ],
        CardIssuer:convertToOption(
            payload!.data.TransactionCardIssuers,
            capitalizeFirstLetterFormatter
        ),
        SamePersonIdentifierSourceTypes: [
            { id: '', name: SamePersonIdentifierSource.Original },
            { id: SamePersonIdentifierSource.Iban, name: SamePersonIdentifierSource.Iban },
            { id: SamePersonIdentifierSource.Manual, name: SamePersonIdentifierSource.Manual },
            { id: SamePersonIdentifierSource.KYC, name: SamePersonIdentifierSource.KYC }
        ],
        ObResultStatuses: [
            {
                id: OpenBankingResultStatus.BankInfoNotSubmitted,
                name: 'Bank info not submitted'
            },
            {
                id: OpenBankingResultStatus.BankInfoSubmitted,
                name: 'Bank info submitted'
            },
            {
                id: OpenBankingResultStatus.PaymentFinalizedWaitingConfirmation,
                name: 'Payment finalized. Waiting confirmation.'
            },
            {
                id: OpenBankingResultStatus.RedirectedConsumerToAuthorizePayment,
                name: 'Redirected the consumer to authorize the payment'
            },
            {
                id: OpenBankingResultStatus.RequestedConsumerToAuthorizePayment,
                name: 'Requested the consumer to authorise the payment on its bank app/page via QR or token'
            },
            // {
            //     id: OpenBankingResultStatus.RequestedPasswordForConsumer,
            //     name: 'Requested password from the consumer'
            // },
            // {
            //     id: OpenBankingResultStatus.RequestedMoreInfoFromConsumer,
            //     name: 'Requested more info (answer) from the consumer'
            // },
            // {
            //     id: OpenBankingResultStatus.RequestedOptionFromConsumer,
            //     name: 'Requested an option from the consumer'
            // },
            //  {
            //     id: OpenBankingResultStatus.ErrorHasOccurred,
            //     name: 'An error has occurred'
            // },
            {
                id: OpenBankingResultStatus.RetryToFinalizePaymentAfterPeriod,
                name: 'Retry to finalize payment after a period.'
            },
            {
                id: OpenBankingResultStatus.AccountInitFinalizedWaitingPaymentInit,
                name: 'Account init finalized. Waiting payment init'
            },
            {
                id: OpenBankingResultStatus.RedirectedConsumerForAccountConfirmation,
                name: 'Redirected the consumer for account confirmation'
            },
            {
                id: OpenBankingResultStatus.RequestedConsumerToAuthorizeAccount,
                name: 'Requested the consumer to authorise the account on its bank app/page via QR or token'
            },
            {
                id: OpenBankingResultStatus.RetryToFinalizeAccountAfterPeriod,
                name: 'Retry to finalize account after a period.'
            }
        ]
    };
};

export const constantsRequestRejected = (state: WritableDraft<GeneralState>) => {
    state.constantsLoadingState = ApiState.Failed;
};

export const settingsRequestLoading = (state: WritableDraft<GeneralState>) => {
    state.settingsLoadingState = ApiState.Pending;
};

export const settingsRequestFulfilled = (
    state: WritableDraft<GeneralState>,
    { payload }: PayloadAction<ThunkReturnType<SettingsResponse>>) => {
    state.settingsLoadingState = ApiState.Succeeded;
    state.settings = payload!.data;
};

export const settingsRequestRejected = (state: WritableDraft<GeneralState>) => {
    state.settingsLoadingState = ApiState.Failed;
    state.settings = null;
};

export const domainsRequestLoading = (state: WritableDraft<GeneralState>) => {
    state.domainsLoadingState = ApiState.Pending;
};

export const domainsRequestFulfilled = (
    state: WritableDraft<GeneralState>,
    { payload }: PayloadAction<ThunkReturnType<DomainsResourceResponse>>) => {
    state.domainsLoadingState = ApiState.Succeeded;
    state.domains = payload!.data && {
        ...payload!.data,
        setupValue: JSON.parse(payload!.data.setupValue)
    };
};

export const domainsRequestRejected = (state: WritableDraft<GeneralState>) => {
    state.domainsLoadingState = ApiState.Pending;
    state.domains = null;
};

export const countriesRequestLoading = (state: WritableDraft<GeneralState>) => {
    state.countriesLoadingState = ApiState.Pending;
};

export const countriesRequestFulfilled = (
    state: WritableDraft<GeneralState>,
    { payload }: PayloadAction<ThunkReturnType<CountriesPaginatedResponse>>) => {
    state.countriesLoadingState = ApiState.Succeeded;
    state.countries = payload!.data.data ?? [];
};

export const countriesRequestRejected = (state: WritableDraft<GeneralState>) => {
    state.countriesLoadingState = ApiState.Pending;
    state.countries = [];
};

export const userPreferencesRequestLoading = (state: WritableDraft<GeneralState>) => {
    state.userPreferencesLoadingState = ApiState.Pending;
};

export const userPreferencesRequestFulfilled = (
    state: WritableDraft<GeneralState>,
    { payload }: PayloadAction<ThunkReturnType<UserPreferencesResponse>>
) => {
    const { data } = payload!;

    state.userPreferencesLoadingState = ApiState.Succeeded;
    state.userPreferences = {
        ...(data ?? {}),
        preferences: parse(data.preferences)
    };
};

export const userPreferencesRequestRejected = (state: WritableDraft<GeneralState>) => {
    state.userPreferencesLoadingState = ApiState.Failed;
    state.userPreferences = {
        preferences: {}
    };
};

export const ledgersRequestLoading = (state: WritableDraft<GeneralState>) => {
    state.ledgersLoadingState = ApiState.Pending;
};

export const ledgersRequestFulfilled = (
    state: WritableDraft<GeneralState>,
    { payload }: PayloadAction<ThunkReturnType<LedgersResponse>>) => {
    state.ledgersLoadingState = ApiState.Succeeded;
    state.ledgers = payload!.data;
};

export const ledgersRequestRejected = (state: WritableDraft<GeneralState>) => {
    state.ledgersLoadingState = ApiState.Pending;
    state.ledgers = null;
};
