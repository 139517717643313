import { memo } from 'react';
import { TableToolbar as WidgetTableToolbar, type TableToolbarProps } from 'ui/widgets/Table';
import { selectIsExportMerchantPayoutsLoading } from 'features/finance/selectors';

const TableToolbar = ({ onExport }: Pick<TableToolbarProps, 'onExport'>) => (
    <WidgetTableToolbar
        onExport={onExport}
        selectIsExportLoading={selectIsExportMerchantPayoutsLoading}
    />
);

export default memo(TableToolbar);
