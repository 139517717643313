import Box from "@mui/material/Box";
import { memo } from "react";

const EmptyData = () => (
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            fontSize: 20,
            fontWeight: 600,
            color: 'text.secondary',
            width: '100%'
        }}
    >
        <Box>
            No data available
        </Box>
    </Box>
);

export default memo(EmptyData);
