import { memo } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from '@mui/icons-material/Info';
import { CurrencySymbol } from "consts/general";
import type { FeeProps } from "./types";

const Fee = ({
    label,
    fee,
    legend,
    currency = CurrencySymbol.EUR
}: FeeProps) => (
    <Box
        sx={{
            py: 2,
            px: 2,
            display: 'flex',
            alignItems: 'center',
            gap: 1
        }}
    >
        <Typography
            variant='subtitle1'
            sx={{
                fontWeight: 400
            }}
        >
            {label}
        </Typography>
        <Box
            sx={{
                fontWeight: 600,
                ml: 2
            }}
        >
            {fee}{currency}
        </Box>
        <Tooltip
            title={legend}
            placement='top'
        >
            <InfoIcon color='disabled' />
        </Tooltip>
    </Box>
);

export default memo(Fee);
