import { useAppStore, useRequest, useTypedSelector } from "hooks";
import type { TablePreferences, UserPreferences, UserPreferencesRequest, UserPreferencesResponse } from "./types";
import type { ID, ThunkReturnType } from "types";
import { createOrUpdateUserPreferencesThunk, getUserPreferencesThunk } from "./thunks";
import useAuth from "features/auth/useAuth";
import { useEffect, useRef } from "react";
import { selectUserPreferencesLoadingState } from "./selectors";
import { ApiState } from "infrastructure/api";
import { TableGridMode } from "consts/table";

type UseUserPreferencesArg = {
    readonly mode?: TableGridMode;
    readonly shouldFetchUserPreferences?: boolean;
    readonly onUserPreferencesFetched?: (userPreferences: UserPreferences) => void;
};

export default function useUserPreferences({
    onUserPreferencesFetched,
    mode,
    shouldFetchUserPreferences = false
}: UseUserPreferencesArg) {
    const { user } = useAuth();
    const coreId = user?.coreId;

    const store = useAppStore();
    const userPreferencesLoadingState = useTypedSelector(selectUserPreferencesLoadingState);
    const onUserPreferencesFetchedRef = useRef(onUserPreferencesFetched);
    onUserPreferencesFetchedRef.current = onUserPreferencesFetched;

    const getUserPreferences = useRequest<ThunkReturnType<UserPreferencesResponse>, ID>({
        thunk: getUserPreferencesThunk,
        shouldNotifyOnReject: () => false
    });
    const getUserPreferencesRef = useRef(getUserPreferences);
    getUserPreferencesRef.current = getUserPreferences;

    const createOrUpdateUserPreferencesRequest = useRequest<ThunkReturnType<UserPreferencesResponse>, UserPreferencesRequest>({
        thunk: createOrUpdateUserPreferencesThunk
    });

    const createOrUpdateUserPreferences = (userPreferences: Partial<TablePreferences>) => {
        if (!coreId) {
            throw new Error('User must be logged in to create or update user preferences');
        }

        if (typeof mode !== 'number') {
            throw new Error('Table mode must be specified to create or update user preferences');
        }

        const { preferences } = store
            .getState()
            .general
            .userPreferences;

        const [userPreferencesRequest] = createOrUpdateUserPreferencesRequest({
            userId: coreId,
            preferences: JSON.stringify({
                ...preferences,
                [mode]: {
                    ...preferences[mode],
                    ...userPreferences
                }
            })
        });

        return userPreferencesRequest;
    };

    useEffect(() => {
        if (shouldFetchUserPreferences && coreId) {
            getUserPreferencesRef.current(coreId);
        }
    }, [
        shouldFetchUserPreferences,
        coreId
    ]);

    useEffect(() => {
        if (
            (typeof onUserPreferencesFetchedRef.current !== 'function') ||
            ![ApiState.Succeeded].includes(userPreferencesLoadingState)
        ) {
            return;
        }

        onUserPreferencesFetchedRef.current(
            store
                .getState()
                .general
                .userPreferences
        );
    }, [
        mode,
        store,
        userPreferencesLoadingState
    ]);

    return {
        getUserPreferences,
        createOrUpdateUserPreferences
    };
};
