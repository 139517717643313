import { postFeedback } from "features/general/api";
import { NotificationSeverity, FeedbackFormData } from "features/general/types";
import useActions from "features/general/useActions";
import { useSimpleRequest } from "hooks/useRequest";
import type { FeedbackFormProps } from "ui/forms/Feedback";

export default function useHome() {
    const {
        showNotication
    } = useActions();
    const request = useSimpleRequest();

    const onSubmit: FeedbackFormProps['onSubmit'] = async (
        feedbackFormData: FeedbackFormData,
        { resetForm }
    ) => request(() => postFeedback(feedbackFormData), {
        onSuccess: () => {
            resetForm();
            showNotication({
                message: `
                    Thank you for sharing your thoughts with us!
                    Your feedback is valuable and helps us improve.
                    We appreciate the time you took to contribute your ideas and suggestions.
                    If necessary, we may reach out to you for further information.
                `,
                severity: NotificationSeverity.Success
            });
        }
    });

    return {
        onSubmit
    };
};
