import { useState, useRef, useEffect, type MutableRefObject } from 'react';
import type { GridColumns, GridValidRowModel } from '@mui/x-data-grid';
import type { PaginateResourceResponse } from 'types';
import type { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { TableGridMode } from 'consts/table';
import useUserPreferences from 'features/general/useUserPreferences';
import useTableConfig from './useTableConfig';
import { isEqual } from 'util/support';

type UseTableColumns = {
    readonly mode: TableGridMode,
    readonly sortable: PaginateResourceResponse<null>['data']['sortable'];
    readonly columnOverrides: GridColumns;
    readonly tableApiRef: MutableRefObject<GridApiPremium | undefined>;
};

export default function useTableColumns<T extends GridValidRowModel>({
    mode,
    tableApiRef,
    columnOverrides = [],
    sortable = []
}: UseTableColumns) {
    const [tableColumnsConfig] = useTableConfig(mode, columnOverrides);

    const [columns, onColumnChange] = useState<GridColumns<T>>(tableColumnsConfig);

    const columnsRef = useRef<GridColumns<T>>([...columns]);

    if (typeof tableApiRef.current?.getAllColumns === 'function') {
        columnsRef.current = tableApiRef.current.getAllColumns();
    }

    const transactionTableSortableRef = useRef([...sortable]);

    const { createOrUpdateUserPreferences } = useUserPreferences({
        mode,
        onUserPreferencesFetched: ({ preferences }) => {
            const tablePreferences = preferences[mode] ?? {};

            if ('columnOrder' in tablePreferences) {
                const currentColumns = [];
                const upgradedColumns = [];

                for (const column of columnsRef.current) {
                    if (tablePreferences.columnOrder.some(field => field === column.field)) {
                        currentColumns.push(column);
                    }else {
                        upgradedColumns.push(column);
                    }
                }

                onColumnChange([
                    ...currentColumns,
                    ...upgradedColumns
                ]);
            }
        }
    });

    const onColumnOrderChange = () => {
        const columns = tableApiRef.current!.getAllColumns();

        onColumnChange(columns);

        createOrUpdateUserPreferences({
            columnOrder: columns
                .reduce((columns: Array<string>, { field }) => [
                    ...columns,
                    field
                ], [])
        });
    };

    const getColumns = () => columnsRef.current;

    useEffect(() => {
        if (!isEqual(
            sortable,
            transactionTableSortableRef.current
        )) {
            onColumnChange(
                columnsRef.current
                    .map(column => ({
                        ...column,
                        sortable: sortable.includes(column.field)
                    }))
            );
        }

        transactionTableSortableRef.current = sortable;
    }, [sortable]);

    return {
        columns,
        getColumns,
        onColumnChange,
        onColumnOrderChange
    };
};
