import { type FC, memo } from "react";
import type { BoxProps } from "@mui/material/Box";
import Box from "@mui/material/Box";
import type { ElasticSearchResourceResponse } from "types";
import {
    type LinearDiagramProps,
    LinearDiagram,
    getLinearChartData,
    useLinearDiagram,
    integerFormat,
    diagramPropsPredicate,
    filterOutDataByStatus,
    useLinearChartColors
} from "ui/widgets/Charts";
import { useTypedSelector } from "hooks";
import {
    selectChargebackRefundsChartLoadingState,
    selectChargebackRefundsChartPayload,
    selectPreviousUrl
} from "features/finance/selectors";
import { Filters } from "consts/filters";
 import ChargebackRefundsLegend from "./ChargebackRefundsLegend";
 import { ChargebackRefundLegendStatus, chargebackRefundsColorSchemeMap } from "consts/finance";

type ChargebackRefundsProps =
    & Pick<LinearDiagramProps, 'className' | 'isLoading'>
    & {
        readonly payload: ElasticSearchResourceResponse<string>['data'];
    };

const ChargebackRefundsDiagram = memo(({
    className,
    isLoading,
    payload
}: ChargebackRefundsProps) => {
    const url = useTypedSelector(selectPreviousUrl);
    const {
        data,
        columns
    } = useLinearDiagram({
        isLoading,
        onLoad: () => {
            const data = getLinearChartData(payload, {
                periodQueryParameterField: Filters.updatedAt,
                periodGroupingField: Filters.createdAt,
                url,
                getDatum: row => {
                    return {
                    pivot: String(row.at(-1)),
                    x: row.at(-2),
                    y: Number(row.at(-3))
                    }
                }
            });

            return {
                total: 0,
                data,
                columns: payload?.columns ?? []
            };
        }
    });
    const filtredData = filterOutDataByStatus( data, "SUCCEEDED" )

    return (
        <LinearDiagram
            className={className}
            subheader={(
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%'
                    }}
                >
                    <ChargebackRefundsLegend
                        {...useLinearChartColors(chargebackRefundsColorSchemeMap)}
                        data={data}
                        status={ChargebackRefundLegendStatus.Chargeback}
                    >
                        Chargebacks
                    </ChargebackRefundsLegend>
                    <ChargebackRefundsLegend
                        {...useLinearChartColors(chargebackRefundsColorSchemeMap)}
                        data={data}
                        status={ChargebackRefundLegendStatus.Refund}
                    >
                        Refunds
                    </ChargebackRefundsLegend>
                </Box>
            )}
            sx={{
                zIndex: 1
            }}
            title='Overall chargebacks and refunds for the selected period'
            columns={columns}
            chartProps={{
                ...useLinearChartColors(chargebackRefundsColorSchemeMap),
                margin: {
                    right: 130
                },
                yFormat: ' >-,',
                axisLeft: {
                    legend: 'Group Count',
                    format: integerFormat
                },
                data:filtredData
            }}
            periodGroupingField={Filters.createdAt}
            isLoading={isLoading}
        />
    );
}, diagramPropsPredicate);

const ChargebackRefunds: FC<BoxProps> = ({ className }) => {
    const payload = useTypedSelector(selectChargebackRefundsChartPayload);
    const isLoading = useTypedSelector(selectChargebackRefundsChartLoadingState);

    return (
        <ChargebackRefundsDiagram
            className={className!}
            isLoading={isLoading}
            payload={payload}
        />
    );
};

ChargebackRefunds.defaultProps = {
    className: 'js-chargeback-refunds'
};

export default memo(ChargebackRefunds);
