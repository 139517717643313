import { useRef, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { FilterVariant } from 'consts/filters';
import {
    Environment,
    FilterValueObject,
    Filter,
    FilterChangePayload,
    FilterRenderTypes,
    FilterType,
    ChangePayload,
    ValueObjectStrategy,
    FiltersChangeHandler
} from 'ui/widgets/Filters';
import type { Config } from 'ui/widgets/Filters/hooks/useFiltersConfigParser';
import useFiltersConfigParser from 'ui/widgets/Filters/hooks/useFiltersConfigParser';
import FiltersRepository from 'ui/widgets/Filters/FiltersRepository';
import { Paginate, summsQueryField } from 'consts/table';
import useFiltersVariants from './useFiltersVariants';

export type FiltersAggregate<T extends string> = {
    readonly [P in FilterType<T>]: Filter;
};

export type FiltersRegistry<T extends string> = {
    [P in FilterType<T>]?: FilterValueObject
};

type ActionLifecycleHook = <TFilterType extends string>(
    urlSearchParams: URLSearchParams,
    filters: FiltersAggregate<TFilterType>) => void;

export type UseFiltersProps<TFilterType extends string> = {
    readonly filterTypes: Map<FilterVariant, TFilterType[]>;
    readonly fetchData: (urlSearchParams: URLSearchParams) => void;
    // readonly onBeforeApply?: ActionLifecycleHook;
    readonly onBeforeFetch?: ActionLifecycleHook;
    readonly onFilterInit?: FiltersChangeHandler;
};

export default function useFilters<TFilterType extends string>({
    filterTypes,
    filtersConfig,
    fetchData,
    // onBeforeApply,
    onBeforeFetch,
    onFilterInit
}: UseFiltersProps<TFilterType> & {
    readonly filtersConfig: Config[];
}) {
    const resetTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
    const [urlSearchParams, setSearchParams] = useSearchParams();

    const filtersPayloadRegistryRef = useRef<FiltersRegistry<TFilterType>>({});

    const parseConfig = useFiltersConfigParser();

    const {
        getFilterVariantByFilterName
    } = useFiltersVariants(filterTypes);

    const handleStrategy = ({
        type,
        payload,
        meta
    }: ChangePayload<TFilterType, any>,
        strategy: ValueObjectStrategy) => {
        const filterVariant = getFilterVariantByFilterName(type);

        const filterController = FiltersRepository.getStrategyByFilterVariant(filterVariant);

        const valueObject = filterController[strategy](payload, meta);

        filtersPayloadRegistryRef.current[type] = valueObject;

        return valueObject;
    };

    const clearSearchParams = (urlSearchParams: URLSearchParams) => {
        for (const filterName of [
            ...Object.keys(filtersPayloadRegistryRef.current),
            Paginate.page,
            summsQueryField
        ]) {
            urlSearchParams.delete(filterName);
        }

        return urlSearchParams;
    }

    const getFilterValueFromUrlSearchQueryParams = (type: TFilterType, filter: Config) => {
        const values = urlSearchParams
            .getAll(type);

        return handleStrategy(
            {
                type,
                payload: urlSearchParams.has(type)
                    ? values
                    : [filter.defaultValue]
                        .filter(value => ![undefined, null].includes(value)),
                meta: filter.meta
            },
            ValueObjectStrategy.SearchQuery
        );
    };

    const [filters, setFiltersState] = useState<FiltersAggregate<TFilterType>>(() =>
        filtersConfig.reduce((
            aggregate: FiltersAggregate<TFilterType>,
            filter: Config
        ) => {
            const name = (filter.searchQueryParam ?? filter.filterId) as TFilterType;
            const filterValue = getFilterValueFromUrlSearchQueryParams(name, filter);

            onFilterInit?.({
                type: name,
                payload: filterValue.value,
                meta: filter.meta
            });

            return {
                ...aggregate,
                [name]: {
                    renderAs: filter.renderAs as FilterRenderTypes,
                    meta: filter.meta,
                    defaultValue: filter.defaultValue,
                    props: {
                        id: filter.filterId,
                        name,
                        value: filterValue.value,
                        ...parseConfig(
                            filter.inputs,
                            filter.adornment,
                            filter.icon
                        )
                    }
                }
            };
        }, {} as FiltersAggregate<TFilterType>)
    );

    const getConfigByFilterId = (filterId: TFilterType) => filters[filterId];

    const getDefaultValue = ([filterName, filterValue]: [TFilterType, Filter]) => {
        const filterVariant = getFilterVariantByFilterName(filterName);

        return filterValue.defaultValue ?? FiltersRepository.getDefaultValue(
            filterVariant,
            getConfigByFilterId(filterName).meta
        );
    };

    const getFiltersRegistryUrlSearchParams = (
        params = new URLSearchParams(),
        environment = Environment.Server
    ) => {
        return Object.entries<
            FilterValueObject<FilterChangePayload> | undefined
        >(filtersPayloadRegistryRef.current)
            .reduce((urlSearchParams, [key, valueObject]) => {
                if (valueObject instanceof FilterValueObject) {
                    valueObject
                        .toArray()
                        .forEach((value, index: number) => {
                            if (!valueObject.isEmpty(value, index)) {
                                urlSearchParams.append(
                                    key,
                                    valueObject.serialize(value, environment, index)
                                );
                            }
                        });
                }

                return urlSearchParams;
            }, params);
    }

    const onChange = ({
        type,
        payload,
        meta = {}
    }: ChangePayload<
        TFilterType,
        FilterChangePayload
    >) => {
        setFiltersState((state: FiltersAggregate<TFilterType>) => ({
            ...state,
            [type]: {
                ...state[type],
                props: {
                    ...state[type].props,
                    value: payload
                }
            }
        }));

        const filterMeta = getConfigByFilterId(type).meta ?? {};

        handleStrategy({
            type,
            payload,
            meta: {
                // Custom overrides / plugins port
                ...filterMeta,
                // DOM attributes port
                ...meta
            }
        }, ValueObjectStrategy.Change);
    };


    const onReset = () => {
        for (const [filterName, filterValue] of Object.entries<Filter>(filters)) {
            const type = filterName as TFilterType;
            onChange({
                type,
                payload: getDefaultValue([type, filterValue]) as FilterChangePayload
            });
        }

        setSearchParams();

        resetTimeoutRef.current = setTimeout(onApply);
    };

    const onApply = () => {
        const urlSearchQueryParams = getFiltersRegistryUrlSearchParams(
            clearSearchParams(urlSearchParams)
        );

        // onBeforeApply?.(urlSearchQueryParams, filters);

        setSearchParams(urlSearchQueryParams);

        onBeforeFetch?.(urlSearchQueryParams, filters);

        fetchData(urlSearchQueryParams);
    };

    useEffect(() =>
        () => clearTimeout(resetTimeoutRef.current)
    , []);

    return {
        filters: Object.values<Filter>(filters),
        getFiltersRegistryUrlSearchParams,
        onChange,
        onReset,
        onApply
    };
};
