import { memo, useState, type PropsWithChildren } from "react";
import { useLocales } from 'locales';
import Stack, { StackProps } from "@mui/material/Stack";
import LoadingButton, { type LoadingButtonProps } from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import { downloadFile } from "features/general/api";

type Props = StackProps & {
    readonly actionProps?: LoadingButtonProps;
};

const Documentation = ({ children, actionProps = {}, ...restProps }: PropsWithChildren<Props>) => {
    const [isLoading, setLoading] = useState(false);

    const { translate } = useLocales();

    const onClick = () => {
        setLoading(true);

        downloadFile('documentation.zip', 'documentation')
            .finally(() => setLoading(false));
    };

    return (
        <Stack
            spacing={3}
            {...restProps}
            sx={{
                px: 5,
                pb: 5,
                mt: 10,
                width: 1,
                display: 'block',
                textAlign: 'center',
                ...restProps.sx
            }}
        >
            <Box
                component="img"
                src="/assets/illustrations/illustration_docs.svg"
            />
            {children}
            <LoadingButton
                onClick={onClick}
                variant='contained'
                {...actionProps}
                loading={isLoading}
            >
                <>{actionProps.children ?? translate('docs.documentation')}</>
            </LoadingButton>
        </Stack>
    );
};

export default memo(Documentation);
