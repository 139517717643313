import { memo } from "react";
import Box from "@mui/material/Box";
import { UploadIllustration } from 'assets/illustrations';
import Resource from 'ui/layouts/Resource';
import SupportForm from "ui/forms/Support";
import useSupport from "./useSupport";

const Support = () => {
    const { onSubmit } = useSupport();

    return (
        <Resource
            title='Merchants | Support'
            breadcrumbsProps={{
                heading: 'Support',
                links: [{ name: '' }]
            }}
        >
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: 10
                }}
            >
                <SupportForm
                    onSubmit={onSubmit}
                />
                <UploadIllustration />
            </Box>
        </Resource>
    );
};

export default memo(Support);
