import type { MerchantPayoutFile } from "features/finance/types";
import Box from "@mui/material/Box";
import { financial } from "ui/widgets/Table/formatters";
import { withGridCellParams } from "ui/widgets/Table/renderers";

const Amount = withGridCellParams<number, MerchantPayoutFile>(({ row, ...restProps }) => (
    <Box
        sx={{
            fontWeight: 'bold'
        }}
    >
        {row.currency} {financial({
            ...restProps,
            value: `${Number(row.amountPaid) - Number(row.rollingReservePaid)}`
        })}
    </Box>
));

export default Amount;
