import { memo } from "react";
import TextField, { type TextFieldProps } from '@mui/material/TextField';
import MenuItem from "@mui/material/MenuItem";
import { departments } from "consts/general";
import type { Option } from "types";
import { compareIds } from "util/support";
import BusinessLogicException from "exceptions/BusinessLogicException";

type Props = Omit<TextFieldProps, 'onChange'> & {
    readonly onChange: (department: Option) => void;
};

const DepartmentSelector = ({
    onChange,
    ...props
}: Props) => {
    const departmentsCollection = Array.from(departments.entries());

    const onDepartmentChange: TextFieldProps['onChange'] = ({ target }) => {
        const departmentEntry = departmentsCollection.find(([, email]) => compareIds(email, target.value));

        if (!departmentEntry) {
            throw new BusinessLogicException(
                `Department with email ${target.value} not found`,
                {}
            );
        }

        const [name, id] = departmentEntry;

        onChange({
            id,
            name
        });
    };

    return (
        <TextField
            select
            {...props}
            onChange={onDepartmentChange}
        >
            {departmentsCollection
                .map(([department, email]) => (
                    <MenuItem
                        key={department}
                        value={email}
                    >
                        {department}
                    </MenuItem>
                ))}
        </TextField>
    );
};

export default memo(DepartmentSelector);
