export const paymentMethods = new Map<string, string>([
    ['sepatransfer', 'Sepa Transfer'],
    ['directbankingeu', 'Direct Banking EU'],
    ['mbway', 'MBWay'],
    ['obt', 'Online Banking Transfer'],
    ['op', 'Open Banking'],
    ['bcmc', 'Bancontact'],
    ['directpay', 'Sofort'],
    ['eps', 'EPS']
]);
