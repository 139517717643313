import { memo } from "react";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import ChargebackRefunds from "./ChargebackRefunds";
import Payouts from "./Payouts";

const FinanceCharts = () => {
    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: `repeat(${Number(useMediaQuery('(min-width:1408px)')) + 1}, 1fr)`,
                gap: 2,
                mt: 2
            }}
        >
            <Payouts />
            <ChargebackRefunds />
        </Box>
    );
};

export default memo(FinanceCharts);
