import type { AutocompleteProps, TextFieldProps, FormControlProps } from "@mui/material";
import MuiAutocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import { forwardRef, ForwardRefRenderFunction } from "react";
import InputLabel from "ui/atoms/InputLabel";
import TextField from "@mui/material/TextField";

export type Props = Omit<AutocompleteProps<any, any, any, any>, 'renderInput'> & {
    readonly label?: string;
    readonly TextFieldProps?: TextFieldProps;
    readonly FormControlProps?: FormControlProps;
};

const Autocomplete: ForwardRefRenderFunction<any, Props> = ({
    label,
    TextFieldProps = {},
    FormControlProps = {},
    ...props
}, ref) => (
    <FormControl
        variant='standard'
        {...FormControlProps}
    >
        <InputLabel>
            {label}
        </InputLabel>
        <MuiAutocomplete
            ref={ref}
            {...props}
            renderInput={(params) => (
                <TextField
                    {...TextFieldProps}
                    {...params}
                    size='small'
                />
            )}
        />
    </FormControl>
);

export default forwardRef(Autocomplete);
