import { PayloadAction } from "@reduxjs/toolkit";
import { Draft, WritableDraft } from "immer/dist/internal";
import { ApiState } from "infrastructure/api";
import {
    exportableStateGuard,
    sliceStateGuard,
    tabbableStateGuard
} from "./guards";

export default function sliceMixin<T extends object, TTabs = undefined>(initialState: T) {
    return {
        saveTabSearchParams: (state: WritableDraft<T>, { payload }: PayloadAction<Draft<string>>) => {
            if (tabbableStateGuard(state)) {
                const activeTab = state.activeTab;
                state[activeTab].searchParams = payload;
            }
        },
        changeTab: (state: WritableDraft<T>, { payload }: PayloadAction<Draft<TTabs>>) => {
            if (tabbableStateGuard(state)) {
                state.activeTab = String(payload);
            }
        },
        changeExportLoading: (state: WritableDraft<T>, { payload }: PayloadAction<Draft<ApiState>>) => {
            if (tabbableStateGuard(state)) {
                const activeTab = state.activeTab;
                state[activeTab].exportLoadingState = payload;
            }

            if (exportableStateGuard(state)){
                state.exportLoadingState = payload;
            }
        },
        resetTableLoadingState: (state: WritableDraft<T>) => {
            if (tabbableStateGuard(state)) {
                const activeTab = state.activeTab;
                state[activeTab].tableLoadingState = ApiState.Idle;
            }

            if (sliceStateGuard(state)) {
                state.tableLoadingState = ApiState.Idle;
            }
        },
        resetState: (state: WritableDraft<T>) => {
            Object.assign(state, initialState);
        }
    };
};
