import { memo } from "react";
import Screen from "ui/layouts/Screen";
import Home from "ui/organizms/Home";

const HomeScreen = () => (
    <Screen
        title='Home'
    >
        <Home />
    </Screen>
);

export default memo(HomeScreen);
