import Box from '@mui/material/Box';
import Header from './Header';
import Tabs from './Tabs';

const Profile = () => (
    <Box>
        <Header />
        <Tabs />
    </Box>
);

export default Profile;