import { memo } from "react";
import Box from "@mui/material/Box";
import { useTypedSelector } from "hooks";
import { selectSettings } from "features/general/selectors";
import { ClipboardTextField } from "ui/molecules/Input";

const GeneralInfo = () => {
    const settings = useTypedSelector(selectSettings);

    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                alignItems: 'flex-start',
                gap: 3
            }}
        >
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    gap: 3,
                }}
            >
                <ClipboardTextField
                    variant='outlined'
                    label='Company'
                    size='small'
                    fullWidth
                    defaultValue={settings?.fullName}
                    inputProps={{
                        readOnly: true
                    }}
                />
                <ClipboardTextField
                    variant='outlined'
                    label='VAT Number'
                    size='small'
                    fullWidth
                    defaultValue={settings?.vatAddress}
                    inputProps={{
                        readOnly: true
                    }}
                />
                <ClipboardTextField
                    variant='outlined'
                    label='Phone'
                    size='small'
                    fullWidth
                    defaultValue={settings?.phone}
                    inputProps={{
                        readOnly: true
                    }}
                />
                <ClipboardTextField
                    variant='outlined'
                    label='Default Language'
                    size='small'
                    fullWidth
                    defaultValue={settings?.defaultLanguage}
                    inputProps={{
                        readOnly: true
                    }}
                />
                <ClipboardTextField
                    variant='outlined'
                    label='Country'
                    size='small'
                    fullWidth
                    defaultValue={settings?.countryAddress}
                    inputProps={{
                        readOnly: true
                    }}
                />
            </Box>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    gap: 3,
                }}
            >
                <ClipboardTextField
                    variant='outlined'
                    label='Address'
                    size='small'
                    fullWidth
                    defaultValue={settings?.fullAddress}
                    inputProps={{
                        readOnly: true
                    }}
                />
                <ClipboardTextField
                    variant='outlined'
                    label='City'
                    size='small'
                    fullWidth
                    defaultValue={settings?.cityAddress}
                    inputProps={{
                        readOnly: true
                    }}
                />
                <ClipboardTextField
                    variant='outlined'
                    label='State'
                    size='small'
                    fullWidth
                    defaultValue={settings?.stateAddress}
                    inputProps={{
                        readOnly: true
                    }}
                />
                <ClipboardTextField
                    variant='outlined'
                    label='Zip Code'
                    size='small'
                    fullWidth
                    defaultValue={settings?.zipAddress}
                    inputProps={{
                        readOnly: true
                    }}
                />
            </Box>
        </Box>
    );
};

export default memo(GeneralInfo);
