import { ApiRoutes } from "consts/enpoints/api";
import { FormSerializers, downloadFileApi, fetchApi } from "infrastructure/api";
import type { ID } from "types";
import type { FeedbackFormData, SupportFormData, UserPreferencesRequest } from "./types";
import { getFileExtension } from "util/support";

export const getConstants = () =>
    fetchApi(ApiRoutes.GetConstants());

export const getSettings = () =>
    fetchApi(ApiRoutes.GetSettings());

export const getDomains = () =>
    fetchApi(ApiRoutes.GetApiDomains);

export const getCountries = (query = '') =>
    fetchApi(`${ApiRoutes.GetCountries()}${query}`);

export const getLedgers = (query = '') =>
    fetchApi(`${ApiRoutes.GetLedgers()}${query}`);

export const downloadFile = (fileKey: string, fileName: string = fileKey) =>
    downloadFileApi(ApiRoutes.GetFile(fileKey), {
        headers: {
            Accept: `application/${getFileExtension(fileKey)}`,
        },
        fileName
    });

export const postFile = (body: FormData) =>
    fetchApi(ApiRoutes.PostFile, {
        serializer: FormSerializers.FormData,
        method: 'POST',
        body
    });

export const deleteFile = (fileKey: string) =>
    fetchApi(ApiRoutes.DeleteFile(fileKey), {
        method: 'DELETE'
    });

export const getUserPreferences = (userId: ID) =>
    fetchApi(ApiRoutes.GetUsersPreferences(userId));

export const createOrUpdateUserPreferences = ({ userId, preferences }: UserPreferencesRequest) =>
    fetchApi(ApiRoutes.PutUsersPreferences(userId), {
        method: 'PUT',
        body: {
            preferences
        }
    });

export const postFeedback = (body: FeedbackFormData) =>
    fetchApi(ApiRoutes.PostFeedback(), {
        method: 'POST',
        body
    });

export const postSupport = (body: SupportFormData) =>
    fetchApi(ApiRoutes.PostSupport(), {
        method: 'POST',
        body
    });
