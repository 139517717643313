import { memo, type PropsWithChildren, type ReactNode } from "react";
import Box, { type BoxProps } from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import type { IncrementTaxes } from "./types";
import Fees, { type FeesProps } from "./Fees";
import { formatPaymentMethodName } from "features/paymentMethods/helpers";

type Props = BoxProps & {
    readonly header: ReactNode;
    readonly getFeesMethods: () => Array<[string | null, Map<string, IncrementTaxes[]>]>;
    readonly renderFeeTitle: (tag: IncrementTaxes['tag']) => ReactNode;
    readonly feesProps?: Partial<FeesProps>;
};

const FeeCategory = ({
    sx,
    header,
    children,
    getFeesMethods,
    renderFeeTitle,
    feesProps
}: PropsWithChildren<Props>) => (
    <Box
        sx={sx}
    >
        <Typography
            variant="h6"
            component="h3"
            sx={{
                mb: 4
            }}
        >
            {header}
        </Typography>
        {children}
        <Box
            sx={{
                display: 'grid',
                gap: 4
            }}
        >
            {getFeesMethods()
                .map(([tag, incrementTaxes]) => (
                    <Fees
                        {...feesProps}
                        key={tag}
                        tag={tag}
                        title={renderFeeTitle(tag && formatPaymentMethodName(tag))}
                        incrementTaxes={incrementTaxes}
                    />
                ))}
        </Box>
    </Box>
);

export default memo(FeeCategory);
