import { getTokenPayload } from 'features/auth/helpers';
import { route as r } from 'util/support';

interface MerchantRoute {
    (param: Parameters<typeof r>[0]): ReturnType<typeof r>;
    merchant: (param: Parameters<typeof r>[0]) => ReturnType<typeof r>;
    original: typeof r;
};

const route: MerchantRoute = (param: Parameters<typeof r>[0]) => r(['merchants', ...param]);

route.merchant = function(param: Parameters<typeof r>[0]) {
    return this([
        getTokenPayload()?.merchantIds.at(0),
        ...param
    ]);
};

route.original = r;

export default route;
