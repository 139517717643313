import { memo } from "react";
import {
    esTransactionsByPaymentMethodQueryToken,
    useStatisticDashboardContext
} from "ui/organizms/StatisticDashboard/StatisticDashboardProvider";
import { CurrencySymbol } from "consts/general";
import {
    AxisBottomTick,
    BarDiagram,
    BarTooltip,
    formatXDateAxis,
    getBarChartData,
    integerFormat
} from "ui/widgets/Charts";
import { Filters } from "consts/filters";
import { fNumber } from "util/formaters";
import { formatPaymentMethodName } from "features/paymentMethods/helpers";

const ProcessedAmountsPerPaymentMethod = () => {
    const statisticDashboard = useStatisticDashboardContext();
    const { payload, isLoading } = statisticDashboard[esTransactionsByPaymentMethodQueryToken];

    const tagSet = new Set<string>();
    const totalAmountsEurPerPeriodMap = new Map<string | number, number>();
    const amountEurPerTagRecord: Record<
        string,
        {
            readonly [period: string]: number;
        }
    > = {};

    const setAmountEurPerTagRecord = ({
        amountEur,
        period,
        tag
    }: {
        readonly amountEur: number;
        readonly period: string | number;
        readonly tag: string;
    }) => {
        amountEurPerTagRecord[tag] = {
            ...amountEurPerTagRecord[tag],
            [period]: (amountEurPerTagRecord[tag]?.[period] || 0) + amountEur
        };
    };

    const setTotalAmountsEurPerPeriodMap = ({
        amountEur,
        period
    }: {
        readonly amountEur: number;
        readonly period: string | number;
    }) => {
        totalAmountsEurPerPeriodMap.set(
            period,
            (totalAmountsEurPerPeriodMap.get(period) || 0) + amountEur
        );
    };

    const data = getBarChartData(payload, {
        getDatum: row => {
            const amountEur = Number(row.at(0));
            const groupCount = Number(row.at(1));
            const period = formatXDateAxis(
                payload?.columns,
                Filters.createdAt
            )(String(row.at(2)));
            const tag = formatPaymentMethodName(String(row.at(3)).trim());

            tagSet.add(tag);

            setAmountEurPerTagRecord({
                amountEur,
                period,
                tag
            });

            setTotalAmountsEurPerPeriodMap({
                amountEur,
                period
            })

            return {
                period,
                [tag]: groupCount
            };
        },
        getIndex: row => String(row.at(2))
    });

    return (
        <BarDiagram
            isLoading={isLoading}
            title='Processed Per Payment Method'
            sx={{
                minHeight: 490
            }}
            chartProps={{
                indexBy: 'period',
                keys: Array.from(tagSet),
                role: 'application',
                ariaLabel: 'Processed Per Payment Method',
                barAriaLabel: e => `${e.id}: ${e.formattedValue} per method ${e.indexValue}`,
                enableGridX: true,
                tooltip: props => (
                    <BarTooltip
                        {...props}
                        label={[props.id, props.data[props.id]].join(' - ')}
                        formattedValue={fNumber(
                            amountEurPerTagRecord[props.id]?.[props.indexValue] ?? 0,
                            '0,0.00'
                        )}
                        postfix={CurrencySymbol.EUR}
                    />
                ),
                axisBottom: {
                    renderTick: props => (
                        <AxisBottomTick
                            {...props}
                            isLoading
                            renderTitle={props =>
                                `${props.value}: ${
                                    fNumber(
                                        totalAmountsEurPerPeriodMap.get(props.value)!,
                                        '0,0.00'
                                    )
                                } ${CurrencySymbol.EUR}`}
                        />
                    )
                },
                axisLeft: {
                    legend: `Number of Transactions`,
                    format: integerFormat
                },
                data
            }}
        />
    );
};

export default memo(ProcessedAmountsPerPaymentMethod);
