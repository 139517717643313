import { useRequest } from "hooks";
import type {
    InNotification,
    NotificationsResponse,
    OutNotification,
    TransactionActionResponse,
    TransactionDetailResponse,
    TransactionHistory,
    TransactionsResponse
} from "./types";
import {
    checkTransactionStatusThunk,
    closePingOrderThunk,
    fakeTransactionRedirectThunk,
    getBankSdkThunk,
    getInNotificationsThunk,
    getOutNotificationsThunk,
    getTransactionDetailsThunk,
    getTransactionHistoryThunk,
    getTransactionsThunk,
    refundThunk,
    sendTransactionNotificationThunk,
    settlePingOrderThunk
} from "./thunks";
import { changeExportLoading, resetState } from "./slice";
import type { ID, ThunkReturnType } from "types";
import { TableGridMode } from "consts/table";
import useResetState from "hooks/useResetState";
import useExportTable from "hooks/useExportTable";
import { ApiRouteTypes } from "consts/enpoints/api";
import { RequestQueryMapper } from "util/request-query-mapper";
import { Filters } from "consts/transactions";
import { Filters as GeneralFilters } from "consts/filters";
import { RequestQueryMapperStrategy } from "consts/request-query";

const useActions = (mode = TableGridMode.Transaction) => {
    const transactionStrategies = new Map<TableGridMode, any>()
        .set(TableGridMode.Transaction, getTransactionsThunk)
        .set(TableGridMode.BankSdk, getBankSdkThunk);

    const getTransactions = useRequest<ThunkReturnType<TransactionsResponse>, string>({
        thunk: transactionStrategies.get(mode)!
    });

    const getTransactionDetails = useRequest<ThunkReturnType<TransactionDetailResponse>, string>({
        thunk: getTransactionDetailsThunk
    });

    const getTransactionHistory = useRequest<
        ThunkReturnType<TransactionHistory>,
        ID
    >({ thunk: getTransactionHistoryThunk });

    const download = useExportTable({
        changeExportLoading,
        apiResourceType: ApiRouteTypes.GetTransactions
    });

    const downloadCsv = (queryParamString: string) =>
        download(
            RequestQueryMapper.from(
                queryParamString, {
                setStrategy: RequestQueryMapperStrategy.Set
            })
            .exportVisibility([
                Filters.transactionId,
                Filters.trackingMemberCode,
                Filters.methodTag,
                Filters.email,
                Filters.amount,
                Filters.currency,
                Filters.status,
                Filters.settlementStatus,
                Filters.consumerFirstName,
                Filters.consumerLastName,
                Filters.currency,
                Filters.reason,
                Filters.countryIso2,
                Filters.settledDate,
                Filters.sourceUrl,
                GeneralFilters.createdAt,
                GeneralFilters.updatedAt
            ])
            .toString()
        );

    const resetTransactions = useResetState(resetState);

    const getInNotifications = useRequest<ThunkReturnType<NotificationsResponse<InNotification>>, string>({
        thunk: getInNotificationsThunk
    });

    const getOutNotifications = useRequest<ThunkReturnType<NotificationsResponse<OutNotification>>, string>({
        thunk: getOutNotificationsThunk
    });

    const sendTransactionNotification = useRequest<ThunkReturnType<TransactionActionResponse>, string>({
        thunk: sendTransactionNotificationThunk
    });

    const checkTransactionStatus = useRequest<ThunkReturnType<TransactionActionResponse>, string>({
        thunk: checkTransactionStatusThunk
    });

    const fakeTransactionRedirect = useRequest<ThunkReturnType<TransactionActionResponse>, string>({
        thunk: fakeTransactionRedirectThunk
    });
    const refundTransactionAction = useRequest<ThunkReturnType<TransactionActionResponse>, string>({
        thunk: refundThunk
    });

    // TODO: fix typing
    const settlePingOrder = useRequest<ThunkReturnType<any>, string>({
        thunk: settlePingOrderThunk
    });

    const closePingOrder = useRequest<ThunkReturnType<any>, string>({
        thunk: closePingOrderThunk
    });

    return {
        resetTransactions,
        getTransactions,
        getTransactionDetails,
        getTransactionHistory,
        downloadCsv,
        getInNotifications,
        getOutNotifications,
        sendTransactionNotification,
        checkTransactionStatus,
        fakeTransactionRedirect,
        settlePingOrder,
        closePingOrder,
        refundTransactionAction
    };
};

export default useActions;
