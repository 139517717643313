import { ApiRoutes } from "consts/enpoints/api";
import type { Setup } from 'features/general/types';
import { Setup as SetupType } from 'consts/general';
import { fetchApi } from "infrastructure/api";
import type { KYCEntity, KYCSettings, ManualBulkKYCsImportHistory } from "./types";
import { downloadFile } from "features/general/api";

export const getManualBulkKYCsImportHistory = (queryString = '') =>
    fetchApi(`${ApiRoutes.GetManualBulkKYCsImportHistory}${queryString}`);

export const getKYCSettings = (queryString = '') =>
    fetchApi(`${ApiRoutes.GetKYCSettings}${queryString}`);

export const updateOrCreateKYCSettings = (body: Partial<KYCSettings>) =>
    fetchApi(ApiRoutes.PostKYCSettings(body.merchantId), {
        method: 'PUT',
        body
    });

export const getSetupsKYCSwitch = (query: string) =>
    fetchApi(`${ApiRoutes.GetSetupsKYCSwitch}${query}`);

export const updateSetupsKYCSwitch = (body: Pick<
    Setup<SetupType.KYC>,
    | 'MOR'
    | 'setupValue'
    | 'userId'
>) =>
    fetchApi(ApiRoutes.PutSetupsKYCSwitch, {
        method: 'PUT',
        body
    });

export const getKYCEntities = (queryString = '') =>
    fetchApi(`${ApiRoutes.GetKycs}${queryString}`);

export const createKYCEntity = (body: Pick<
    KYCEntity,
    | 'consumerId'
    | 'kycTypeId'
    | 'fileKey'
    | 'reason'
    | 'countryIso2'
>) =>
    fetchApi(ApiRoutes.PostKycs, {
        method: 'POST',
        body
    });

export const deleteKYCEntity = (pki: string) =>
    fetchApi(ApiRoutes.DeleteKYCs(pki), {
        method: 'DELETE'
    });

export const downloadKYCFile = (fileKey: string, fileName: string) =>
    downloadFile(fileKey, fileName);

export const createManualBulkKYCsImportHistory = (body: Pick<
    ManualBulkKYCsImportHistory,
    | 'fileKey'
    | 'kycFileKey'
    | 'merchantId'
    | 'kycTypeIds'
    | 'MOR'
>) =>
    fetchApi(ApiRoutes.PostManualBulkKYCsImportHistory, {
        method: 'POST',
        body
    });
