import { memo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table, { ActionMenu } from "ui/widgets/Table";
import { TableGridMode } from "consts/table";
import {
    selectIsMerchantPayoutsUninitialized,
    selectIsMerchantPayoutsLoading,
    selectMerchantPayoutsSlice
} from "features/finance/selectors";
import useFinancePayoutsTable from "./useFinancePayoutsTable";
import TableToolbar from "./TableToolbar";
import { GRID_ACTIONS_COLUMN_TYPE } from "@mui/x-data-grid-premium";
import type { GridCellParams } from "@mui/x-data-grid-premium";
import { Amount, Fees, PayoutInterval } from "./Columns";
import { NoDataOverlay } from "ui/molecules/TableGrids";

const FinancePayoutsTable = () => {
    const {
        financeFiltersRef,
        filterTypes,
        getInitialState,
        getMenuItems,
        getRequestMappings,
        getRowId,
        fetchTableData,
        downloadCsv
    } = useFinancePayoutsTable();

    return (
        <Box
            sx={{
                '& > div': {
                    '& > div': {
                        '&:first-of-type': {
                            mt: 0,
                            '& .MuiPaper-root > div:first-of-type': {
                                display: 'none'
                            }
                        },
                        mx: 0
                    }
                }
            }}
        >
            <Typography
                variant='h6'
                component='h2'
                sx={{
                    fontSize: '16px',
                    marginBottom: '16px'
                }}
                mt={3}
            >
                Settlements & Fraud Monitoring
            </Typography>
            <Table
                mode={TableGridMode.MerchantPayoutsFiles}
                filtersRef={financeFiltersRef}
                initialState={getInitialState()}
                selectIsTableUninitialized={selectIsMerchantPayoutsUninitialized}
                selectIsTableLoading={selectIsMerchantPayoutsLoading}
                selectTableDataSlice={selectMerchantPayoutsSlice}
                fetchTableData={fetchTableData}
                downloadCsv={downloadCsv}
                filterTypes={filterTypes}
                Toolbar={TableToolbar}
                NoRowsOverlay={() => (
                    <NoDataOverlay
                        DataEntityName='payouts file'
                    />
                )}
                FiltersProps={{
                    isFiltersOpen: true,
                    forceRenderSelectedFilters: true
                }}
                CardProps={{
                    sx: {
                        maxHeight: 340
                    }
                }}
                getRowId={getRowId}
                columns={[
                    {
                        field: GRID_ACTIONS_COLUMN_TYPE,
                        headerName: 'Download',
                        headerAlign: 'center',
                        align: 'center',
                        disableColumnMenu: true,
                        renderCell: (params: GridCellParams) => [
                            <ActionMenu
                                {...params}
                                getMenuItems={getMenuItems}
                                requestMappings={getRequestMappings()}
                                iconName='SaveAlt'
                            />
                        ]
                    },
                    {
                        field: 'dateFrom',
                        headerName: "Date From - To",
                        minWidth: 200,
                        sortable: false,
                        disableColumnMenu: true,
                        renderCell: PayoutInterval
                    },
                    {
                        field: "amountPaid",
                        headerName: "Amount",
                        headerAlign: 'center',
                        align: 'center',
                        minWidth: 150,
                        sortable: false,
                        disableColumnMenu: true,
                        renderCell: Amount
                    },
                    {
                        field: "totalNet",
                        headerName: "Fees",
                        headerAlign: 'center',
                        align: 'center',
                        minWidth: 150,
                        sortable: false,
                        disableColumnMenu: true,
                        renderCell: Fees
                    },
                ]}
            />
        </Box>
    );
};

export default memo(FinancePayoutsTable);
