import { memo } from "react";
import Box from "@mui/material/Box";
import Feedback from "ui/forms/Feedback";
import Profile from "ui/layouts/Profile";
import { UploadIllustration } from 'assets/illustrations';
import { Typography } from "@mui/material";
import useHome from "./useHome";

const Home = () => (
    <Profile>
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: 10,
                alignItems: 'center'
            }}
        >
            <Box>
                <Typography
                    sx={{
                        fontFamily: 'var(--primary-font)'
                    }}
                >
                    Send us a feedback
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'var(--primary-font)',
                        fontSize: 18,
                        fontWeight: 700,
                        mb: 3
                    }}
                >
                    Feel free to leave a suggestion for our product.
                </Typography>
                <Feedback
                    {...useHome()}
                />
            </Box>
            <UploadIllustration />
        </Box>
    </Profile>
);

export default memo(Home);
