import type { ReactNode } from "react";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { downloadKYCFile } from "features/kyc/api";
import { DownloadableAction } from 'ui/molecules/Action';
import type { UseDownloadable } from "ui/molecules/Action/Downloadable";
import withGridCellParams from "./withGridCellParams";
import { useCellContext } from "../contexts/CellProvider";

type FileCellProps = Partial<UseDownloadable> & {
    readonly title: ReactNode | null;
};

const File = withGridCellParams<string | File>(props => {
    const { value, field } = props;

    const cellContext = useCellContext();

    const {
        title,
        downloadFile = downloadKYCFile
    } = cellContext[field]?.getCellProps?.<FileCellProps>(props) ?? {};

    const getValue = () =>
        (value instanceof File)
            ? (value as File).name
            : String(value);

    return (
        <Tooltip
            title={title}
            placement='top'
            arrow
        >
            <Box
                sx={{
                    display: 'grid',
                    placeContent: 'center',
                    cursor: title && value
                        ? 'pointer'
                        : 'default'
                }}
                component='span'
            >
                {value
                    ? (
                        <DownloadableAction
                            file={getValue()}
                            downloadFile={fileKey => downloadFile(fileKey, fileKey)}
                            sx={{
                                width: 'initial',
                                height: 'initial',
                                borderRadius: 1,
                            }}
                        >
                            <SaveAltIcon
                                color='disabled'
                            />
                            <Box
                                sx={{
                                    textTransform: 'none',
                                    ml: 1
                                }}
                            >
                                {getValue()}
                            </Box>
                        </DownloadableAction>
                    )
                    : '-'}
            </Box>
        </Tooltip>
    );
});

export default File;
