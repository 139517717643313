import { UiDateTimeFormatter } from "util/formaters";
import BusinessLogicException from "exceptions/BusinessLogicException";
import moment, { type Moment } from "moment";
import type { ElasticSearchResourceResponse } from "types";
import {
    browserUrlSearchParamsAdapterFactory,
    PeriodAdapter,
    type PeriodType
} from "util/period-adapter";
import type { Serie } from "@nivo/line";
import type { BarDatum } from "@nivo/bar";
import { Period } from "consts/period";
import type {
    AxisTickProps,
    BarChartGetters,
    ChartFilter,
    LinearChartDatum,
    LinearChartGetters,
    LinearDiagramProps
} from "../types";
import { LinearChartRepository, Datum } from "./repositories";
import { getSearchParams } from "util/support";
import { fNumber, numberFormatter } from "util/formaters";

export const getPeriod = <
    TPayload extends ElasticSearchResourceResponse<string>['data']
>(columns: TPayload['columns'] = [], field: string) =>
    columns.find(({ name }) => name.startsWith(field))?.name as PeriodType | undefined;

export const getFormattedTickPivot = <
    TPayload extends ElasticSearchResourceResponse<string>['data']
>(
    date: Moment,
    columns: TPayload['columns'] = [],
    field: string
) => {
    const period = getPeriod(columns, field);

    const tickPivotFormattingStrategyRepository = new Map<PeriodType, () => string>()
        .set(`${field}${Period.Year}`, () => moment(date).format(UiDateTimeFormatter.Year))
        .set(`${field}${Period.Month}`, () => UiDateTimeFormatter.withISO8601(
            moment(date)
                .startOf('month')
                .format(UiDateTimeFormatter.Default)
        ))
        .set(`${field}${Period.Week}`, () => {
            const momentDate = moment(date);
            const year = momentDate.format(UiDateTimeFormatter.Year);
            const week = momentDate.format(UiDateTimeFormatter.Week);

            return `${year}${week}`;
        })
        .set(`${field}${Period.Day}`, () => UiDateTimeFormatter.withISO8601(
            moment(date)
                .format(UiDateTimeFormatter.Default)
        ))
        .set(`${field}${Period.Hour}`, () => moment(date).format(UiDateTimeFormatter.Hour));

    return getValidatedFormattingValue(
        tickPivotFormattingStrategyRepository,
        period
    );
};

export const formatXDateAxis = <
    TPayload extends ElasticSearchResourceResponse<string>['data']
>(columns: TPayload['columns'] = [], field: string) =>
    (value: string | number) => {
        const period = getPeriod(columns, field);

        if (!period) {
            return value;
        }

        const xDateAxisFormattingStrategyRepository = new Map<PeriodType, () => string>()
            .set(`${field}${Period.Year}`, () => String(value))
            .set(`${field}${Period.Month}`, () => moment(value).format(UiDateTimeFormatter.MonthYear))
            .set(`${field}${Period.Week}`, () => {
                const year = String(value).slice(0, 4);
                const week = String(value).slice(4, 6);

                return UiDateTimeFormatter.withYearWeekUi(year, week);
            })
            .set(`${field}${Period.Day}`, () => moment(value).format(UiDateTimeFormatter.Ui))
            .set(`${field}${Period.Hour}`, () => UiDateTimeFormatter.withHour(value));

        return getValidatedFormattingValue(
            xDateAxisFormattingStrategyRepository,
            period
        );
    };

export const getTickCount = (data: Serie[]) => data.reduce((max, { data }) => Math.max(max, data.length), 0);

export const getAxisBottomTickRotation = (serie: Serie[]) => {
    const periodLength = getTickCount(serie);

    if (periodLength > 6) {
        return 30;
    }

    return 0;
};

export const applyTotals = (series: Serie[], pivot = 'Total') => {
    const totals = Array.from<Omit<LinearChartDatum, 'pivot'>>([]);
    const tickMaxCount = getTickCount(series);

    for (let tickIndex = 0; tickIndex < tickMaxCount; tickIndex += 1) {
        let tick, total = 0;

        for (const { data } of series) {
            const { x, y = 0 } = data[tickIndex] ?? {};
            tick = x;
            total += Number(y);
        }

        if (tick) {
            totals.push(new Datum(String(tick), total));
        }
    }

    return series.concat({
        id: pivot,
        data: totals
    });
};

export const getAverage = <
    TPayload extends ElasticSearchResourceResponse<string>['data']
>({
    total,
    columns,
    periodGroupingField,
    periodQueryParameterField,
    url
}: Pick<
    LinearChartGetters<TPayload>,
    | 'periodGroupingField'
    | 'periodQueryParameterField'
> & {
    readonly total: number;
    readonly columns: TPayload['columns'];
    readonly url?: string;
}) => {
    const diff = getDiff({
        columns,
        periodGroupingField,
        periodQueryParameterField,
        url,
        precise: true
    });

    if (diff <= 0) {
        return total;
    }

    return total / diff;
};

export const getLinearChartData = <
    TPayload extends ElasticSearchResourceResponse<string>['data']
>(
    payload: TPayload | undefined,
    {
        url,
        periodGroupingField,
        periodQueryParameterField,
        getDatum,
        getPivot = row => String(row.at(-1)),
    }: LinearChartGetters<TPayload>
) => {
    if (!payload) {
        return [];
    }

    const chartRepository = LinearChartRepository.make();
    // Preset chart repository with ticks
    for (const x of Array.from(getTicks({
        url,
        columns: payload.columns,
        periodGroupingField,
        periodQueryParameterField
    }))) {
        for (const row of payload.rows) {
            const pivot = getPivot(row);

            chartRepository.update({
                pivot,
                x
            });
        }
    }

    // Populate chart repository with data
    for (const row of payload.rows) {
        chartRepository.update(getDatum(row));
    }

    // Compensation for missing ticks
    for (const x of chartRepository.ticks) {
        for (const [pivot] of chartRepository.entries) {
            chartRepository.getSerieTicks(pivot)
                .forEach(() => {
                    const semiDatum = {
                        pivot,
                        x
                    };

                    if (!chartRepository.has(semiDatum)) {
                        chartRepository.update(semiDatum);
                    }
                });
        }
    }

    let sortSerie: undefined | ((a: string, b: string) => number) = undefined;

    // This is fucking workaround for 53rd week returned <- Dmitry dont write comments like this
    if ([
        Period.Week,
        Period.Year
    ].some(period => getPeriod(payload.columns, periodGroupingField)?.endsWith(period))) {
        sortSerie = (ax, bx) => ax.localeCompare(bx);
    }
    // return applyTotals(
    //     chartRepository.getSerie(),
    //     'Total'
    // );
    return chartRepository.getSerie(sortSerie);
};

export const getBarChartData = <
    TPayload extends ElasticSearchResourceResponse<string>['data']
>(
    payload: TPayload | undefined,
    {
        getDatum,
        getIndex,
        applyFilter = d => d
    }: BarChartGetters<TPayload> & ChartFilter<BarDatum>
) => {
    if (!payload) {
        return [];
    }

    const processedCountByMethodMap = payload.rows.reduce((map, row) => {
        const indexBy = getIndex(row);

        return map.set(indexBy, {
            ...map.get(indexBy),
            ...getDatum(row)
        });
    }, new Map<string | number, ReturnType<BarChartGetters<TPayload>['getDatum']>>());

    return applyFilter(Array.from(processedCountByMethodMap.values()));
};

export const getBarDatumKeys = <TKey extends string>(keys: Array<TKey>, data: Array<BarDatum>) =>
    keys.filter(key => data.some(datum => key in datum));

export const getBarDatumTotal = (
    { value }: AxisTickProps,
    barDatum: Array<BarDatum>,
    getIndex: (barDatum: BarDatum) => string | number
) => {
    const totalAmount = barDatum
        .reduce((totalAmount, barDatum) => {
            if (Object.is(getIndex(barDatum), value)) {
                return totalAmount + getBarDatumSubtotal(barDatum);
            }

            return totalAmount;
        }, 0);

    return {
        value: totalAmount,
        formattedValue: fNumber(totalAmount, ',.2f'),
    };
};

export const diagramPropsPredicate = <TProps extends Pick<LinearDiagramProps, 'isLoading'>>(
    prevProps: TProps,
    nextProps: TProps
) => Object.is(prevProps.isLoading, nextProps.isLoading);

export const integerFormat = (value: number): string => {
    if (!value) {
        return `${value}`;
    }

    const number = Object.is(Math.floor(value), value) && value;

    if (!number) {
        return '';
    }

    return numberFormatter(number, {
        notation: 'compact'
    });
};

function getBarDatumSubtotal(barDatum: BarDatum): number {
    return Object.entries(barDatum)
        .reduce((amount, [key, value]) => {
            if (
                !key.endsWith('Color') &&
                !Number.isNaN(Number(value))
            ) {
                return amount + Number(value);
            }

            return amount;
        }, 0);
}

function getDiff<TPayload extends ElasticSearchResourceResponse<string>['data']>({
    periodGroupingField,
    periodQueryParameterField,
    columns,
    precise,
    url
}: Pick<
    LinearChartGetters<TPayload>,
    | 'periodGroupingField'
    | 'periodQueryParameterField'
> & {
    readonly columns: TPayload['columns'];
    readonly precise?: boolean;
    readonly url?: string;
}) {
    const periodFactor = getPeriod(columns, periodGroupingField);

    if (!periodFactor) {
        return 0;
    }

    return PeriodAdapter
        .make(
            browserUrlSearchParamsAdapterFactory(periodQueryParameterField, [
                moment.invalid(),
                moment()
                    .endOf('day')
            ]),
            periodGroupingField
        )
        .getDifferenceFromPeriod(
            getSearchParams(url),
            periodFactor,
            precise
        );
};

function getTicks<TPayload extends ElasticSearchResourceResponse<string>['data']>({
    columns,
    periodGroupingField,
    periodQueryParameterField,
    url
}: Pick<
    LinearChartGetters<TPayload>,
    | 'periodGroupingField'
    | 'periodQueryParameterField'
> & {
    readonly columns: TPayload['columns'];
    readonly url?: string;
}) {
    const ticks = new Set<string>();
    const periodFactor = getPeriod(columns, periodGroupingField);

    if (!periodFactor) {
        return ticks;
    }

    const [dateFrom] = browserUrlSearchParamsAdapterFactory(periodQueryParameterField)(getSearchParams(url));

    let momentDate = moment(dateFrom);

    ticks.add(getFormattedTickPivot(momentDate, columns, periodGroupingField));

    for (
        let count = 0;
        count < Math.floor(getDiff({
            columns,
            periodGroupingField,
            periodQueryParameterField,
            url,
            precise: true
        }));
        count += 1
    ) {
        momentDate = PeriodAdapter.incrementPeriod(
            momentDate,
            periodFactor,
            { field: periodGroupingField }
        );

        ticks.add(getFormattedTickPivot(momentDate, columns, periodGroupingField));
    }

    return ticks;
};

function getValidatedFormattingValue(
    formattingStrategyRepository: Map<PeriodType, () => string>,
    period?: PeriodType
): string {
    if (!period) {
        throw new BusinessLogicException('Period factor is not defined', {});
    }

    if (!formattingStrategyRepository.has(period)) {
        throw new BusinessLogicException(`Unknown period of type ${period}`, { period });
    }

    return formattingStrategyRepository.get(period)!();
}

export const filterOutDataByStatus = (
    data:Serie[],
    status: string
) => {
    if (!data) {
        return [];
    }
    const filteredArray = data.filter(obj => obj.id !== status);
    return filteredArray
};
