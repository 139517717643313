import { AUTH_DATA_KEY } from "consts/general";
import type { AuthData, Authorizable } from "features/auth/types";
import Storage from "util/storage";
import { ID, ResourceResponse } from "types";
import decodeToken from "util/jwt";
import type { JWTPayload } from "util/jwt";
import { ResponseCode } from "consts/api";
import { UserRolesNames } from "consts/auth";

interface UserPayload extends JWTPayload {
    readonly merchantIds: Array<ID>;
    readonly mors: Array<unknown>;
    readonly permissions: Array<unknown>;
    readonly userId: ID;
    readonly roleId: number;
    readonly merchantId: ID;
}

export const getAuthData = () =>
    Storage.getItem<AuthData>(AUTH_DATA_KEY);

export const getAuthToken = () =>
    getAuthData()?.access_token;

export const getTokenPayload = () => {
    const token = getAuthToken();

    if (!token) {
        return;
    }

    return decodeToken(token) as UserPayload;
};

export const getUserId = () => getTokenPayload()?.userId;

export const setAuthData = (authData: AuthData) => {
    Storage.setItem(AUTH_DATA_KEY, authData);
};

export const revokeAuthData = () => {
    Storage.removeItem(AUTH_DATA_KEY);
};

export const isCodeIncludes = (code: number) =>
    [ResponseCode.Unathenticated].includes(code);

export const isResponseValid = (response: ResourceResponse<unknown>) =>
    !isCodeIncludes(response?.code);

export const isEmployee = (roleName: string) =>
    Object.is(roleName, UserRolesNames.MerchantEmployee);

export const isMerchant = (_: string) => true;

export const isAdmin = (roleName: string) => [
    UserRolesNames.MerchantAdmin,
].includes(roleName as UserRolesNames);

export const filterByRoles = (
    roleName: string | null,
    rollableCollection: Array<Authorizable>
) => rollableCollection.filter(({ roles }) => {
    const userRole = roleName as UserRolesNames;

    if (typeof roles === 'string') {
        return ['*', roles].includes(userRole);
    }

    if (Array.isArray(roles)) {
        return roles.includes(userRole);
    }

    return true;
});
