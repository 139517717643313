import { ApiState } from "infrastructure/api";
import { PayoutAccountsState } from "./types";

const state: PayoutAccountsState = {
    payoutAccounts: null,
    payoutAccountsLoadingState: ApiState.Idle,
    payoutCryptoAccounts: null,
    payoutCryptoAccountsLoadingState: ApiState.Idle,
    account: null,
};

export const sliceToken = 'payoutAccounts';

export default state;
