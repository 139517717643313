import { memo } from "react";
import Box from "@mui/material/Box";
import { ClipboardTextField } from "ui/molecules/Input";
import { useTypedSelector } from "hooks";
import { selectSettings } from "features/general/selectors";
import Documentation from "ui/widgets/Documentation";

const TechnicalInformation = () => {
    const settings = useTypedSelector(selectSettings);
    return (
        <Box
            sx={{
                display: 'grid',
                maxWidth: 500,
                alignItems: 'flex-start',
                gap: 3
            }}
        >
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    gap: 3,
                }}
            >
                <ClipboardTextField
                    variant='outlined'
                    label='API-ID'
                    size='small'
                    fullWidth
                    defaultValue={settings?.apiId}
                    inputProps={{
                        readOnly: true
                    }}
                />
                <ClipboardTextField
                    variant='outlined'
                    label='API-GUID'
                    size='small'
                    fullWidth
                    defaultValue={settings?.apiGuid}
                    inputProps={{
                        readOnly: true
                    }}
                />
                <ClipboardTextField
                    variant='outlined'
                    label='CPKey'
                    size='small'
                    fullWidth
                    defaultValue={settings?.cpKey}
                    inputProps={{
                        readOnly: true
                    }}
                />
                <ClipboardTextField
                    variant='outlined'
                    label='CPSecret'
                    size='small'
                    fullWidth
                    defaultValue={settings?.cpSecret}
                    inputProps={{
                        readOnly: true
                    }}
                />
                <ClipboardTextField
                    variant='outlined'
                    label='Shared Key'
                    size='small'
                    fullWidth
                    defaultValue={settings?.sharedKey}
                    inputProps={{
                        readOnly: true
                    }}
                />
            </Box>
            <Documentation
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 3,
                    mt: 3
                }}
                actionProps={{
                    children: 'Download Documentation',
                    fullWidth: true,
                    size: 'large'
                }}
            />
        </Box>
    );
};

export default memo(TechnicalInformation);