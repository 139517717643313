import { memo } from "react";
import StatefulTabs from "ui/widgets/StatefulTabs";
import GeneralInfo from "./GeneralInfo";
import FinancialDetails from "./FinancialDetails";
import FeesMethods from "./FeesMethods";
import TechnicalInformation from "./TechnicalInformation";
import Tab from './Tab';
import { ProfileTabs } from "consts/profile";
import { UserRolesNames } from "consts/auth";

const Tabs = () => (
    <StatefulTabs
        onChange={() => {}}
        model={[
            {
                label: ProfileTabs.GeneralInfo,
                component: (
                    <Tab
                        title={ProfileTabs.GeneralInfo}
                    >
                        <GeneralInfo />
                    </Tab>
                ),
                roles: [UserRolesNames.MerchantAdmin]
            },
            {
                label: ProfileTabs.FinancialDetails,
                component: (
                    <Tab
                        title={ProfileTabs.FinancialDetails}
                    >
                        <FinancialDetails />
                    </Tab>
                ),
                roles: [
                    UserRolesNames.MerchantAdmin,
                    UserRolesNames.MerchantFinance
                ]
            },
            {
                label: ProfileTabs.FeesMethods,
                component: (
                    <Tab
                        sx={{
                            maxWidth: 'initial'
                        }}
                    >
                        <FeesMethods />
                    </Tab>
                ),
                roles: [
                    UserRolesNames.MerchantAdmin,
                    UserRolesNames.MerchantTech
                ]
            },
            {
                label: ProfileTabs.TechnicalInformation,
                component: (
                    <Tab
                        title={ProfileTabs.TechnicalInformation}
                    >
                        <TechnicalInformation />
                    </Tab>
                ),
                roles: [
                    UserRolesNames.MerchantAdmin,
                    UserRolesNames.MerchantTech
                ]
            }
        ]}
    />
);

export default memo(Tabs);