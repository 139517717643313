import { selectAuthStatus, selectAuthUser } from "features/auth/selectors";
import { AuthStatus } from "features/auth/types";
import { useTypedSelector } from "hooks/store";
import { concat } from "util/support";


const useAuth = () => {
    const authState = useTypedSelector(selectAuthStatus);
    const user = useTypedSelector(selectAuthUser);

    const isPending = () =>
        [AuthStatus.Uninitialized, AuthStatus.Pending].includes(authState);
    const isAuthenticated = () =>
        [AuthStatus.Authenticated].includes(authState);

    const getUserName = () => user && concat([user.firstName, user.lastName], { token: ' ' });

    return {
        isPending,
        isAuthenticated,
        user,
        getUserName
    };
};

export default useAuth;
