import { ApiState } from "infrastructure/api";
import { Setup as SetupType } from 'consts/general';
import type {
    BooleanSwitch,
    CoreBankingEntity,
    ID,
    Mapped,
    PaginateResourceResponse,
    ResourceResponse,
    Timestamps,
    Option
} from "types";
import { TableGridMode } from "consts/table";
import type { GridColumnVisibilityModel } from "@mui/x-data-grid";

export enum NotificationSeverity {
    Warning = 'warning',
    Success = 'success',
    Info = 'info',
    Error = 'error'
};

export type Notification = {
    readonly severity: NotificationSeverity,
    readonly message: string;
};

export type GeneralState = {
    isSideMenuOpen: boolean;
    readonly notifications: Array<Notification>;
    readonly constants: Partial<Constants>;
    readonly constantsLoadingState: ApiState;
    readonly countries: Countries | null;
    readonly countriesLoadingState: ApiState;
    readonly domains: Setup<SetupType.Domains, SetupValue> | null;
    readonly domainsLoadingState: ApiState;
    readonly extras: Extras;
    readonly userPreferences: UserPreferences | {};
    readonly userPreferencesLoadingState: ApiState;
    readonly settings: Settings | null;
    readonly settingsLoadingState: ApiState;
    readonly ledgers: LedgersResponse['data'] | null;
    readonly ledgersLoadingState: ApiState;
};

export type Settings = {
    readonly isActive: boolean;
    readonly payoutCurrency: string;
    readonly fullName: string;
    readonly pki: ID;
};

export type SettingsResponse = ResourceResponse<Settings>;

export type Constants = {
    readonly Currencies: Array<string>;
    readonly TransactionCurrencies: Array<string>;
    readonly Countries: Countries;
    readonly CsvSettlementStatus: Array<string>;
    readonly TransactionFundStatus: Array<string>;
    readonly PSPs: Array<string>;
    readonly TransactionPayoutStatus: Array<Option>;
    readonly TransactionSettlementStatus: Array<Option>;
    readonly TransactionStatus: Array<string>;
    readonly Via: Array<Option>;
    readonly MerchantPayoutCalculationEnums: Array<string>;
    readonly MerchantPayoutIncrementEnums: Array<string>;
    readonly MerchantPayoutIncrementTaxName: Array<string>;
    readonly MerchantPayoutFileTaxName: Array<string>;
    readonly MerchantPayoutIncrementTaxRefColumnsFrom: Array<string>;
    readonly TransactionResultMessage: Record<string, string>;
    readonly MerchantPayoutIncrementsVia: Record<string, string>;
    readonly KYCStatusEnums: Array<Option>;
    readonly KycStatusesMap: Record<string, string>;
    readonly KYCStatusesMap: Array<Option>;
    readonly SamePersonIdentifierSourceTypes: Array<Option>;
    readonly ObResultStatuses: Array<Option>;
    readonly CardIssuer: Array<Option>
    readonly TransactionCardIssuers: Record<string, string>
};

export type ConstantsResponse = ResourceResponse<Constants>;

export type Country = {
    readonly coreId: ID;
    readonly iso2: string;
    readonly iso3: string;
    readonly shortName: string;
    readonly longName: string;
    readonly numcode: string;
    readonly unMember: string;
    readonly callingCode: string;
    readonly cctld: string;
};

export type Countries = Array<Country>;

export type CountriesPaginatedResponse = PaginateResourceResponse<Country>;

export type SetupValue = Mapped<string>;

export type Setup<TIdentifier extends string, TSetupValue = BooleanSwitch> =
    & CoreBankingEntity
    & Timestamps
    & {
        readonly MOR: string;
        readonly identifier: TIdentifier;
        readonly setupValue: TSetupValue;
        readonly valueType: string;
        readonly userId: ID;
    };

/*export type Domains<TSetup = string> = {
    readonly MOR: string;
    readonly coreId: ID;
    readonly createdAt: string;
    readonly updatedAt: string | null;
    readonly createdBySystem: ID;
    readonly identifier: 'APIINFO_DOMAINS';
    readonly isDeletable: boolean;
    readonly obs: unknown | null;
    readonly userId: ID | null;
    readonly valueType: 'json_assoc';
    readonly setupValue: TSetup;
};*/

export type DomainsResourceResponse = ResourceResponse<
    Setup<SetupType.Domains, string>
>;

export type Extras = Record<string, Array<Option>>;

export type FileEntity = {
    readonly fileKey: string;
};

export type FileResponse = ResourceResponse<FileEntity>;

export type TablePreferences = {
    readonly columnVisibilityModel: GridColumnVisibilityModel;
    readonly columnOrder: Array<string>;
};

export type UserPreferences<T = Record<TableGridMode, TablePreferences>> = Record<
    'preferences',
    T
>;

export type UserPreferencesResponse = ResourceResponse<UserPreferences<string>>;

export type UserPreferencesRequest = {
    readonly userId: ID;
    readonly preferences: string;
};

export type FeedbackFormData = {
    readonly description: string;
};

export type SupportFormData = FeedbackFormData & {
    readonly subject: string;
    readonly department: string;
};

export type Ledger = Timestamps & {
    readonly currency: string;
    readonly balanceRequested: number;
    readonly balanceProcessed: number;
    readonly balanceSettled: number;
    readonly balanceBlocked: number;
    readonly rollingReserved: number;
    readonly balancePending: number;
    readonly merchantName: string;
    readonly pki: number;
};

export type LedgersResponse = PaginateResourceResponse<Ledger>;
