import { memo, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useFinanceCassettes, {
    esPendingPayoutsQueryToken,
    lastPayedQueryToken,
    rollingReverseReserveQueryToken
} from "./useFinanceCassettes";
import TotalBalance from "./TotalBalance";
import Reserve from "./Reserve";
import LastPayout from "./LastPayout";

const FinanceCassettes = () => {
    const {
        fetch,
        [rollingReverseReserveQueryToken]: rollingReverseReserveQuery,
        [esPendingPayoutsQueryToken]: esPendingPayoutsQuery,
        [lastPayedQueryToken]: lastPayedQuery
    } = useFinanceCassettes();

    const fetchRef = useRef<(typeof fetch) | null>(fetch);

    useEffect(() => {
        fetchRef.current?.('');
        fetchRef.current = null;
    }, []);

    return (
        <Box>
            <Typography
                variant='h6'
                component='h2'
                gutterBottom
            >
                Current situation
            </Typography>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
                    gap: 2
                }}
            >
                <TotalBalance
                    rollingReverseReserveQuery={rollingReverseReserveQuery}
                    esPendingPayoutsQuery={esPendingPayoutsQuery}
                />
                <Reserve
                    rollingReverseReserveQuery={rollingReverseReserveQuery}
                />
                <LastPayout
                    lastPayedQuery={lastPayedQuery}
                />
            </Box>
        </Box>
    );
};

export default memo(FinanceCassettes);
