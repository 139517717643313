import { createSlice } from "@reduxjs/toolkit";
import { ApiState } from "infrastructure/api";
import sliceMixin from "infrastructure/store/reducers/sliceMixin";
import { merchantsRequestFulfilled, merchantsRequestLoading, merchantsRequestRejected } from "./reducers";
import initialState, { sliceToken } from "./state";
import { getMerchantsThunk, postMerchantThunk } from "./thunks";
import type { MerchantsState } from "./types";

const slice = createSlice({
    name: sliceToken,
    initialState,
    reducers: {
        ...sliceMixin<MerchantsState>(initialState),
        changeMerchantsLoadingState: (state, { payload }) => {
            state.merchantsLoadingState = payload;
        },
        changeMerchantsSlice: (state, { payload }) => {
            state.merchantsSlice = payload.data;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getMerchantsThunk.pending, merchantsRequestLoading)
            .addCase(getMerchantsThunk.fulfilled, merchantsRequestFulfilled)
            .addCase(getMerchantsThunk.rejected, merchantsRequestRejected)
            .addCase(postMerchantThunk.pending, state => {
                state.merchantProfileLoadingState = ApiState.Pending;
            })
            .addCase(postMerchantThunk.fulfilled, state => {
                state.merchantProfileLoadingState = ApiState.Succeeded;
            })
            .addCase(postMerchantThunk.rejected, state => {
                state.merchantProfileLoadingState = ApiState.Failed;
            })
    }
});

export const {
    name,
    actions: {
        changeExportLoading,
        resetState,
        changeMerchantsLoadingState,
        changeMerchantsSlice
    }
} = slice;

export default slice.reducer;
