import { ApiState } from "infrastructure/api";
import type { RootState } from "infrastructure/store";
import type { Merchant } from "./types";
import type { ID } from "types";
import { compareIds } from "util/support";

export const selectIsMerchantsUninitialized = (state: RootState) =>
  state.merchants.merchantsLoadingState === ApiState.Idle;

export const selectIsMerchantsLoading = (state: RootState) =>
  state.merchants.merchantsLoadingState === ApiState.Pending;

export const selectIsExportMerchantsLoading = (state: RootState) =>
  state.merchants.exportLoadingState === ApiState.Pending;

export const selectMerchantSlice = (state: RootState) =>
  state.merchants.merchantsSlice;

export const selectIsProfileLoading = (state: RootState) =>
  state.merchants.merchantProfileLoadingState === ApiState.Pending;

export const selectMerchantOptions = (state: RootState) =>
  state.merchants
    .merchantsSlice
    ?.data
    .map(({ coreId, fullName }: Merchant) => ({
      id: coreId,
      name: `${fullName} (${coreId})`
    })) ?? [];

export const selectMerchantById = (merchantId: ID) =>
  (state: RootState) =>
    state.merchants
      .merchantsSlice
      ?.data
      .find(({ coreId }: Merchant) => compareIds(coreId, merchantId));

export const selectActiveMerchants = (state: RootState) =>
  state.merchants
    .merchantsSlice
    ?.data
    .filter(({ isActive }: Merchant) => isActive) ?? [];
