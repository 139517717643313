import * as renderCells from 'ui/molecules/TableGrids/renderers';
import * as cells from 'ui/widgets/Table/renderers';
import * as valueFormatters from 'ui/widgets/Table/formatters';

// TODO: migrate to widget renderers
const commonRenderCells = Object.assign({}, renderCells, cells);

export const generalTableParser = (columns: any, sortableColumns: Array<string> = []) => {
    const getIsCellValid = (entity: any, name: string) =>
        (typeof entity[name] !== 'function') && (name in entity);

    return columns.map((cell: any) => {
        const aggregatedCell: any = {
            ...cell,
            sortable: sortableColumns.includes(cell.field)
        };

        if (getIsCellValid(aggregatedCell, 'renderCell')) {
            aggregatedCell.renderCell = (commonRenderCells as any)[aggregatedCell.renderCell!];
        }

        if ('valueFormatter' in aggregatedCell) {
            aggregatedCell.valueFormatter = (valueFormatters as any)[aggregatedCell.valueFormatter!];
        }

        if (!aggregatedCell.minWidth) {
            aggregatedCell.minWidth = 100;
        }

        if (!('align' in aggregatedCell)) {
            aggregatedCell.align = ['amount', 'methodId', 'consumerId'].includes(cell.field) ? 'right' : 'left';
        }

        if (!('description' in aggregatedCell)) {
            aggregatedCell.description = aggregatedCell.headerName;
        }

        if (!('flex' in aggregatedCell)) {
            aggregatedCell.flex = 1;
        }

        return aggregatedCell;
    });
};

export const parse = (value: string) => {
    let parsed = {};

    try {
        parsed = JSON.parse(value);
    } finally {
        return parsed;
    }
};

