import CircularProgress, { type CircularProgressProps } from "@mui/material/CircularProgress";
import { memo, Fragment, type ElementType, type PropsWithChildren } from "react";

type Props = CircularProgressProps & {
    readonly WrapperComponent?: ElementType;
    readonly WrapperComponentProps?: Record<string, unknown>;
    readonly isLoading?: boolean;
};

const ProgressLoader = ({
    isLoading = false,
    WrapperComponent = Fragment,
    WrapperComponentProps = {},
    children,
    ...circularProgressProps
}: PropsWithChildren<Props>) => (
    <>
        {isLoading
            ? (
                <WrapperComponent
                    {...WrapperComponentProps}
                >
                    <CircularProgress
                        {...circularProgressProps}
                    />
                </WrapperComponent>
            )
            : children}
    </>
);

export default memo(ProgressLoader);
