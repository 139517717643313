import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { selectSettings } from "features/general/selectors";
import { useTypedSelector } from "hooks";
import { memo, type PropsWithChildren } from "react";

const Settings = ({ children }: PropsWithChildren) => {
    const settings = useTypedSelector(selectSettings);

    if (!settings) {
        return (
            <Box
                sx={{
                    display: "grid",
                    placeContent: "center",
                    height: "100vh"
                }}
            >
                <CircularProgress size={40} />
            </Box>
        );
    }

    return <>{children}</>;
};

export default memo(Settings);
