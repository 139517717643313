import { PropsWithChildren, memo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Account from "ui/molecules/Account";
import Support from "ui/molecules/Support";
import { useLocales } from 'locales';
import useAuth from "features/auth/useAuth";

const Profile = ({ children }: PropsWithChildren) => {
    const { translate } = useLocales();
    const { getUserName } = useAuth();
    const name = getUserName();

    return (
        <Box
            sx={{
                px: 3
            }}
        >
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        gap: 3,
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <Account
                        title={(
                            <Typography
                                sx={{
                                    fontFamily: 'var(--primary-font)',
                                    fontSize: 27,
                                    fontWeight: 700
                                }}
                            >
                                {[translate('docs.hi'), name].join(', ')}!
                            </Typography>
                        )}
                        subTitle={(
                            <Typography
                                sx={{
                                    fontFamily: 'var(--primary-font)'
                                }}
                            >
                                Welcome to Macropay’s back office.
                            </Typography>
                        )}
                        name={name}
                        alt={name}
                        sx={{
                            width: 82,
                            height: 82
                        }}
                    />
                    <Support>
                        <Typography
                            sx={{
                                fontFamily: 'var(--primary-font)',
                                fontSize: 18,
                                fontWeight: 600,
                                textAlign: 'right',
                                mb: 1
                            }}
                        >
                            Any issues?
                        </Typography>
                    </Support>
                </Box>
                <Divider sx={{ my: 2 }} />
            </Box>
            {children}
        </Box>
    );
};

export default memo(Profile);
