import { memo, useMemo } from "react";
import type { ReserveProps } from "./types";
import Cassette from "./Cassette";
import { fromArray } from "util/support";

const Reserve = ({ rollingReverseReserveQuery }: ReserveProps) => {
    const { payload: rollingReverseReserveQueryPayload } = rollingReverseReserveQuery;

    const amount = useMemo(() => {
        const { rollingReserved = 0 } = fromArray(rollingReverseReserveQueryPayload?.data)
            .at(0) ?? {};

        return Number(rollingReserved);
    }, [
        rollingReverseReserveQueryPayload
    ]);

    return (
        <Cassette
            labelSlot='Reserve'
            isLoading={rollingReverseReserveQuery.isLoading}
            amountSlot={amount}
        />
    );
};

export default memo(Reserve);
