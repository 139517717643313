import { memo, useMemo, useRef } from 'react';
import Menu, { type  MenuImperativeProps } from 'ui/atoms/Menu';
import type { IconProps } from 'ui/atoms/Icon';
import { ButtonProps } from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Icon from 'ui/atoms/Icon';
import useActionMenu from './useActionMenu';
import type { GridRowParams } from '@mui/x-data-grid-premium';
import { FileProvider, ManageFileTaxesProvider, DialogProvider } from './Dialogs';
import type { ActionMenuArg } from './Props';
import type { CoreBankingEntity } from 'types';

type Props =
    & Pick<GridRowParams, 'row'>
    & ActionMenuArg<CoreBankingEntity>
    & {
        readonly iconName?: IconProps['name'];
    };

const ActionMenu = ({
    row,
    requestMappings,
    getMenuItems,
    iconName = 'MoreVert'
}: Props) => {
    const menuRef = useRef<MenuImperativeProps>();

    const getActionMenuItem = useActionMenu({
        row,
        menuRef,
        requestMappings,
        getMenuItems
    });

    const ActionControl = useMemo(() =>
        ({ onClick }: ButtonProps) => (
            <IconButton
                onClick={onClick}
            >
                <Icon name={iconName} />
            </IconButton>
        ), [iconName]);

    return (
        <Menu
            ref={menuRef}
            ActionControl={ActionControl}
        >
            {getActionMenuItem()}
        </Menu>
    );
};

export default memo((props: Props) => (
    <FileProvider value={props.row}>
        <ManageFileTaxesProvider>
            <DialogProvider>
                <ActionMenu {...props} />
            </DialogProvider>
        </ManageFileTaxesProvider>
    </FileProvider>
));
