import Screen from 'ui/layouts/Screen';
import SupportComponent from 'ui/organizms/Support';

const Support = () => (
    <Screen
        title='Support'
    >
        <SupportComponent />
    </Screen>
);

export default Support;
