import type { PayloadAction } from "@reduxjs/toolkit";
import type { WritableDraft } from "immer/dist/internal"
import { ApiState } from "infrastructure/api";
import type { ThunkReturnType } from "types";
import type { RolesResponse, UsersMerchantsResponse, UsersMorsResponse, UsersResponse, UsersState } from "./types";

export const usersRequestLoading = (state: WritableDraft<UsersState>) => {
    state.usersLoadingState = ApiState.Pending;
};

export const usersRequestFulfilled = (
    state: WritableDraft<UsersState>,
    { payload }: PayloadAction<ThunkReturnType<UsersResponse>>) => {
    state.usersLoadingState = ApiState.Succeeded;
    state.usersSlice = payload!.data;
};

export const usersRequestRejected = (state: WritableDraft<UsersState>) => {
    state.usersLoadingState = ApiState.Failed;
    state.usersSlice = null;
};

export const rolesRequestLoading = (state: WritableDraft<UsersState>) => {
    state.rolesLoadingState = ApiState.Pending;
};

export const rolesRequestFulfilled = (
    state: WritableDraft<UsersState>,
    { payload }: PayloadAction<ThunkReturnType<RolesResponse>>) => {
    state.rolesLoadingState = ApiState.Succeeded;
    state.rolesSlice = payload!.data;
};

export const rolesRequestRejected = (state: WritableDraft<UsersState>) => {
    state.rolesLoadingState = ApiState.Failed;
    state.rolesSlice = null;
};

export const usersMerchantsRequestLoading = (state: WritableDraft<UsersState>) => {
    state.usersMerchantsLoadingState = ApiState.Pending;
};

export const usersMerchantsRequestFulfilled = (
    state: WritableDraft<UsersState>,
    { payload }: PayloadAction<ThunkReturnType<UsersMerchantsResponse>>) => {
    state.usersMerchantsLoadingState = ApiState.Succeeded;
    state.usersMerchantsSlice = payload!.data;
};

export const usersMerchantsRequestRejected = (state: WritableDraft<UsersState>) => {
    state.usersMerchantsLoadingState = ApiState.Failed;
    state.usersMerchantsSlice = null;
};

export const usersMorsRequestLoading = (state: WritableDraft<UsersState>) => {
    state.usersMorsLoadingState = ApiState.Pending;
};

export const usersMorsRequestFulfilled = (
    state: WritableDraft<UsersState>,
    { payload }: PayloadAction<ThunkReturnType<UsersMorsResponse>>) => {
    state.usersMorsLoadingState = ApiState.Succeeded;
    state.usersMorsSlice = payload!.data;
};

export const usersMorsRequestRejected = (state: WritableDraft<UsersState>) => {
    state.usersMorsLoadingState = ApiState.Failed;
    state.usersMorsSlice = null;
};
