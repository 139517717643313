import { ReactNode, memo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CustomAvatar } from "lib/custom-avatar";

type Props = {
    readonly tag: ReactNode;
};

const FeeTitle = ({ tag }: Props) => (
    <Box
        sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center'
        }}
    >
        <CustomAvatar
            color='primary'
            name={String(tag)}
        />
        <Typography
            variant="h6"
            component="h3"
        >
            {tag}
        </Typography>
    </Box>
);

export default memo(FeeTitle);
