import { selectAccounts, selectAccount as selectAccountSelector, selectIsAccountsLoading } from "features/payoutAccounts/selectors";
import useActions from "features/payoutAccounts/useActions";
import { useAppStore, useTypedSelector } from "hooks";
import useSessionEffect from "hooks/useSessionEffect";
import { useEffect } from "react";

const financialDetailsSessionEffectSymbol = Symbol('financialDetailsSessionEffect');

export default function useFinancialDetails() {
    const store = useAppStore();
    const accounts = useTypedSelector(selectAccounts);
    const account = useTypedSelector(selectAccountSelector);
    const {
        getPayoutAccounts,
        getPayoutCryptoAccounts,
        selectAccount
    } = useActions();
    const isAccountsLoading = useTypedSelector(selectIsAccountsLoading)
    const hasFinancialDetails = () =>(!isAccountsLoading && accounts.length > 0)

    useEffect(() => {
        if (accounts.length > 0 && !selectAccountSelector(store.getState())) {
            selectAccount(accounts[0]);
        }
    }, [accounts, selectAccount, store]);

    useSessionEffect(() => {
        getPayoutAccounts();
        getPayoutCryptoAccounts();
    }, financialDetailsSessionEffectSymbol);

    return {
        accounts,
        account,
        hasFinancialDetails,
        isAccountsLoading
    };
};
