import clsx from "clsx";
import { CurrencySymbol } from "consts/general";
import type { FeeCardProps, IncrementTaxes } from "./types";

export default function useFeeCard({
    incrementTaxes
}: Pick<FeeCardProps, 'incrementTaxes'>) {
    const getNormalizedIncrementTaxes = () => {
        if (Number(incrementTaxes.at(0)!.refColFromMinSum) > 0) {
            return [
                {
                    ...incrementTaxes.at(0)!,
                    refColFromMinSum: 0,
                    currentIncMultiplierForEachRefColumn: 0
                },
                ...incrementTaxes
            ];
        }

        return incrementTaxes;
    };

    const renderInterval = (
        refColFromMinSum: IncrementTaxes['refColFromMinSum'],
        index: number,
        incrementTaxes: ReturnType<typeof getNormalizedIncrementTaxes>
    ) => [
        refColFromMinSum,
        incrementTaxes.at(index + 1)?.refColFromMinSum
    ]
        .filter(refColFromMinSum => ['number', 'string']
            .includes(typeof refColFromMinSum))
        .map((refColFromMinSum, _, incrementTaxes) =>
            `${refColFromMinSum} ${CurrencySymbol.EUR} ${clsx((incrementTaxes.length < 2) && '+')}`
        )
        .join(' - ');

    return {
        getNormalizedIncrementTaxes,
        renderInterval
    };
};
